import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion.tsx";
import { EyeIcon } from "@heroicons/react/24/outline";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table.tsx";
import { useAppSelector } from "@/store/Hooks.ts";
import { selectCurrentEntryRowData } from "@/store/order-guide-upload/product-matching/OrderGuideUploadProductMatching.slice.ts";

export function FileRecordPreview() {
  const entryRowData = useAppSelector(selectCurrentEntryRowData);

  if (!entryRowData) {
    return null;
  }

  return (
    <Accordion collapsible type="single" className="max-w-full">
      <AccordionItem value="1">
        <AccordionTrigger>
          <div className="text-left">
            <h3>
              File Record Preview <EyeIcon className="inline-block h-5 w-5" />
            </h3>
            <p className="text-sm text-gray-500">Click to expand</p>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <>
            <p className="text-sm text-gray-500">Below is the record from the file you uploaded. You may use it as a reference.</p>
            <p className="text-xs text-gray-500">Not all columns may be shown. Empty columns, and preset-hidden columns are not shown for brevity.</p>
            <div className="mt-4 block overflow-x-scroll rounded border shadow">
              <Table className="mb-2 min-w-full bg-white">
                <TableHeader>
                  <TableRow>
                    {/*<TableHead>Row</TableHead>*/}
                    {Object.keys(entryRowData)
                      .filter((x) => !!entryRowData[x])
                      .map((col) => (
                        <TableHead key={col} className="min-w-[10ch] whitespace-nowrap">
                          {col}
                        </TableHead>
                      ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    {Object.values(entryRowData)
                      .filter((x) => !!x)
                      .map((record, index) => (
                        <TableCell key={index} className="whitespace-nowrap">
                          {record}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
