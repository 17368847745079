import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  createRoutesFromElements,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import "./index.css";
import { ApplicationRoutes } from "./Routes.tsx";
// Render *after* the #root element in the DOM, so that the react elements are actually top-level inside the body element.
// See https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
// ReactDOM.createRoot(document.body).render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
// );
// import "./Apm.ts";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://fe715e9cafc7bbb028fd34828dfaeb14@o4507105045643264.ingest.us.sentry.io/4507105048395776",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    // Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      // buttonLabel: "Feedback",
      showBranding: false,
      autoInject: false,
      colorScheme: "light",

      isNameRequired: false,
      showName: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter(createRoutesFromElements(ApplicationRoutes()));

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />

    {/*<BrowserRouter basename="/">*/}
    {/*  */}
    {/*</BrowserRouter>*/}
  </React.StrictMode>,
);
