import { BusinessRegistrationType } from "@/store/IRN.API.ts";
import { IrnInput, IrnSelect } from "@/components/forms/IrnInput.tsx";
import { AddressForm } from "@/components/forms/AddressForm.tsx";
import { clsx } from "clsx";

export function BusinessForm({ control, namePrefix, dialog, requireFields }: { control: any; namePrefix?: string; dialog?: boolean; requireFields?: boolean }) {
  if (namePrefix) {
    namePrefix = namePrefix + ".";
  }

  return (
    <div className={clsx("grid grid-cols-1 gap-5", !dialog && "sm:grid-cols-2")}>
      <IrnInput
        control={control}
        name={`${namePrefix}operatingName`}
        label="Operating (DBA) Name"
        description="The operating business name that is shown on your customer's receipts and invoices"
      />
      <IrnInput control={control} name={`${namePrefix}name`} label="Legal Name" description="Enter the name that your business is registered under" />
      <div className="sm:col-span-full">
        <IrnSelect
          control={control}
          name={`${namePrefix}registrationType`}
          label="Registration Type"
          required={requireFields}
          description="The registration type of the business as it appears on your tax forms (W9)"
          options={[
            { label: "Sole Proprietorship", value: BusinessRegistrationType.SoleProprietorship },
            { label: "Registered Partnership", value: BusinessRegistrationType.Partnership },
            { label: "Incorporated", value: BusinessRegistrationType.Incorporated },
            { label: "Non-Profit", value: BusinessRegistrationType.NonProfit },
            { label: "LLC (D - Disregarded Entity)", value: BusinessRegistrationType.LlcDisregardedEntity },
            { label: "LLC (C - Corporation)", value: BusinessRegistrationType.LlccCorporation },
            { label: "LLC (P - Partnership)", value: BusinessRegistrationType.LlcPartnership },
            { label: "Sub S Corporation", value: BusinessRegistrationType.SubSCorporation },
          ]}
        />
      </div>
      <div className="sm:col-span-full">
        <IrnInput control={control} name={`${namePrefix}taxId`} label="Tax ID" description="The tax ID of the business." />
      </div>
      <IrnInput control={control} name={`${namePrefix}email`} label="Email" description="The email of the business." />
      <IrnInput control={control} name={`${namePrefix}phoneNumber`} label="Phone Number" description="The phone number of the business." />

      <div className="sm:col-span-full">
        <AddressForm control={control} namePrefix={`${namePrefix}address`} />
      </div>
    </div>
  );
}
