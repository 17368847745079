import { LoadingSpinner } from "@/components/LoadingSpinner.tsx";
import { OrderGuideCategoryListing } from "@/components/order-guide/OrderGuideCategoryListing.tsx";
import {
  OrderGuideDisplayCategoryDto,
  OrderGuideDisplayDto,
  OrderGuideItemDto,
  useGetOrderGuideDisplayQuery,
  useReorderOrderGuideItemsMutation,
} from "@/store/IRN.API.ts";
import { DragDropContext, DropResult } from "@hello-pangea/dnd";
import { useEffect, useState } from "react";

export function OrderGuideListingCategoryWrapper({ orderGuideId, allowItemReordering }: { orderGuideId: number; allowItemReordering: boolean }) {
  const ogiDisplay = useGetOrderGuideDisplayQuery(orderGuideId);
  const [reorderItemsTrigger] = useReorderOrderGuideItemsMutation();

  // NOTE: We're keeping local state for the order guide here to prevent unnecessary re-renders, and so we can update the ordering locally
  // while we wait for the API to respond with the new order guide.
  const [orderGuide, setOrderGuide] = useState<OrderGuideDisplayDto | null>(null);
  const [categories, setCategories] = useState<OrderGuideDisplayCategoryDto[]>([]);
  const [itemsByCategory, setItemsByCategory] = useState<Record<number, OrderGuideItemDto[]>>({});

  useEffect(() => {
    if (ogiDisplay.isLoading) {
      // console.log("Loading order guide data");
      return;
    }

    if (!ogiDisplay.data) {
      setOrderGuide(null);
      setCategories([]);
      setItemsByCategory({});
      // console.log("No order guide data");
    } else {
      console.log(ogiDisplay.data);
      setOrderGuide(ogiDisplay.data);

      // Set the categories for the order guide
      setCategories([...ogiDisplay.data.categories]);

      // Set the items for each category
      const itemsByCategory: Record<number, OrderGuideItemDto[]> = {};
      ogiDisplay.data.categories.forEach((category) => {
        itemsByCategory[category.id] = [...category.items].sort((a, b) => a.index - b.index);
      });

      setItemsByCategory(itemsByCategory);
    }
  }, [ogiDisplay.data, ogiDisplay.isLoading, orderGuideId]);

  const onDragItemEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index && result.destination.droppableId === result.source.droppableId) {
      return;
    }

    console.log(result);
    /*{
  "draggableId": "ogi-33",
  "type": "DEFAULT",
  "source": {
    "index": 0,
    "droppableId": "ogi-cat-6"
  },
  "reason": "DROP",
  "mode": "FLUID",
  "destination": {
    "droppableId": "ogi-cat-8",
    "index": 0
  },
  "combine": null
}*/

    const sourceCategory = parseInt(result.source.droppableId.replace("ogi-cat-", ""));
    const destinationCategory = parseInt(result.destination.droppableId.replace("ogi-cat-", ""));

    const sourceItemId = parseInt(result.draggableId.replace("ogi-", ""));
    const sourceCategoryItems = itemsByCategory[sourceCategory];

    const sourceItem = sourceCategoryItems.find((i) => i.id === sourceItemId);

    if (!sourceItem) {
      console.error("Could not find item", sourceItemId, "in category", sourceCategory);
      return;
    }

    // First things first, inform the server that we're re-ordering the items
    reorderItemsTrigger({
      orderGuideId: orderGuide!.id,
      body: {
        itemId: sourceItemId,
        newIndex: result.destination.index,
        categoryId: destinationCategory,
      },
    });

    // Now we need to update the local state to reflect the new order
    const sourceItemIndex = sourceCategoryItems.findIndex((i) => i.id === sourceItemId);
    const destinationCategoryItems = itemsByCategory[destinationCategory];

    // Remove the item from the source category
    sourceCategoryItems.splice(sourceItemIndex, 1);

    // Insert the item into the destination category
    // If the destination splice would put it at the back of the array, use push instead
    if (result.destination.index === destinationCategoryItems.length) {
      destinationCategoryItems.push(sourceItem);
    } else {
      destinationCategoryItems.splice(result.destination.index, 0, sourceItem);
    }

    // Update the items by category
    const itemsByCategoryCopy = { ...itemsByCategory };
    itemsByCategoryCopy[sourceCategory] = sourceCategoryItems;
    itemsByCategoryCopy[destinationCategory] = destinationCategoryItems;

    setItemsByCategory(itemsByCategoryCopy);

    // console.log(items[result.source.index].productLink?.name, "dragged from", result.source.index, "to", result.destination.index);
    //
    // const itemId = parseInt(result.draggableId.replace("draggable-", "").split("-")[1]);
    // const categoryItemsIndex = itemsByCategory.findIndex((c) => c.items.find((i) => i.id === itemId));
    // const category = itemsByCategory[categoryItemsIndex];
    // const categoryItems = category.items;
    // const orderedCategoryItems = reorder(categoryItems, result.source.index, result.destination.index);
    //
    // // Replace the category items with the reordered items
    // const itemsByCategoryCopy = [...itemsByCategory];
    //
    // itemsByCategoryCopy[categoryItemsIndex] = {
    //   ...category,
    //   items: orderedCategoryItems,
    // };
    //
    // setItemsByCategory(itemsByCategoryCopy);
    //
    // reorderItemsTrigger({
    //   orderGuideId: orderGuide.id,
    //   body: {
    //     itemId: items[result.source.index].id,
    //     newIndex: result.destination.index,
    //   },
    // });
  };

  if (!orderGuide) {
    return <LoadingSpinner size="4xl" />;
  }

  return (
    <DragDropContext onDragEnd={onDragItemEnd} dragHandleUsageInstructions="Drag and drop to reorder items">
      {categories.map((category) => (
        <OrderGuideCategoryListing
          key={category.id.toString()}
          orderGuide={orderGuide}
          category={category}
          allowItemReordering={allowItemReordering}
          items={itemsByCategory[category.id]}
        />
      ))}
    </DragDropContext>
  );
}
