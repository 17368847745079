import { useAppSelector } from "@/store/Hooks.ts";
import { selectUserInfo } from "@/store/Auth.Slice.ts";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { ApplicationRole, BusinessRegistrationType, OnboardUserRequestDto, useOnboardUserMutation } from "@/store/IRN.API.ts";
import { LoadingSpinner } from "../../components/LoadingSpinner.tsx";
import { businessFormValidation } from "@/components/forms/BusinessFormValidation.tsx";
import * as yup from "yup";
import { ObjectSchema } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "@/components/ui/form.tsx";
import { AddressForm } from "@/components/forms/AddressForm.tsx";
import { IrnInput, IrnSelect } from "@/components/forms/IrnInput.tsx";
import { useAccount } from "@azure/msal-react";
import { optionalAddressFormValidationSchema } from "@/components/forms/fields/AddressFormValidation.tsx";
import { selectIsOnboarding } from "@/store/Onboarding.slice.ts";
import { DevTool } from "@hookform/devtools";
import { Button } from "@/components/ui/button.tsx";
import { RoleGuard } from "@/components/authentication/RoleGuard.tsx";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion.tsx";
import { JsonDataDump } from "@/components/JsonDataDump.tsx";

const validationSchema: ObjectSchema<OnboardUserRequestDto> = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  // Phone number is optional, but if it's provided, it must be a valid 10 digit phone number
  phoneNumber: yup.string().matches(/^\d{10}$/, {
    message: "Please enter a valid phone number",
    excludeEmptyString: true,
  }),
  business: businessFormValidation,
  address: optionalAddressFormValidationSchema,
});

export function Onboarding() {
  const accountInfo = useAccount();
  const isOnboarding = useAppSelector(selectIsOnboarding);
  // const currentStep = useAppSelector(selectCurrentStep);
  const userInfo = useAppSelector(selectUserInfo);

  const form = useForm<OnboardUserRequestDto>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: accountInfo?.name?.split(" ")[0] ?? "",
      lastName: accountInfo?.name?.split(" ")[1] ?? "",
      phoneNumber: "",
      address: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        careOf: "",
      },
      business: {
        name: "",
        taxId: "",
        phoneNumber: "",
        registrationType: undefined,
        operatingName: "",
        email: "",
        address: {
          address1: "",
          address2: "",
          city: "",
          state: "",
          zipCode: "",
          careOf: "",
        },
      },
    },
  });
  const navigate = useNavigate();
  const [onboardUserMutationTrigger, onboardUserMutation] = useOnboardUserMutation();

  useEffect(() => {
    if (!isOnboarding && userInfo?.hasOnboarded) {
      navigate("/app/dashboard");
    }
  }, [isOnboarding, navigate, userInfo?.hasOnboarded]);

  const onSubmit = (data: OnboardUserRequestDto) => {
    console.log(data);
    // TODO: Handle form submission
    onboardUserMutationTrigger({
      ...data,
    });
  };

  return (
    <div className="container px-5 sm:px-0">
      <h1 className="scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight transition-colors first:mt-0">
        Welcome to <span className="text-brand">IRN</span>!
      </h1>
      <p className="onboarding-welcome-subheader">Please fill out the information below to get started.</p>
      <DevTool control={form.control} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="space-y-10 divide-y divide-gray-900/10">
            <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
              <div className="px-4 sm:px-0">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Personal Information</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  This information will be used to identify you across the platform, and to contact you if necessary.
                </p>
              </div>

              <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div className="px-4 py-6 sm:p-8">
                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                    <div className="col-span-full sm:col-span-1">
                      <IrnInput<OnboardUserRequestDto> label="First Name" name="firstName" control={form.control} autoComplete="given-name" />
                    </div>

                    <div className="col-span-full sm:col-span-1">
                      <IrnInput<OnboardUserRequestDto> label="Last Name" name="lastName" control={form.control} autoComplete="family-name" />
                    </div>

                    <Accordion type="single" collapsible className="col-span-full">
                      <AccordionItem value="1">
                        <AccordionTrigger>Optional information</AccordionTrigger>
                        <AccordionContent className="px-1">
                          <p className="mb-5 leading-5">
                            Providing your phone number and address is optional, but if you provide it, we can use it to contact you if we need to. We will
                            never share your phone number or address with anyone else.
                          </p>
                          <div className="col-span-full">
                            <IrnInput<OnboardUserRequestDto> label="Phone Number" name="phoneNumber" control={form.control} autoComplete="tel" />
                          </div>

                          <div className="col-span-full">
                            {/*<Controller name="address" control={form.control as any} render={({ field }) => <AddressForm control={field as any} />} />*/}
                            <AddressForm control={form.control} namePrefix="address" />
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
              <div className="px-4 sm:px-0">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Business Information</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  This information will be used to send invoices, ship orders, and to identify your business.
                </p>

                <p className="mt-5 text-sm leading-6 text-gray-600">
                  You don't have to fill out all the information presented. You are free to use our services until such time as that information is fully
                  required. At that point, we will ask you to provide the information before you can continue using our services. Required information is marked
                  with a <span className="text-red-500">*</span>.
                </p>
                <p className="mt-5 text-sm leading-6 text-gray-600">
                  Have more businesses? No problem! You'll be able to register more businesses after you complete this onboarding process. For now, let's keep
                  it simple and get you up and running.
                </p>

                <p className="mt-5 text-sm leading-6 text-gray-600">
                  <span className="font-semibold">Why do we require your business ZIP code?</span>
                  <br />
                  We need your business address to make sure we're showing the right products for your service area. Some products and services are only
                  available in certain areas, so we use your ZIP code to make sure we're showing you the right products for your business.
                </p>
              </div>

              <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div className="space-y-5 divide-gray-900/10 px-4 py-6 sm:p-8">
                  <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
                    <IrnInput<OnboardUserRequestDto>
                      control={form.control}
                      name={`business.operatingName`}
                      label="Operating (DBA) Name"
                      description="The operating business name that is shown on your customer's receipts and invoices"
                    />
                    <IrnInput<OnboardUserRequestDto>
                      control={form.control}
                      name={`business.name`}
                      label="Legal Name"
                      description="Enter the name that your business is registered under"
                    />

                    <IrnInput<OnboardUserRequestDto> control={form.control} name={`business.email`} label="Email" description="The email of the business." />
                    <IrnInput<OnboardUserRequestDto>
                      control={form.control}
                      name={`business.phoneNumber`}
                      label="Phone Number"
                      description="The phone number of the business."
                    />

                    <div className="sm:col-span-full">
                      <AddressForm control={form.control} namePrefix={`business.address`} />
                    </div>

                    <Accordion type="single" collapsible className="col-span-full">
                      <AccordionItem value="1">
                        <AccordionTrigger>Optional information</AccordionTrigger>
                        <AccordionContent className="px-1">
                          <div className="sm:col-span-full">
                            <IrnSelect<OnboardUserRequestDto>
                              control={form.control}
                              name={`business.registrationType`}
                              label="Registration Type"
                              description="The registration type of the business as it appears on your tax forms (W9)"
                              options={[
                                { label: "Sole Proprietorship", value: BusinessRegistrationType.SoleProprietorship },
                                { label: "Registered Partnership", value: BusinessRegistrationType.Partnership },
                                { label: "Incorporated", value: BusinessRegistrationType.Incorporated },
                                { label: "Non-Profit", value: BusinessRegistrationType.NonProfit },
                                { label: "LLC (D - Disregarded Entity)", value: BusinessRegistrationType.LlcDisregardedEntity },
                                { label: "LLC (C - Corporation)", value: BusinessRegistrationType.LlccCorporation },
                                { label: "LLC (P - Partnership)", value: BusinessRegistrationType.LlcPartnership },
                                { label: "Sub S Corporation", value: BusinessRegistrationType.SubSCorporation },
                              ]}
                            />
                          </div>
                          <div className="mt-5 sm:col-span-full">
                            <IrnInput<OnboardUserRequestDto>
                              control={form.control}
                              name={`business.taxId`}
                              label="Tax ID"
                              description="The tax ID of the business."
                            />
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10 flex w-full justify-end px-5">
            <Button type="submit" className="w-full sm:w-max" disabled={onboardUserMutation.isLoading}>
              {onboardUserMutation.isLoading && <LoadingSpinner className="mr-2" size="sm" />}
              Continue
            </Button>
          </div>
        </form>
      </Form>
      <RoleGuard roles={[ApplicationRole.Developer]}>
        <JsonDataDump data={form.formState.errors} />
      </RoleGuard>
    </div>
  );
}
