import { cn } from "@/lib/utils.ts";
import * as React from "react";
import { Button } from "./button";

interface NumericInputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export function NumericInput({ className, min, max, step, ...props }: NumericInputProps) {
  const ref = React.useRef<HTMLInputElement>(null);

  let realMin = 0;
  if (min !== undefined) {
    realMin = +min;
  }

  let realMax = 1000;
  if (max !== undefined) {
    realMax = +max;
  }

  let realStep = 1;
  if (step !== undefined) {
    realStep = +step;
  }

  const handleIncrement = () => {
    if (!ref!.current!.value) {
      ref!.current!.value = "1";
      return;
    }
    // Increment the value, only up to the max, by the step value
    const newValue = Math.min(parseFloat(ref!.current!.value || "0") + realStep, realMax);
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, "value")!.set!;
    nativeInputValueSetter.call(ref.current!, newValue);
    const event = new Event("input", { bubbles: true });
    ref.current!.dispatchEvent(event);
  };

  const handleDecrement = () => {
    // console.log("decrementing");
    // if (!ref!.current!.value) {
    //   ref.current!.value = "0";
    //   console.log("decrementing 2");
    //   return;
    // }

    // Decrement the value, only down to the min, by the step value
    const newValue = Math.max(parseFloat(ref!.current!.value || "0") - realStep, realMin);
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, "value")!.set!;
    nativeInputValueSetter.call(ref.current!, newValue);
    const event = new Event("input", { bubbles: true });
    ref.current!.dispatchEvent(event);
    //
    // setValue.call(node, value);
    // node.dispatchEvent(event);
    //
    // ref!.current!.value = newValue.toString();
    // // Trigger the input onChange event to trigger the form validation
    // ref!.current!.dispatchEvent(new Event("input", { bubbles: true }));
  };

  return (
    <div
      className={
        "isolate inline-flex " +
        // If the input, or buttons have focus/focus-visible, add a ring to the group
        "has-[:focus-visible]:rounded-lg has-[:focus-visible]:outline-none has-[:focus-visible]:ring-2 has-[:focus-visible]:ring-ring has-[:focus-visible]:ring-offset-2 "
      }
    >
      <Button
        className="z-10 h-10 w-12 rounded-none rounded-s-lg font-bold disabled:cursor-not-allowed"
        variant="outline"
        onClick={handleDecrement}
        disabled={props.disabled}
      >
        <svg className="h-3 w-3 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h16" />
        </svg>
      </Button>
      <input
        className={cn(
          "flex h-10 border border-input bg-background px-3 py-2 text-sm outline-none ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        type="number"
        min={min ?? 0}
        max={max ?? 1000}
        step={step ?? 1}
        ref={ref}
        {...props}
      />
      <Button
        className="h-10 w-12 rounded-none rounded-e-lg font-bold disabled:cursor-not-allowed"
        variant="outline"
        onClick={handleIncrement}
        disabled={props.disabled}
      >
        <svg className="h-3 w-3 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
        </svg>
      </Button>
    </div>
  );
}
