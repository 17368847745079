import { selectAuthenticated, selectUserRoles } from "@/store/Auth.Slice.ts";
import { useAppSelector } from "@/store/Hooks.ts";
import { ApplicationRole } from "@/store/IRN.API.ts";
import { useEffect, useMemo, useRef, useState } from "react";
import * as screens from "../../tailwind.screens.json";

export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

export function useIsInRole(...roles: ApplicationRole[]): boolean {
  const [isInRole, setIsInRole] = useState(false);
  const userRoles = useAppSelector(selectUserRoles);
  const isAuthenticated = useAppSelector(selectAuthenticated);

  useEffect(() => {
    if (!isAuthenticated) {
      setIsInRole(false);
      return;
    }

    if (roles.length === 0) {
      setIsInRole(false);
      return;
    }

    if (roles.some((role) => userRoles.includes(role))) {
      setIsInRole(true);
      return;
    }

    setIsInRole(false);
  }, [isAuthenticated, roles, userRoles]);

  return isInRole;
}

export function on<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T["addEventListener"]> | [string, Function | null, ...any]
): void {
  if (obj && obj.addEventListener) {
    obj.addEventListener(...(args as Parameters<HTMLElement["addEventListener"]>));
  }
}

export function off<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T["removeEventListener"]> | [string, Function | null, ...any]
): void {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(...(args as Parameters<HTMLElement["removeEventListener"]>));
  }
}

export const isBrowser = typeof window !== "undefined";
export const isNavigator = typeof navigator !== "undefined";

export type Breakpoint = keyof typeof breakpoints;
export const breakpoints = {
  phone: 0,
  tablet: Number.parseInt(screens.tablet),
  laptop: Number.parseInt(screens.laptop),
  desktop: Number.parseInt(screens.desktop),
};

export function useScreenBreakpoint() {
  const [screen, setScreen] = useState(isBrowser ? window.innerWidth : 0);

  useEffect(() => {
    const setSideScreen = (): void => {
      setScreen(window.innerWidth);
    };
    setSideScreen();
    on(window, "resize", setSideScreen);
    return () => {
      off(window, "resize", setSideScreen);
    };
  });
  const sortedBreakpoints = useMemo(() => Object.entries(breakpoints).sort((a, b) => (a[1] >= b[1] ? 1 : -1)), [breakpoints]);
  return sortedBreakpoints.reduce((acc, [name, width]) => {
    if (screen >= width) {
      return name;
    } else {
      return acc;
    }
  }, sortedBreakpoints[0][0]);
}
