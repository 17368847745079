import { useIsInRole } from "@/hooks";
import { useAppSelector } from "@/store/Hooks.ts";
import { selectDevState } from "@/store/Dev.slice.ts";
import { Dialog, Transition } from "@headlessui/react";
import { useClipboard } from "use-clipboard-copy";
import { Fragment, useState } from "react";
import { CommandLineIcon } from "@heroicons/react/24/outline";
import { Button } from "@/components/ui/button.tsx";
import { ApplicationRole } from "@/store/IRN.API.ts";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";

export function DevDataPane() {
  const isDeveloper = useIsInRole(ApplicationRole.Developer);
  const { debugObjectData: data } = useAppSelector(selectDevState);

  const [isOpen, setIsOpen] = useState(false);
  const clipboard = useClipboard();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleCopyToClipboard = () => {
    clipboard.copy(JSON.stringify(data, null, 2));
  };

  const handleDebugAction = () => {
    // devTrigger({
    //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //   // @ts-ignore
    //   debugAction: null,
    // });
  };

  if (!isDeveloper) {
    return null;
  }

  /*<button onClick={() => setIsOpen(!isOpen)} className="cursor-pointer rounded-full border border-brand bg-brand p-2 text-white">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-10 w-10">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m6.75 7.5 3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25Z"
            />
          </svg>
        </button>*/

  return (
    <div>
      <button onClick={handleOpen} className="fixed bottom-5 left-5 z-50 cursor-pointer rounded-full border border-brand bg-brand p-2 text-white">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-10 w-10">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m6.75 7.5 3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25Z"
          />
        </svg>
      </button>

      <Transition show={isOpen} as={Fragment}>
        <Dialog open={isOpen} onClose={handleClose} className="fixed inset-0 overflow-y-auto ">
          <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <div className="inline-block h-full max-h-screen w-full max-w-10xl transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all">
              <div className="bg-white px-4 pb-4 pt-4 sm:p-6 sm:pb-4">
                <div className="">
                  <div className="text-center sm:text-left">
                    <Dialog.Title as="h3" className="border-b border-black text-lg font-semibold leading-6 text-gray-900">
                      Dev Data
                    </Dialog.Title>
                    <div className="mt-2 max-h-[70vh] overflow-auto">
                      <JsonView src={data} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between space-x-5 sm:px-6 sm:py-3">
                <Button onClick={handleDebugAction}>
                  <CommandLineIcon className="mr-2 h-5 w-5" />
                  Debug Action
                </Button>
                <div className="flex space-x-5">
                  <Button onClick={handleCopyToClipboard} variant="secondary">
                    Copy to Clipboard
                  </Button>
                  <Button onClick={handleClose} variant="destructive">
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );

  // By default show a "console" button in the bottom right corner of the screen
  // that will open the dev data pane.

  // When the dev data pane is open, it should show the following:
  // - A button to close the dev data pane
  // - A button to copy the dev data to the clipboard
  // - A button to download the dev data as a file
  // - A JSON dump of the dev data

  // The dev data pane should be draggable and resizable.
}
