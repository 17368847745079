enum LogLevel {
  Error,
  Warn,
  Info,
  Debug,
  Trace,
  Assert,
}

export class Log {
  public static level: LogLevel = process.env.NODE_ENV === "development" ? LogLevel.Assert : LogLevel.Info;
  private static _log: typeof console.info = console.info.bind(console);
  private static _warn: typeof console.warn = console.warn.bind(console);
  private static _error: typeof console.error = console.error.bind(console);
  private static _debug: typeof console.debug = console.debug.bind(console);
  private static _trace: typeof console.trace = console.trace.bind(console);
  private static _assert: typeof console.assert = console.assert.bind(console);

  public static setLogLevel(level: LogLevel) {
    Log.level = level;
  }

  public static info(...args: any[]) {
    if (Log.level >= LogLevel.Info) {
      Log._log(...args);
    }
  }

  public static error(...args: any[]) {
    if (Log.level >= LogLevel.Error) {
      Log._error(...args);
    }
  }

  public static warn(...args: any[]) {
    if (Log.level >= LogLevel.Warn) {
      Log._warn(...args);
    }
  }

  public static debug(...args: any[]) {
    // Debug only in development
    /*if (process.env.NODE_ENV === "development")*/
    if (Log.level >= LogLevel.Debug) {
      Log._debug(...args);
    }
  }

  public static debugInline(...args: any[]) {
    // Debug only in development
    /*if (process.env.NODE_ENV === "development")*/
    if (Log.level >= LogLevel.Debug) {
      Log._debug(...args);
    }
    return true;
  }

  public static object(obj: any) {
    if (Log.level <= LogLevel.Trace) {
      Log._trace(JSON.stringify(obj, null, 2));
    }

    return obj;
  }

  public static assert(condition: boolean, message: string) {
    if (Log.level <= LogLevel.Assert) {
      Log._assert(condition, message);
    }
  }
}
