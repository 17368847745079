import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./Store.ts";
// import { sec } from "../Security.ts";
import { AccountInfo } from "@azure/msal-browser";
import { ApplicationRole, UserInfoDto } from "./IRN.API.ts";

export interface AuthState {
  authenticated: boolean;
  user?: AccountInfo;
  userRoles: ApplicationRole[];
  userInfo?: UserInfoDto;
}

export const initialState: AuthState = {
  authenticated: false,
  userRoles: [],
};

export const loadUserInformationAsync = createAsyncThunk("auth/loadUserInformationAsync", async (_, { getState }) => {
  const accessToken = (getState() as RootState).auth.user?.idToken;

  if (!accessToken) {
    return;
  }

  const response = await fetch("/api/user/auth/roles", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data = await response.json();
  return data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(loadUserInformationAsync.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }

      state.userRoles = action.payload;
    });
  },
  reducers: {
    setAuthenticated: (state, action: PayloadAction<AccountInfo | undefined | null>) => {
      if (!action.payload) {
        return initialState;
      }

      state.authenticated = true;
      state.user = action.payload || undefined;
    },

    setUserInfo: (state, action: PayloadAction<UserInfoDto>) => {
      if (!action.payload) {
        return initialState;
      }

      state.authenticated = true;
      state.userInfo = action.payload;
      state.userRoles = action.payload.roles || [];
    },
  },
});

export const { setAuthenticated, setUserInfo } = authSlice.actions;
export const selectAuthenticated = (state: RootState) => (state.auth as AuthState).authenticated;
export const selectUser = (state: RootState) => (state.auth as AuthState).user;
export const selectUserInfo = (state: RootState) => (state.auth as AuthState).userInfo;
export const selectUserEmail = (state: RootState) => (state.auth as AuthState).user?.idTokenClaims?.email || "";
export const selectUserName = (state: RootState) => (state.auth as AuthState).user?.name;

const selectSelf = (state: RootState) => state;
export const selectUserRoles = createSelector(selectSelf, (state) => state.auth.userInfo?.roles || []);

export default authSlice.reducer;
