import { PlaceholderImage } from "@/components/PlaceholderImage.tsx";
import { OrderGuideItemDto } from "@/store/IRN.API.ts";

export function OrderGuideItemListingImage(props: { item: OrderGuideItemDto }) {
  return (
    <div className="h-[60px] w-[60px] min-w-[60px]">
      {props.item.productLink?.thumbnailUrl ? (
        <img
          alt="Product image"
          className="aspect-square rounded-md object-cover h-[60px] w-[60px]"
          src={props.item.productLink?.thumbnailUrl ?? `https://picsum.photos/seed/${props.item.productLinkId}/200` ?? "/irn-square-240x240.png"}
        />
      ) : (
        <PlaceholderImage className="w-full h-full" />
      )}
    </div>
  );
}
