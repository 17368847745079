import { LoadingSpinner } from "@/components/LoadingSpinner.tsx";
import { selectActiveBusiness, selectBusinesses, setActiveBusiness, setBusinesses } from "@/store/Business.slice.ts";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { useLazyGetBusinessesQuery } from "@/store/IRN.API.ts";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";

export function HydrateBusinesses({ children }: { children?: React.ReactNode }) {
  const businesses = useAppSelector(selectBusinesses);
  const activeBusiness = useAppSelector(selectActiveBusiness);
  const dispatch = useAppDispatch();
  const [getBusinessesTrigger, businessesFromApi] = useLazyGetBusinessesQuery();
  const msal = useMsal();

  useEffect(() => {
    if (msal.accounts.length === 0) {
      return;
    }
    getBusinessesTrigger();
  }, [msal.accounts, getBusinessesTrigger]);

  useEffect(() => {
    // If there is no active business
    // Or the active business is not valid for the current login (happens when users switch between businesses)
    // Set the first business as the active business
    if (businessesFromApi.isLoading || businessesFromApi.isFetching) {
      return;
    }

    if (businessesFromApi.isError) {
      console.error("Error fetching businesses", businessesFromApi.error);
      return;
    }

    if (businessesFromApi.isSuccess) {
      console.info("Successfully fetched businesses", businessesFromApi.data);
    }

    if (businessesFromApi.isSuccess && businessesFromApi.data) {
      console.log("businesses", businessesFromApi.data);
      if (!businesses || businesses.length === 0) {
        console.warn("No businesses found in the store. This should only happen on the first load.");
        dispatch(setActiveBusiness(undefined));
      } else if (businesses.length >= 1 && (!activeBusiness || !businesses.find((b) => b.id === activeBusiness?.id))) {
        // Check if we saved the active business to local storage
        // If not, set the first business as the active business
        const localActiveBusinessId = localStorage.getItem("activeBusinessId");

        if (localActiveBusinessId) {
          const localActiveBusiness = businesses.find((b) => b.id.toString() === localActiveBusinessId);
          if (localActiveBusiness) {
            console.info("Setting the local storage active business as the active business.", localActiveBusiness.id, localActiveBusiness.name);
            dispatch(setActiveBusiness(localActiveBusiness));
            return;
          }
        }

        console.info(
          "Setting the first business as the active business.",
          businesses[0].id,
          businesses[0].name,
          businesses.length,
          !activeBusiness,
          !businesses.find((b) => b.id === activeBusiness?.id),
        );
        dispatch(setActiveBusiness(businesses[0]));
      }
    }
  }, [businesses, activeBusiness, dispatch]);

  useEffect(() => {
    if (businessesFromApi.isSuccess) {
      console.log("Setting businesses in the store", businessesFromApi.data);
      dispatch(setBusinesses(businessesFromApi.data));
    }
  }, [businessesFromApi, dispatch]);

  if (businessesFromApi.isLoading || businessesFromApi.isFetching) {
    return <LoadingSpinner size="4xl" />;
  }

  return <>{children}</>;
}
