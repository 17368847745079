import { IrnInput } from "@/components/forms/IrnInput.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Form } from "@/components/ui/form";
import { yupResolver } from "@hookform/resolvers/yup";
import Payment from "payment";
import { useEffect, useState } from "react";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { InferType } from "yup";

const validationSchema = Yup.object().shape({
  cardNumber: Yup.string()
    .required("Card number is required")
    .test("test-number", "Credit Card number is invalid", (value) => Payment.fns.validateCardNumber(value)),
  expiry: Yup.string()
    .required("Expiry is required")
    .test("test-expiry", "Invalid Expiry", (value) => Payment.fns.validateCardExpiry(value)),
  cvc: Yup.string()
    .required("CVC is required")
    .test("test-cvc", "Invalid CVC", (value) => Payment.fns.validateCardCVC(value)),
  name: Yup.string().required("Name is required"),
  cardType: Yup.string(),
});

type formType = InferType<typeof validationSchema>;

export type CreditCardFormData = formType;

export function CreditCardInput({ onCardSubmit, submitDisabled }: { onCardSubmit: (data: CreditCardFormData) => void; submitDisabled?: boolean }) {
  const form = useForm({
    defaultValues: {
      cardNumber: "4111 1111 1111 1111",
      expiry: "12/30",
      cvc: "123",
      name: "TEST CARDHOLDER",
      cardType: "visa",
    },
    resolver: yupResolver(validationSchema),
  });

  const cardNumberState = form.watch("cardNumber", "");
  const cardExpiryState = form.watch("expiry");
  const cardCvcState = form.watch("cvc");
  // const cardTypeState = form.watch("cardType");

  const [cardMaxLength, setCardMaxLength] = useState(16);

  const onFormSubmit = async (data: formType) => {
    if (!data.cardNumber || !data.expiry || !data.cvc) return;
    if (Payment.fns.validateCardNumber(data.cardNumber) && Payment.fns.validateCardExpiry(data.expiry) && Payment.fns.validateCardCVC(data.cvc)) {
      onCardSubmit(data);
    }
  };

  useEffect(() => {
    const newCardFormat = formatCreditCardNumber(cardNumberState);

    if (newCardFormat !== cardNumberState) {
      form.setValue("cardNumber", newCardFormat as string);
      form.setValue("cardType", Payment.fns.cardType(cardNumberState));
      setCardMaxLength(Payment.fns.cardType(cardNumberState) === "amex" ? 17 : 19);
    }
  }, [cardNumberState, form]);

  useEffect(() => {
    const newCvcFormat = formatCVC(cardCvcState, cardNumberState);

    if (newCvcFormat !== cardCvcState) {
      form.setValue("cvc", newCvcFormat);
    }
  }, [cardCvcState, cardNumberState, form]);

  useEffect(() => {
    const newExpiryFormat = formatExpirationDate(cardExpiryState);

    if (newExpiryFormat !== cardExpiryState) {
      form.setValue("expiry", newExpiryFormat);
    }
  }, [cardExpiryState, form]);

  return (
    <div className="">
      <Form {...form}>
        <form className="space-y-6 mt-10" onSubmit={form.handleSubmit(onFormSubmit)}>
          <IrnInput control={form.control} name="name" label="Cardholder Name" />

          {/*{cardTypeState}*/}
          <IrnInput control={form.control} name="cardNumber" label="Card Number" maxLength={cardMaxLength} />
          <div className="grid grid-cols-2 gap-6 w-full">
            <IrnInput control={form.control} name="expiry" label="Expiry Date" />
            <IrnInput control={form.control} name="cvc" label="CVC" />
          </div>

          <Button type="submit" className="w-full" disabled={submitDisabled}>
            Add Payment Method
          </Button>
        </form>
      </Form>
    </div>
  );
}

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

function formatCreditCardNumber(value: string | undefined) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

function formatCVC(value: string, cardNumber?: string) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (cardNumber) {
    const issuer = Payment.fns.cardType(cardNumber);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

function formatExpirationDate(value: string | undefined) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}
