import { IrnInput } from "@/components/forms/IrnInput.tsx";

export function AddressForm({ control, namePrefix }: { control: any; namePrefix?: string }) {
  if (namePrefix) {
    namePrefix = namePrefix + ".";
  }
  return (
    <div className="grid grid-cols-1 gap-5">
      <div className="col-span-full">
        <IrnInput label="Street Address" name={`${namePrefix}address1`} control={control} autoComplete="street-address" />
      </div>

      <div className="col-span-full">
        <IrnInput label="Street Address 2" name={`${namePrefix}address2`} control={control} autoComplete="address-line2" />
      </div>

      <div className="grid grid-cols-3 gap-5">
        <IrnInput label="City" name={`${namePrefix}city`} control={control} autoComplete="address-level2" />

        <IrnInput label="State" name={`${namePrefix}state`} control={control} autoComplete="address-level1" />
        {/*<StateInputField control={control} label="State" name="state" autoComplete="address-level1" errors={errors.state} />*/}
        {/*<ZipCodeInput control={control} label="ZIP" name="zipCode" autoComplete="postal-code" errors={errors.zipCode} />*/}

        <IrnInput label="Zip Code" name={`${namePrefix}zipCode`} control={control} autoComplete="postal-code" />
        {/*<ZipCodeInput control={control} label="ZIP" name="zipCode" autoComplete="postal-code" errors={errors.zipCode} />*/}
      </div>

      <IrnInput label="Care Of" name={`${namePrefix}careOf`} control={control} autoComplete="address-level1" />
      {/*<StateInputField control={control} label="State" name="state" autoComplete="address-level1" errors={errors.state} />*/}
    </div>
  );
}
