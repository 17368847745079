import { CreateDistributorDialog } from "@/components/dialogs/create-distributor/CreateDistributorDialog.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { Label } from "@/components/ui/label.tsx";
import { SearchCombobox } from "@/components/ui/search-combobox.tsx";
import { cn } from "@/lib/utils.ts";
import { selectActiveBusiness } from "@/store/Business.slice.ts";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { useGetDistributorsQuery } from "@/store/IRN.API.ts";
import { SupportedDistributors } from "@/store/order-guide-upload/wizard/OrderGuideUploadWizard.presets.tsx";
import {
  selectAutoDetectedGuideDistributor,
  selectDistributorId,
  selectSelectedDistributorPreset,
  setCanMoveToNextStep,
  setDistributorId,
  setSelectedDistributorPreset,
} from "@/store/order-guide-upload/wizard/OrderGuideUploadWizard.slice.ts";
import { classNames } from "@/utils.ts";
import { RadioGroup } from "@headlessui/react";
import { BoltIcon, CheckCircleIcon, TruckIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

export function SelectUploadDistributorDefaultsStep() {
  const detectedDistributor = useAppSelector(selectAutoDetectedGuideDistributor);
  const selectedDistributor = useAppSelector(selectSelectedDistributorPreset);
  const uploadDistributorId = useAppSelector(selectDistributorId);
  const uploadBusinessId = useAppSelector(selectActiveBusiness)?.id;
  const distributors = useGetDistributorsQuery(uploadBusinessId ?? 0);
  const [distributorDialogOpen, setDistributorDialogOpen] = useState(false);
  const [userDistributorConfirmed, setUserDistributorConfirmed] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (distributors.data && detectedDistributor) {
      const detectedDistributorId = distributors.data.find((d) => d.name.toLowerCase().includes(detectedDistributor.toLowerCase()))?.id;
      if (detectedDistributorId) {
        dispatch(setDistributorId(detectedDistributorId));
      }
    }
  }, [distributors.data, detectedDistributor]);

  useEffect(() => {
    console.log(selectedDistributor, uploadDistributorId, userDistributorConfirmed);
    if (selectedDistributor) {
      dispatch(setSelectedDistributorPreset(selectedDistributor));
    }
    dispatch(setCanMoveToNextStep(!!selectedDistributor && !!uploadDistributorId && userDistributorConfirmed));
  }, [selectedDistributor, uploadDistributorId, userDistributorConfirmed]);

  useEffect(() => {
    if (detectedDistributor) {
      dispatch(setSelectedDistributorPreset(detectedDistributor?.toLowerCase()));
    }
  }, []);

  return (
    <>
      <div className="p-4">
        <div className="grid gap-4">
          <div className="grid gap-0.5">
            <div className="flex items-center gap-2">
              <TruckIcon className="h-5 w-5" />
              <h3 className="font-semibold">Distributor Options</h3>
            </div>
            <p className="text-sm leading-loose text-gray-500 dark:text-gray-400">Pick your distributor options</p>
          </div>
          {detectedDistributor && (
            <div className="rounded border border-dashed border-brand-500 bg-gray-50 p-4">
              <h3 className="font-semibold">
                <BoltIcon className="inline-block h-5 w-5" />
                Detected
              </h3>
              <p className="text-sm leading-loose text-gray-500 dark:text-gray-400">
                We've detected that this order guide is from <span className="font-semibold text-brand-600">{detectedDistributor}</span>. You can change the
                distributor presets below if needed.
              </p>
            </div>
          )}

          <div className="flex items-center gap-4">
            <RadioGroup value={selectedDistributor} onChange={(value) => dispatch(setSelectedDistributorPreset(value as string))}>
              <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">Select an upload type</RadioGroup.Label>

              <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                {SupportedDistributors.map((distributorOption) => (
                  <RadioGroup.Option
                    key={distributorOption.value}
                    value={distributorOption.value}
                    className={({ active }) =>
                      classNames(
                        active ? "border-brand-500 ring-2 ring-brand-500" : "border-gray-300",
                        "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none",
                      )
                    }
                  >
                    {({ checked, active }) => (
                      <>
                        <div className="flex flex-1">
                          <div className="flex w-full flex-col items-center justify-center gap-4">
                            {distributorOption.logo && (
                              <RadioGroup.Label className="">
                                <img src={distributorOption.logo} alt={distributorOption.name} className="max-h-16 object-cover" />
                              </RadioGroup.Label>
                            )}

                            <RadioGroup.Description
                              as="span"
                              className={cn(
                                "block select-none",
                                distributorOption.value === "other" ? "font-semibold text-brand-600" : "font-medium text-gray-900",
                              )}
                            >
                              {distributorOption.name}
                            </RadioGroup.Description>
                            {distributorOption.value === "other" && (
                              <RadioGroup.Description as="span" className="block select-none text-center text-xs text-gray-600">
                                Select this option if your distributor is not one of our fully supported import options
                              </RadioGroup.Description>
                            )}
                          </div>
                        </div>
                        <CheckCircleIcon className={classNames(!checked ? "invisible" : "", "h-5 w-5 text-brand-500")} aria-hidden="true" />
                        <span
                          className={classNames(
                            active ? "border" : "border-2",
                            checked ? "border-brand-500" : "border-transparent",
                            "pointer-events-none absolute -inset-px rounded-lg",
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
          <p className="place-self-center text-sm text-gray-500">Don't see your distributor? Leave us feedback and we'll add it to our list.</p>

          <hr />

          <div className="max-w-md place-self-center">
            <div className="flex flex-col space-y-2">
              <Label size="md">Distributor</Label>
              <SearchCombobox
                createNew
                hint="Distributor"
                value={uploadDistributorId?.toString() ?? ""}
                setValue={(value) => dispatch(setDistributorId(parseInt(value)))}
                options={
                  distributors.data
                    ?.slice(0)
                    .filter((x) => x.id !== 1)
                    .sort((a, b) => a.id - b.id)
                    .map((d) => ({ value: d.id.toString(), label: d.id + " - " + d.name })) ?? []
                }
                createNewRequested={() => setDistributorDialogOpen(true)}
              />
              <CreateDistributorDialog
                open={distributorDialogOpen}
                setOpen={setDistributorDialogOpen}
                created={(id) => dispatch(setDistributorId(id))}
                businessId={uploadBusinessId ?? 0}
              />
              <p className="text-sm text-muted-foreground">
                Select the distributor that this upload is for. This will determine which products are available when searching. You may create your own
                distributor for future reference.
              </p>

              <div className="flex items-center space-x-2">
                <Checkbox
                  id="confirm-distributor"
                  checked={userDistributorConfirmed}
                  onCheckedChange={(value) => setUserDistributorConfirmed(value === true)}
                ></Checkbox>
                <Label htmlFor="confirm-distributor" className="leading-tight">
                  I confirm that the distributor I selected is correct and I understand that I may not see all products if I select the wrong distributor, or
                  that products may not be matched correctly.
                </Label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
