import { useAlert } from "@/components/dialogs/confirmation-dialog/ConfirmationDialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Label } from "@/components/ui/label.tsx";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select.tsx";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { OrderGuideUploadColumnType } from "@/store/IRN.API.ts";
import {
  selectColumnNames,
  selectColumnSelectionErrors,
  selectColumnTypes,
  selectRecords,
  setColumnSelectionError,
  setColumnTypes,
} from "@/store/order-guide-upload/wizard/OrderGuideUploadWizard.slice.ts";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { clsx } from "clsx";
import { ReactNode, useEffect, useState } from "react";

export function UploadColumnBase({
  disabled,
  colType,
  display,
  helpBody,
  required,
}: {
  disabled?: boolean;
  colType: OrderGuideUploadColumnType;
  display: string;
  helpBody?: ReactNode;
  required?: boolean;
}) {
  const appDispatch = useAppDispatch();
  const columnTypes: Record<OrderGuideUploadColumnType, string | null> | undefined = useAppSelector(selectColumnTypes);
  const columns = useAppSelector(selectColumnNames) ?? [];
  const records = useAppSelector(selectRecords);
  const alertBox = useAlert();
  const [columnRecordValue, setColumnRecordValue] = useState<string>("");
  // const errorColumns = useAppSelector(selectOrderGuideUploadErrorColumns);
  const errorColumns = useAppSelector(selectColumnSelectionErrors);
  const error = errorColumns?.[colType];

  const columnKey = columnTypes?.[colType] ?? undefined;

  useEffect(() => {
    // Instead, we can use the following:
    // Get the first record with a value for the column type
    if (records && columnTypes && columnKey) {
      if (!columnKey) {
        setColumnRecordValue("");
        return;
      }

      const record = records.find((r) => r.record[columnKey]);
      if (record) {
        setColumnRecordValue(record.record[columnKey]);
      }
    }
  }, [columnKey, columnTypes, colType, records]);

  useEffect(() => {
    // If this is a required column, show an error when the column is not set
    if (required && (!columnKey || columnKey === "Unknown")) {
      appDispatch(setColumnSelectionError({ column: colType, error: `'${display}' is required` }));
    } else {
      appDispatch(setColumnSelectionError({ column: colType, error: undefined }));
    }
  }, [columnKey, required]);

  return (
    <div className={clsx("grid grid-cols-2 rounded border bg-white p-2 shadow", error ? "border-red-500" : "")} key={colType}>
      <Label htmlFor={colType} className={clsx("ml-2 flex flex-col", disabled ? "text-gray-400" : "")}>
        <div className="mr-2 flex items-center justify-between gap-x-2">
          <span className={error ? "font-semibold text-destructive" : ""}>
            {display} {required && <span className="text-red-500">*</span>}
          </span>

          {helpBody && (
            <Button
              variant="icon-gray"
              size="sm-icon"
              onClick={() => {
                alertBox({
                  title: display,
                  body: helpBody,
                });
              }}
            >
              <QuestionMarkCircleIcon />
            </Button>
          )}
        </div>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <span className="text-xs text-gray-500">{columnRecordValue}</span>
        {error && <span className="text-xs text-red-500">{error}</span>}
      </Label>
      <Select
        onValueChange={(event) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const cts: Record<OrderGuideUploadColumnType, string | null> = { ...columnTypes };
          cts[colType as OrderGuideUploadColumnType] = event.toString() || null;
          appDispatch(setColumnTypes(cts));
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={columnKey ?? undefined}
        disabled={disabled}
      >
        <SelectTrigger>
          <SelectValue placeholder=""></SelectValue>
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="Unknown">&nbsp;</SelectItem>
          {columns.map((col) => (
            <SelectItem key={col} value={col}>
              {col}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
