import { LoadingSpinner } from "@/components/LoadingSpinner.tsx";
import { DevDataProvider } from "@/components/dev/DevDataProvider.tsx";
import { useConfirm } from "@/components/dialogs/confirmation-dialog/ConfirmationDialog.tsx";
import { WaitingStatusDialog } from "@/components/dialogs/waiting-status-dialog.tsx";
import { AnimatedCheckmark } from "@/components/display/AnimatedCheckmark.tsx";
import { ActionButton } from "@/components/order-guide-upload/ActionButton";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion.tsx";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert.tsx";
import { Badge } from "@/components/ui/badge.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Label } from "@/components/ui/label.tsx";
import { Progress } from "@/components/ui/progress.tsx";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import { FileRecordPreview } from "@/pages/app/order-guide/upload/wizard/steps/components/FileRecordPreview.tsx";
import { MatchingProductDisplay } from "@/pages/app/order-guide/upload/wizard/steps/components/MatchingProductDisplay.tsx";
import { ProductsSearchTable } from "@/pages/app/order-guide/upload/wizard/steps/components/ProductsSearchTable.tsx";
import { ReviewAndMatchItemsPaginationButtons } from "@/pages/app/order-guide/upload/wizard/steps/components/ReviewAndMatchItemsPaginationButtons.tsx";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import {
  UploadProcessingStatus,
  UploadType,
  useConfirmOrderGuideUploadMutation,
  useGetOrderGuideSummariesQuery,
  useLazyGetOrderGuideUploadEntryQuery,
  useLazyGetOrderGuideUploadQuery,
} from "@/store/IRN.API.ts";
import * as ProductMatching from "@/store/order-guide-upload/product-matching/OrderGuideUploadProductMatching.slice.ts";
import { ParsedEntryData, createCustomEntryProduct } from "@/store/order-guide-upload/product-matching/OrderGuideUploadProductMatching.slice.ts";
import { resetWizard, selectShowAdvancedControls } from "@/store/order-guide-upload/wizard/OrderGuideUploadWizard.slice.ts";
import { CheckIcon } from "@heroicons/react/20/solid";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/solid";
import { ActivityIcon } from "lucide-react";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface ProductEditField {
  label: string;
  key: string;
  getValue: (entryInfo: ParsedEntryData | undefined) => string | number | undefined;
  required: boolean;
  type?: string;
}

const productEditFields: ProductEditField[] = [
  { label: "Product Name", key: "Name", getValue: (entryInfo: ParsedEntryData | undefined) => entryInfo?.Name, required: true },
  { label: "Brand", key: "Brand", getValue: (entryInfo: ParsedEntryData | undefined) => entryInfo?.Brand, required: false },
  { label: "Product Price", key: "Price", getValue: (entryInfo: ParsedEntryData | undefined) => entryInfo?.Price, required: true, type: "number" },
  { label: "Price Per Lb/Piece", key: "PricePerPiece", getValue: (entryInfo: ParsedEntryData | undefined) => entryInfo?.PricePerPiece, required: false },
  { label: "Units Per Case", key: "UnitsPerCase", getValue: (entryInfo: ParsedEntryData | undefined) => entryInfo?.UnitsPerCase, required: false },
  { label: "Manufacturer #", key: "MPN", getValue: (entryInfo: ParsedEntryData | undefined) => entryInfo?.MPN, required: false },
  { label: "UPC/GTIN", key: "UPC", getValue: (entryInfo: ParsedEntryData | undefined) => entryInfo?.UPC, required: false },

  { label: "Unit Size", key: "UnitSize", getValue: (entryInfo: ParsedEntryData | undefined) => entryInfo?.UnitSize, required: false },
  { label: "Distributor SKU", key: "SKU", getValue: (entryInfo: ParsedEntryData | undefined) => entryInfo?.SKU, required: false },
];

const advancedEditFields: ProductEditField[] = [
  { label: "Unit", key: "Unit", getValue: (entryInfo: ParsedEntryData | undefined) => entryInfo?.Unit, required: false },
  { label: "Size", key: "Size", getValue: (entryInfo: ParsedEntryData | undefined) => entryInfo?.Size, required: false },
  { label: "Category ID", key: "CategoryId", getValue: (entryInfo: ParsedEntryData | undefined) => entryInfo?.CategoryId, required: false },
  { label: "Thumbnail", key: "Thumbnail", getValue: (entryInfo: ParsedEntryData | undefined) => entryInfo?.Thumbnail, required: false },
  { label: "Images", key: "Images", getValue: (entryInfo: ParsedEntryData | undefined) => entryInfo?.Images?.join(","), required: false },
  { label: "Product Category", key: "Category", getValue: (entryInfo: ParsedEntryData | undefined) => entryInfo?.Category, required: false },
  {
    label: "Product Description",
    key: "Description",
    getValue: (entryInfo: ParsedEntryData | undefined) => entryInfo?.Description,
    required: false,
    type: "textarea",
  },
];

export function ReviewAndMatchItemsStep() {
  const params = useParams();
  const uploadId = useMemo(() => parseInt(params.uploadId ?? "0"), [params]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [getOrderGuideUploadQueryTrigger, { data: upload, isLoading: uploadIsLoading }] = useLazyGetOrderGuideUploadQuery();

  const currentEntryIndex = useAppSelector(ProductMatching.selectCurrentEntryIndex) ?? 0;

  const [getEntryTrigger, { data: entry, isSuccess: getEntryResultIsSuccess, isLoading: getEntryResultIsLoading, isError: getEntryResultIsError }] =
    useLazyGetOrderGuideUploadEntryQuery();

  // const entry = useAppSelector(selectCurrentEntry);
  const entryInfo = useAppSelector(ProductMatching.selectCurrentEntryParsedData);
  const entryProduct = useAppSelector(ProductMatching.selectCurrentEntryProductLink);
  const loadingEntry = useAppSelector(ProductMatching.selectLoadingEntry);
  const showAdvancedControls = useAppSelector(selectShowAdvancedControls);
  const isConfirmingExactMatches = useAppSelector(ProductMatching.selectIsConfirmingExactMatches);
  const totalExactMatches = useAppSelector(ProductMatching.selectTotalExactMatches);
  const numExactMatchesConfirmed = useAppSelector(ProductMatching.selectNumExactMatchesConfirmed);
  const availableOrderGuides = useGetOrderGuideSummariesQuery();
  const [confirmUploadTrigger] = useConfirmOrderGuideUploadMutation();
  const [editMode] = useState(false);

  const [showMatchingProductDisplay, setShowMatchingProductDisplay] = useState(false);
  const [showProductsSearchTable, setShowProductsSearchTable] = useState(false);
  const [showNoMatchingProductDisplay, setShowNoMatchingProductDisplay] = useState(false);
  const [confirmedEntryCount, setConfirmedEntryCount] = useState(0);
  const [uploadEntriesLength, setUploadEntriesLength] = useState(0);

  const [selectedOrderGuideForMerge, setSelectedOrderGuideForMerge] = useState<string | null>(null);

  const confirmationDialog = useConfirm();

  // Whenever we mount/remount, go ahead and request the "current" entry info
  useEffect(() => {
    dispatch(ProductMatching.setUploadId(uploadId));
    dispatch(ProductMatching.resetWithId(uploadId));
    dispatch(ProductMatching.gotoNextEntry(0));
    dispatch(resetWizard());
  }, []);

  useEffect(() => {
    if (uploadId) {
      console.log("Getting upload for", uploadId);
      getOrderGuideUploadQueryTrigger(uploadId, true);
    }
  }, [uploadId]);

  useEffect(() => {
    if (upload) {
      setUploadEntriesLength(upload.entries.length);
      setConfirmedEntryCount(upload.entries.filter((e) => e.status === UploadProcessingStatus.Confirmed).length);
    }
  }, [upload]);

  useEffect(() => {
    if (uploadId && upload) {
      getEntryTrigger({
        uploadId: uploadId,
        entryId: upload!.entries[currentEntryIndex].id,
      });
    }
  }, [uploadId, currentEntryIndex, upload]);

  useEffect(() => {
    if (getEntryResultIsSuccess && entry) {
      dispatch(ProductMatching.setCurrentEntry(entry));
    }
  }, [getEntryResultIsSuccess, entry]);

  useEffect(() => {
    setShowMatchingProductDisplay(
      !!entry &&
        !!entry?.productLinkId &&
        entry?.productLinkId !== 1 &&
        (entry?.status === UploadProcessingStatus.Completed || entry?.status == UploadProcessingStatus.Confirmed),
    );

    setShowProductsSearchTable(
      !!entry &&
        (!entry?.productLinkId ||
          (entry.potentialProductIds && entry.potentialProductIds.length > 0) ||
          entry.productLinkId == 1 ||
          entry.status === UploadProcessingStatus.Completed),
    );

    setShowNoMatchingProductDisplay(
      !!entry &&
        (!entry.productLinkId || entry.productLinkId === 1) &&
        (!entry.potentialProductIds || entry.potentialProductIds.length == 0) &&
        (entry.status === UploadProcessingStatus.Completed || entry.status === UploadProcessingStatus.Failed),
    );
  }, [entry, entry?.productLinkId]);

  useEffect(() => {
    if (confirmedEntryCount > 0 && confirmedEntryCount === uploadEntriesLength) {
      confirmationDialog({
        title: "Confirm Upload",
        body: (
          <div className="grid w-full place-items-center gap-5 text-center">
            <AnimatedCheckmark />
            <p>You have confirmed all the products in this upload!</p>
            <p>You may continue to review the products you&apos;ve already confirmed, or click &quot;Continue&quot; to finish with this upload.</p>
          </div>
        ),
        actionButton: "Continue",
        actionVariant: "success",
        cancelButton: "Back to Upload",
      }).then((result) => {
        if (upload?.uploadType === UploadType.LoadIntoOrderGuide) {
          requestOrderGuideToMergeInto();
        } else if (result) {
          console.log("Confirmed upload");
          confirmUploadTrigger({
            uploadId: uploadId,
            loadItemsIntoOrderGuideId: null,
            uploadType: UploadType.NewOrderGuide,
          }).then((result) => {
            console.log(result);
            if ("data" in result && result.data) {
              navigate(`/app/order-guide/${result.data}`);
            } else {
              navigate(`/app/order-guide`);
            }
          });
        }
      });
    }
  }, [confirmedEntryCount]);

  function requestOrderGuideToMergeInto(firstAttempt = true) {
    confirmationDialog({
      title: "Select Order Guide",
      body: (
        <div className="grid w-full place-items-center gap-10">
          <p>You have requested that this upload be merged into another order guide. Please select the order guide you would like to merge this upload into.</p>
          <Select onValueChange={setSelectedOrderGuideForMerge}>
            <SelectTrigger>
              <SelectValue placeholder="Select an order guide" />
            </SelectTrigger>
            <SelectContent>
              {availableOrderGuides.data
                ?.filter((og) => og.businessId === upload!.businessId)
                .map((option) => (
                  <SelectItem key={option.id} value={option.id.toString()}>
                    {option.name}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>

          {!firstAttempt && (
            <Alert variant="destructive">
              <ExclamationCircleIcon className="h-5 w-5" />
              <AlertTitle>Invalid selection</AlertTitle>
              <AlertDescription>Please select an order guide to merge this upload into.</AlertDescription>
            </Alert>
          )}
        </div>
      ),
      actionButton: "Continue",
      actionVariant: "success",
      cancelButton: "Back to Upload",
    }).then((result) => {
      if (!result) {
        return;
      }
      console.log("Selected order guide for merge", selectedOrderGuideForMerge);
      if (!selectedOrderGuideForMerge) {
        // Continue to the next loop
        requestOrderGuideToMergeInto(false);
      } else {
        confirmUploadTrigger({
          uploadId: uploadId,
          loadItemsIntoOrderGuideId: parseInt(selectedOrderGuideForMerge),
          uploadType: UploadType.LoadIntoOrderGuide,
        }).then((result) => {
          console.log(result);
          if ("data" in result && result.data) {
            navigate(`/app/order-guide/${result.data}`);
          } else {
            navigate(`/app/order-guide`);
          }
        });
      }
    });
  }

  const renderPaginationButtons = () => {
    return (
      <ReviewAndMatchItemsPaginationButtons
        uploadEntriesLength={uploadEntriesLength}
        entry={entry}
        upload={upload}
        showMatchingProductDisplay={showMatchingProductDisplay}
      />
    );
  };

  if (!upload || uploadIsLoading) {
    return <LoadingSpinner size="4xl" />;
  }

  return (
    <div className="mx-auto max-w-8xl">
      <WaitingStatusDialog title="Confirming matches" open={isConfirmingExactMatches}>
        <div className="flex flex-col w-full place-items-center justify-center p-4 gap-10">
          <LoadingSpinner size="4xl" />
          <p>
            Confirming exact matches ({numExactMatchesConfirmed} of {totalExactMatches})
          </p>
        </div>
      </WaitingStatusDialog>
      <DevDataProvider data={{ uploadId, upload, entry, entryProduct }} />
      <div className="rounded-lg border bg-white">
        <div className="flex items-center border-b p-4">
          <div className="flex items-center gap-2">
            <ActivityIcon className="h-6 w-6" />
            <div>
              <h2 className="text-lg font-semibold leading-none">Product review</h2>
              <p className="leading-none text-gray-600">Follow the steps to match or create your uploaded products.</p>
            </div>
          </div>
          <div className="ml-auto flex items-center gap-2 text-sm">
            {confirmedEntryCount === uploadEntriesLength && (
              <Button variant="success">
                <CheckIcon className="mr-2 h-4 w-4" />
                Confirm Upload
              </Button>
            )}
            <ActionButton upload={upload} />
          </div>
        </div>
        <div className="p-4">
          {/*<div className="flex justify-between">*/}
          {/*  <div className="flex items-center space-x-2">*/}
          {/*    <Switch*/}
          {/*      id="skip-confident-matches"*/}
          {/*      checked={skipConfidentMatches}*/}
          {/*      onCheckedChange={(x) => dispatch(ProductMatching.setSkipConfidentMatches(x))}*/}
          {/*    />*/}
          {/*    <Label htmlFor="skip-confident-matches">Skip Confident Matches</Label>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="mx-auto grid w-full max-w-[90vw] grid-cols-1 gap-8 pb-12 lg:grid-cols-5 md:px-8">
            <div className="md:col-span-2">
              <div className="overflow-hidden rounded-lg border">
                <Progress value={confirmedEntryCount} max={upload?.entries.length ?? 100} className="h-3 rounded-b-none rounded-t-none"></Progress>
                <div className="flex justify-between bg-card p-6 shadow">
                  <div className="grid gap-1">
                    <h2 className="flex items-center text-xl font-semibold leading-3">
                      Product {currentEntryIndex + 1} of {uploadEntriesLength}
                    </h2>
                    <div className="text-xs text-gray-500">ID: {entry?.id}</div>
                    <div>
                      {entry?.status === UploadProcessingStatus.Completed && entry?.productLinkId && entry?.productLinkId === 1 ? (
                        <Badge variant="purple">No matches</Badge>
                      ) : entry?.status === UploadProcessingStatus.Completed ? (
                        <Badge variant="yellow">Unconfirmed</Badge>
                      ) : entry?.status === UploadProcessingStatus.Failed ? (
                        <Badge variant="destructive">Failed</Badge>
                      ) : entry?.status === UploadProcessingStatus.Confirmed ? (
                        <Badge variant="green">Confirmed</Badge>
                      ) : (
                        <Badge variant="blue">Processing</Badge>
                      )}
                    </div>
                  </div>

                  <div className="flex gap-4">{renderPaginationButtons()}</div>
                </div>
                <div className="grid gap-5 p-6">
                  {getEntryResultIsLoading && <LoadingSpinner />}
                  {getEntryResultIsError && <div>Error loading entry</div>}
                  {getEntryResultIsSuccess && !entry && <div>No entry found</div>}
                  {getEntryResultIsSuccess && entry && entryInfo && (
                    <>
                      {editMode ? (
                        <EditProductForms entryInfo={entryInfo!} />
                      ) : (
                        <ProductView entryInfo={entryInfo!} productEditFields={productEditFields.concat(advancedEditFields)} />
                      )}
                    </>
                  )}
                </div>
                {/*<div className="flex w-full justify-end gap-4 border-t p-6">{renderPaginationButtons()}</div>*/}
              </div>
            </div>
            <div className="space-y-4 md:col-span-3">
              {loadingEntry && <LoadingSpinner />}

              {showMatchingProductDisplay ? (
                <MatchingProductDisplay entryProduct={entryProduct} entry={entry} entryInfo={entryInfo} upload={upload!} />
              ) : (
                <div className="flex w-full justify-between">
                  <Button
                    onClick={() => {
                      console.log("creating custom product");
                      dispatch(
                        createCustomEntryProduct({
                          entryId: entry!.id,
                          uploadId: upload!.id,
                        }),
                      );
                    }}
                  >
                    <PlusIcon className="mr-1 h-5 w-5" />
                    Create custom product
                  </Button>
                </div>
              )}

              {showNoMatchingProductDisplay && (
                <Alert variant="destructive" dismissibleId="product-no-matches-alert">
                  <ExclamationCircleIcon className="h-5 w-5" />
                  <AlertTitle dismissibleId="product-no-matches-alert">No matches found for this product</AlertTitle>
                  <AlertDescription>
                    If you can&apos;t find the product you&apos;re looking for, you can create a custom product, or search the entire product catalog via the
                    search bar below.
                  </AlertDescription>
                </Alert>
              )}

              {showProductsSearchTable && <ProductsSearchTable loadingEntry={loadingEntry} upload={upload} entry={entry!} />}

              {showAdvancedControls && <FileRecordPreview />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function EditProductForms({ entryInfo }: { entryInfo: ParsedEntryData }) {
  return (
    <>
      <div className="grid grid-cols-2 items-center gap-3">
        {productEditFields.map((field) => (
          <Fragment key={field.key}>
            <Label htmlFor={field.key} className="">
              {field.label}
            </Label>
            {field.type === "textarea" ? (
              <Textarea className="" id={field.key} value={field.getValue(entryInfo) || undefined} />
            ) : (
              <Input className="" id={field.key} type={field.type} value={field.getValue(entryInfo) || undefined} />
            )}
          </Fragment>
        ))}
      </div>
      <Accordion collapsible type="single" className="max-w-full">
        <AccordionItem value="1">
          <AccordionTrigger>
            <h4>Advanced fields</h4>
          </AccordionTrigger>
          <AccordionContent>
            <div className="grid grid-cols-2 items-center gap-3">
              {advancedEditFields.map((field) => (
                <Fragment key={field.key}>
                  <Label htmlFor={field.key} className="">
                    {field.label}
                  </Label>
                  <Input className="" id={field.key} type={field.type} value={field.getValue(entryInfo) || undefined} />
                </Fragment>
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  );
}

function ProductView({ entryInfo, productEditFields }: { entryInfo: ParsedEntryData; productEditFields: any[] }) {
  return (
    <>
      <div className="grid grid-cols-2 items-center gap-3">
        {productEditFields
          .filter((field) => !!field.getValue(entryInfo))
          .map((field) => (
            <Fragment key={field.key}>
              <Label htmlFor={field.key} className="text-right font-semibold">
                {field.label}
              </Label>
              <p>{field.getValue(entryInfo) || "N/A"}</p>
            </Fragment>
          ))}
      </div>
    </>
  );
}
