import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./Store.ts";
import { OrderGuideItemDto } from "./IRN.API.ts";

const orderGuideItemEntityAdapter = createEntityAdapter<OrderGuideItemDto>();

export const orderGuideItemSlice = createSlice({
  name: "orderGuideItem",
  initialState: orderGuideItemEntityAdapter.getInitialState(),
  reducers: {
    orderGuideItemsAdded: orderGuideItemEntityAdapter.addOne,
    orderGuideItemsRemoved: orderGuideItemEntityAdapter.removeOne,
    orderGuideItemsUpdated: orderGuideItemEntityAdapter.updateOne,
    orderGuideManyItemsUpdated: orderGuideItemEntityAdapter.updateMany,

    orderGuideItemsLoaded: (state, action: PayloadAction<OrderGuideItemDto[]>) => {
      orderGuideItemEntityAdapter.removeAll(state);

      if (!action?.payload || action.payload.length === 0) {
        return;
      }

      return orderGuideItemEntityAdapter.addMany(state, action.payload);
    },

    clearOrderGuideItems: orderGuideItemEntityAdapter.removeAll,
  },
});

export const { orderGuideItemsAdded, orderGuideItemsRemoved, orderGuideItemsUpdated, clearOrderGuideItems, orderGuideItemsLoaded, orderGuideManyItemsUpdated } =
  orderGuideItemSlice.actions;
export default orderGuideItemSlice.reducer;
export const orderGuideItemsEntitySelectors = orderGuideItemEntityAdapter.getSelectors();

export const selectOrderGuideItemQuantity = (id: string) => (state: RootState) =>
  orderGuideItemsEntitySelectors.selectById(state.orderGuideItems, id)?.quantity ?? 0;

export const selectOrderGuideItem = (id: string) => (state: RootState) => orderGuideItemsEntitySelectors.selectById(state.orderGuideItems, id);

export const selectOrderGuideItems = (state: RootState) => orderGuideItemsEntitySelectors.selectAll(state.orderGuideItems);

export const selectOrderGuideItemsByIds = (ids: number[]) => (state: RootState) =>
  orderGuideItemsEntitySelectors.selectAll(state.orderGuideItems).filter((item) => ids.includes(item.id));

export const currentOrderGuideItemsTotalItems = (state: RootState) => {
  const items = orderGuideItemsEntitySelectors.selectAll(state.orderGuideItems);
  return items.reduce((acc, item) => acc + item.quantity, 0);
};

export const currentOrderGuideItemsTotalPrice = (state: RootState) => {
  const items = orderGuideItemsEntitySelectors.selectAll(state.orderGuideItems);
  return items.reduce((acc, item) => acc + item.quantity * (item.productLink?.products?.[0]?.price ?? 0), 0);
};
