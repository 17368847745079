import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./Store.ts";
import { OrderGuideDto, OrderGuideItemDto, OrderGuideSummaryDto } from "./IRN.API.ts";

export interface OrderGuideState {
  orderGuides: OrderGuideDto[];

  currentGuide?: OrderGuideDto;
  currentItems?: OrderGuideItemDto[];

  addToGuideLastSelectedBusinessId?: number;
  addToGuideLastSelectedGuideId?: number;
  orderGuideSummaries?: OrderGuideSummaryDto[];
  orderGuidesForLastBusiness?: OrderGuideSummaryDto[];

  enableParEditing?: boolean;
}

export const initialState: OrderGuideState = {
  orderGuides: [],
};

export const orderGuideSlice = createSlice({
  name: "orderGuide",
  initialState,
  reducers: {
    setOrderGuides: (state, action: PayloadAction<OrderGuideDto[]>) => {
      state.orderGuides = action.payload;
    },
    setCurrentOrderGuide: (state, action: PayloadAction<OrderGuideDto | undefined>) => {
      state.currentGuide = action.payload;
      state.currentItems = action.payload?.items ?? undefined;
    },
    setCurrentOrderGuideItems: (state, action: PayloadAction<OrderGuideItemDto[] | undefined>) => {
      state.currentItems = action.payload;
    },

    setAddToGuideLastSelectedBusinessId: (state, action: PayloadAction<number>) => {
      state.addToGuideLastSelectedBusinessId = action.payload;

      // Clear the last selected guide id when the business changes, and use the first guide in the list
      state.addToGuideLastSelectedGuideId = undefined;

      if (state.orderGuideSummaries && state.orderGuideSummaries.length > 0) {
        state.orderGuidesForLastBusiness = state.orderGuideSummaries.filter((x) => x.businessId === action.payload);

        if (state.orderGuidesForLastBusiness.length > 0) {
          state.addToGuideLastSelectedGuideId = state.orderGuidesForLastBusiness[0].id;
        }
      }
    },

    setAddToGuideLastSelectedGuideId: (state, action: PayloadAction<number>) => {
      state.addToGuideLastSelectedGuideId = action.payload;
    },

    setOrderGuideSummaries: (state, action: PayloadAction<OrderGuideSummaryDto[]>) => {
      state.orderGuideSummaries = action.payload;
    },

    setEnableParEditing: (state, action: PayloadAction<boolean>) => {
      state.enableParEditing = action.payload;
    },
  },
});

export const {
  setOrderGuides,
  setCurrentOrderGuide,
  setCurrentOrderGuideItems,
  setAddToGuideLastSelectedBusinessId,
  setAddToGuideLastSelectedGuideId,
  setOrderGuideSummaries,
  setEnableParEditing,
} = orderGuideSlice.actions;
export const selectOrderGuides = (state: RootState) => state.orderGuide.orderGuides;
export const selectCurrentOrderGuide = (state: RootState) => state.orderGuide.currentGuide;
export const selectCurrentItems = (state: RootState) => state.orderGuide.currentItems;
export const selectAddToGuideLastSelectedBusinessId = (state: RootState) => state.orderGuide.addToGuideLastSelectedBusinessId;
export const selectAddToGuideLastSelectedGuideId = (state: RootState) => state.orderGuide.addToGuideLastSelectedGuideId;
export const selectOrderGuidesForLastBusiness: (state: RootState) => OrderGuideSummaryDto[] | undefined = (state: RootState) =>
  state.orderGuide.orderGuidesForLastBusiness;
export const selectEnableParEditing = (state: RootState) => state.orderGuide.enableParEditing;

export default orderGuideSlice.reducer;
