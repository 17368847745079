import { useRouteError } from "react-router-dom";
import { Log } from "@/Log.ts";

export function ErrorPage() {
  const error = useRouteError() as any;
  Log.debug(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      {/* Show the error Type */}
      <p>
        <strong>{error?.name}</strong>
      </p>
      <p>
        <i>{error?.message}</i>
      </p>
      {/*<RoleGuard roles={["developer"]}>*/}
      <code>
        <pre>{JSON.stringify(error)}</pre>
      </code>
      <code>
        <pre>{error?.stack}</pre>
      </code>
      {/*<DevDataProvider data={error} />*/}
      {/*</RoleGuard>*/}
    </div>
  );
}
