import { Card } from "@/components/ui/card.tsx";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

export function LinkCard({ to, children, className }: { to: string; children: ReactNode; className?: string }) {
  return (
    <Link to={to} className="group p-0.5 focus-visible:outline-none">
      <Card className={"outline outline-[1px] outline-brand/25 shadow-md group-hover:outline-1 group-focus-visible:outline-1 bg-white" + className}>
        {children}
      </Card>
    </Link>
  );
}
