import { apiSlice as api } from "./API.slice";
export const addTagTypes = [
  "IRN.API",
  "business",
  "order-guide",
  "order-guide-upload",
  "order-guide-upload-entry",
  "orders",
  "payment",
  "products",
  "user",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getHealthz: build.query<GetHealthzApiResponse, GetHealthzApiArg>({
        query: () => ({ url: `/-/healthz` }),
        providesTags: ["IRN.API"],
      }),
      postApiContactUs: build.mutation<PostApiContactUsApiResponse, PostApiContactUsApiArg>({
        query: (queryArg) => ({ url: `/api/contact-us`, method: "POST", body: queryArg }),
        invalidatesTags: ["IRN.API"],
      }),
      postApiMsg: build.mutation<PostApiMsgApiResponse, PostApiMsgApiArg>({
        query: () => ({ url: `/api/msg`, method: "POST" }),
        invalidatesTags: ["IRN.API"],
      }),
      getBusinesses: build.query<GetBusinessesApiResponse, GetBusinessesApiArg>({
        query: () => ({ url: `/api/v1/businesses` }),
        providesTags: ["business"],
      }),
      createBusiness: build.mutation<CreateBusinessApiResponse, CreateBusinessApiArg>({
        query: (queryArg) => ({ url: `/api/v1/businesses`, method: "POST", body: queryArg }),
        invalidatesTags: ["business"],
      }),
      getBusiness: build.query<GetBusinessApiResponse, GetBusinessApiArg>({
        query: (queryArg) => ({ url: `/api/v1/businesses/${queryArg}` }),
        providesTags: ["business"],
      }),
      updateBusiness: build.mutation<UpdateBusinessApiResponse, UpdateBusinessApiArg>({
        query: (queryArg) => ({ url: `/api/v1/businesses/${queryArg.businessId}`, method: "PUT", body: queryArg.body }),
        invalidatesTags: ["business"],
      }),
      deleteBusiness: build.mutation<DeleteBusinessApiResponse, DeleteBusinessApiArg>({
        query: (queryArg) => ({ url: `/api/v1/businesses/${queryArg}`, method: "DELETE" }),
        invalidatesTags: ["business"],
      }),
      getBusinessStats: build.query<GetBusinessStatsApiResponse, GetBusinessStatsApiArg>({
        query: (queryArg) => ({ url: `/api/v1/businesses/${queryArg}/stats` }),
        providesTags: ["business"],
      }),
      getOrderGuideSummaries: build.query<GetOrderGuideSummariesApiResponse, GetOrderGuideSummariesApiArg>({
        query: () => ({ url: `/api/v1/order-guide/summaries` }),
        providesTags: ["order-guide"],
      }),
      createOrderGuide: build.mutation<CreateOrderGuideApiResponse, CreateOrderGuideApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide`, method: "POST", body: queryArg }),
        invalidatesTags: ["order-guide"],
      }),
      addProductToOrderGuide: build.mutation<AddProductToOrderGuideApiResponse, AddProductToOrderGuideApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/${queryArg.orderGuideId}/product/${queryArg.productLinkId}`, method: "POST" }),
        invalidatesTags: ["order-guide"],
      }),
      removeProductFromOrderGuide: build.mutation<RemoveProductFromOrderGuideApiResponse, RemoveProductFromOrderGuideApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/${queryArg.orderGuideId}/product/${queryArg.productLinkId}`, method: "DELETE" }),
        invalidatesTags: ["order-guide"],
      }),
      setProductQuantityInOrderGuide: build.mutation<SetProductQuantityInOrderGuideApiResponse, SetProductQuantityInOrderGuideApiArg>({
        query: (queryArg) => ({
          url: `/api/v1/order-guide/${queryArg.orderGuideId}/product/${queryArg.productLinkId}/quantity`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["order-guide"],
      }),
      createOrderFromOrderGuide: build.mutation<CreateOrderFromOrderGuideApiResponse, CreateOrderFromOrderGuideApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/${queryArg}/order`, method: "POST" }),
        invalidatesTags: ["order-guide"],
      }),
      getOrderGuide: build.query<GetOrderGuideApiResponse, GetOrderGuideApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/${queryArg}` }),
        providesTags: ["order-guide"],
      }),
      updateOrderGuide: build.mutation<UpdateOrderGuideApiResponse, UpdateOrderGuideApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/${queryArg.orderGuideId}`, method: "PUT", body: queryArg.body }),
        invalidatesTags: ["order-guide"],
      }),
      deleteOrderGuide: build.mutation<DeleteOrderGuideApiResponse, DeleteOrderGuideApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/${queryArg}`, method: "DELETE" }),
        invalidatesTags: ["order-guide"],
      }),
      getOrderGuideDisplay: build.query<GetOrderGuideDisplayApiResponse, GetOrderGuideDisplayApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/${queryArg}/display` }),
        providesTags: ["order-guide"],
      }),
      changeOrderGuideType: build.mutation<ChangeOrderGuideTypeApiResponse, ChangeOrderGuideTypeApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/${queryArg.orderGuideId}/mode/${queryArg["type"]}`, method: "PUT" }),
        invalidatesTags: ["order-guide"],
      }),
      createOrderGuideItemCategory: build.mutation<CreateOrderGuideItemCategoryApiResponse, CreateOrderGuideItemCategoryApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/${queryArg.orderGuideId}/category`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["order-guide"],
      }),
      reorderOrderGuideCategories: build.mutation<ReorderOrderGuideCategoriesApiResponse, ReorderOrderGuideCategoriesApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/${queryArg.orderGuideId}/category/reorder`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["order-guide"],
      }),
      deleteOrderGuideItemCategory: build.mutation<DeleteOrderGuideItemCategoryApiResponse, DeleteOrderGuideItemCategoryApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/${queryArg.orderGuideId}/category/${queryArg.categoryId}`, method: "DELETE" }),
        invalidatesTags: ["order-guide"],
      }),
      reorderOrderGuideItems: build.mutation<ReorderOrderGuideItemsApiResponse, ReorderOrderGuideItemsApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/${queryArg.orderGuideId}/items/reorder`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["order-guide"],
      }),
      uploadOrderGuide: build.mutation<UploadOrderGuideApiResponse, UploadOrderGuideApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/uploads/${queryArg.businessId}/new`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["order-guide-upload"],
      }),
      getOrderGuideUploadColumnTypes: build.query<GetOrderGuideUploadColumnTypesApiResponse, GetOrderGuideUploadColumnTypesApiArg>({
        query: () => ({ url: `/api/v1/order-guide/uploads/column-types` }),
        providesTags: ["order-guide-upload"],
      }),
      getOrderGuideUpload: build.query<GetOrderGuideUploadApiResponse, GetOrderGuideUploadApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/uploads/${queryArg}` }),
        providesTags: ["order-guide-upload"],
      }),
      deleteOrderGuideUpload: build.mutation<DeleteOrderGuideUploadApiResponse, DeleteOrderGuideUploadApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/uploads/${queryArg}`, method: "DELETE" }),
        invalidatesTags: ["order-guide-upload"],
      }),
      getOrderGuideUploadSummaries: build.query<GetOrderGuideUploadSummariesApiResponse, GetOrderGuideUploadSummariesApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/uploads/${queryArg}/summaries` }),
        providesTags: ["order-guide-upload"],
      }),
      confirmOrderGuideUpload: build.mutation<ConfirmOrderGuideUploadApiResponse, ConfirmOrderGuideUploadApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/uploads/confirm`, method: "POST", body: queryArg }),
        invalidatesTags: ["order-guide-upload"],
      }),
      getOrderGuideUploadEntry: build.query<GetOrderGuideUploadEntryApiResponse, GetOrderGuideUploadEntryApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/uploads/${queryArg.uploadId}/entries/${queryArg.entryId}` }),
        providesTags: ["order-guide-upload", "order-guide-upload-entry"],
      }),
      confirmOrderGuideUploadEntry: build.mutation<ConfirmOrderGuideUploadEntryApiResponse, ConfirmOrderGuideUploadEntryApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/uploads/${queryArg.uploadId}/entries/confirm`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["order-guide-upload", "order-guide-upload-entry"],
      }),
      unlinkOrderGuideUploadEntry: build.mutation<UnlinkOrderGuideUploadEntryApiResponse, UnlinkOrderGuideUploadEntryApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/uploads/${queryArg.uploadId}/entries/unlink`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["order-guide-upload", "order-guide-upload-entry"],
      }),
      setProductOrderGuideUploadEntry: build.mutation<SetProductOrderGuideUploadEntryApiResponse, SetProductOrderGuideUploadEntryApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/uploads/${queryArg.uploadId}/entries/set-product`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["order-guide-upload", "order-guide-upload-entry"],
      }),
      createProductFromOrderGuideEntry: build.mutation<CreateProductFromOrderGuideEntryApiResponse, CreateProductFromOrderGuideEntryApiArg>({
        query: (queryArg) => ({ url: `/api/v1/order-guide/uploads/${queryArg.uploadId}/entries/create-product`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["order-guide-upload", "order-guide-upload-entry"],
      }),
      getRecentOrders: build.query<GetRecentOrdersApiResponse, GetRecentOrdersApiArg>({
        query: (queryArg) => ({ url: `/api/v1/orders/${queryArg}/recent` }),
        providesTags: ["orders"],
      }),
      getOrders: build.query<GetOrdersApiResponse, GetOrdersApiArg>({
        query: (queryArg) => ({
          url: `/api/v1/orders`,
          params: {
            startDate: queryArg.startDate,
            endDate: queryArg.endDate,
            status: queryArg.status,
            businessId: queryArg.businessId,
            skip: queryArg.skip,
            take: queryArg.take,
          },
        }),
        providesTags: ["orders"],
      }),
      getOrderDetailsById: build.query<GetOrderDetailsByIdApiResponse, GetOrderDetailsByIdApiArg>({
        query: (queryArg) => ({ url: `/api/v1/orders/${queryArg}` }),
        providesTags: ["orders"],
      }),
      completeOrder: build.mutation<CompleteOrderApiResponse, CompleteOrderApiArg>({
        query: (queryArg) => ({ url: `/api/v1/orders/${queryArg}/complete`, method: "POST" }),
        invalidatesTags: ["orders"],
      }),
      getHostedPaymentToken: build.query<GetHostedPaymentTokenApiResponse, GetHostedPaymentTokenApiArg>({
        query: (queryArg) => ({ url: `/api/v1/orders/${queryArg}/hosted-payment-token` }),
        providesTags: ["orders"],
      }),
      createPaymentMethod: build.mutation<CreatePaymentMethodApiResponse, CreatePaymentMethodApiArg>({
        query: (queryArg) => ({ url: `/api/v1/payment/${queryArg.businessId}/method`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["payment"],
      }),
      deletePaymentMethod: build.mutation<DeletePaymentMethodApiResponse, DeletePaymentMethodApiArg>({
        query: (queryArg) => ({ url: `/api/v1/payment/${queryArg.businessId}/method/${queryArg.paymentMethodId}`, method: "DELETE" }),
        invalidatesTags: ["payment"],
      }),
      getBusinessPaymentMethods: build.query<GetBusinessPaymentMethodsApiResponse, GetBusinessPaymentMethodsApiArg>({
        query: (queryArg) => ({ url: `/api/v1/payment/${queryArg}/methods` }),
        providesTags: ["payment"],
      }),
      submitPayment: build.mutation<SubmitPaymentApiResponse, SubmitPaymentApiArg>({
        query: (queryArg) => ({ url: `/api/v1/payment/submit`, method: "POST", body: queryArg }),
        invalidatesTags: ["payment"],
      }),
      searchProducts: build.mutation<SearchProductsApiResponse, SearchProductsApiArg>({
        query: (queryArg) => ({ url: `/api/v1/products/search`, method: "POST", body: queryArg }),
        invalidatesTags: ["products"],
      }),
      getSearchProductFacets: build.query<GetSearchProductFacetsApiResponse, GetSearchProductFacetsApiArg>({
        query: (queryArg) => ({ url: `/api/v1/products/search/facets`, params: { businessId: queryArg } }),
        providesTags: ["products"],
      }),
      getDistributors: build.query<GetDistributorsApiResponse, GetDistributorsApiArg>({
        query: (queryArg) => ({ url: `/api/v1/products/distributors`, params: { businessId: queryArg } }),
        providesTags: ["products"],
      }),
      createDistributor: build.mutation<CreateDistributorApiResponse, CreateDistributorApiArg>({
        query: (queryArg) => ({ url: `/api/v1/products/distributor`, method: "POST", body: queryArg }),
        invalidatesTags: ["products"],
      }),
      getProductLink: build.query<GetProductLinkApiResponse, GetProductLinkApiArg>({
        query: (queryArg) => ({ url: `/api/v1/products/${queryArg.id}`, params: { includePendingUploadProducts: queryArg.includePendingUploadProducts } }),
        providesTags: ["products"],
      }),
      getProductPriceHistory: build.query<GetProductPriceHistoryApiResponse, GetProductPriceHistoryApiArg>({
        query: (queryArg) => ({ url: `/api/v1/products/${queryArg}/price-history` }),
        providesTags: ["products"],
      }),
      getUser: build.query<GetUserApiResponse, GetUserApiArg>({
        query: () => ({ url: `/api/v1/user` }),
        providesTags: ["user"],
      }),
      getUserAuthRoles: build.query<GetUserAuthRolesApiResponse, GetUserAuthRolesApiArg>({
        query: () => ({ url: `/api/v1/user/auth/roles` }),
        providesTags: ["user"],
      }),
      updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
        query: (queryArg) => ({ url: `/api/v1/user/contact-info`, method: "PUT", body: queryArg }),
        invalidatesTags: ["user"],
      }),
      onboardUser: build.mutation<OnboardUserApiResponse, OnboardUserApiArg>({
        query: (queryArg) => ({ url: `/api/v1/user/onboard`, method: "POST", body: queryArg }),
        invalidatesTags: ["user"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as irnApi };
export type GetHealthzApiResponse = unknown;
export type GetHealthzApiArg = void;
export type PostApiContactUsApiResponse = unknown;
export type PostApiContactUsApiArg = ContactRequest;
export type PostApiMsgApiResponse = unknown;
export type PostApiMsgApiArg = void;
export type GetBusinessesApiResponse = /** status 200 OK */ BusinessDto[];
export type GetBusinessesApiArg = void;
export type CreateBusinessApiResponse = /** status 200 OK */ BusinessDto;
export type CreateBusinessApiArg = CreateBusinessRequestDto;
export type GetBusinessApiResponse = /** status 200 OK */ BusinessDto;
export type GetBusinessApiArg = number;
export type UpdateBusinessApiResponse = /** status 200 OK */ BusinessDto;
export type UpdateBusinessApiArg = {
  businessId: number;
  body: CreateBusinessRequestDto;
};
export type DeleteBusinessApiResponse = /** status 200 OK */ BusinessDto;
export type DeleteBusinessApiArg = number;
export type GetBusinessStatsApiResponse = /** status 200 OK */ BusinessStatDto[];
export type GetBusinessStatsApiArg = number;
export type GetOrderGuideSummariesApiResponse = /** status 200 OK */ OrderGuideSummaryDto[];
export type GetOrderGuideSummariesApiArg = void;
export type CreateOrderGuideApiResponse = /** status 200 OK */ OrderGuideDto;
export type CreateOrderGuideApiArg = CreateOrderGuideRequestDto;
export type AddProductToOrderGuideApiResponse = /** status 200 OK */ number;
export type AddProductToOrderGuideApiArg = {
  orderGuideId: number;
  productLinkId: number;
};
export type RemoveProductFromOrderGuideApiResponse = /** status 200 OK */ string;
export type RemoveProductFromOrderGuideApiArg = {
  orderGuideId: number;
  productLinkId: number;
};
export type SetProductQuantityInOrderGuideApiResponse = /** status 200 OK */ OrderGuideItemDto;
export type SetProductQuantityInOrderGuideApiArg = {
  orderGuideId: number;
  productLinkId: number;
  body: UpdateOrderGuideItemQuantityRequestDto;
};
export type CreateOrderFromOrderGuideApiResponse = /** status 200 OK */ OrderDetailsDto;
export type CreateOrderFromOrderGuideApiArg = number;
export type GetOrderGuideApiResponse = /** status 200 OK */ OrderGuideDto;
export type GetOrderGuideApiArg = number;
export type UpdateOrderGuideApiResponse = /** status 200 OK */ OrderGuideDto;
export type UpdateOrderGuideApiArg = {
  orderGuideId: number;
  body: UpdateOrderGuideRequestDto;
};
export type DeleteOrderGuideApiResponse = unknown;
export type DeleteOrderGuideApiArg = number;
export type GetOrderGuideDisplayApiResponse = /** status 200 OK */ OrderGuideDisplayDto;
export type GetOrderGuideDisplayApiArg = number;
export type ChangeOrderGuideTypeApiResponse = unknown;
export type ChangeOrderGuideTypeApiArg = {
  orderGuideId: number;
  type: string;
};
export type CreateOrderGuideItemCategoryApiResponse = /** status 200 OK */ OrderGuideItemCategoryDto;
export type CreateOrderGuideItemCategoryApiArg = {
  orderGuideId: number;
  body: CreateOrderGuideItemCategoryRequestDto;
};
export type ReorderOrderGuideCategoriesApiResponse = unknown;
export type ReorderOrderGuideCategoriesApiArg = {
  orderGuideId: number;
  body: ReorderOrderGuideCategoriesRequestDto;
};
export type DeleteOrderGuideItemCategoryApiResponse = unknown;
export type DeleteOrderGuideItemCategoryApiArg = {
  orderGuideId: number;
  categoryId: number;
};
export type ReorderOrderGuideItemsApiResponse = unknown;
export type ReorderOrderGuideItemsApiArg = {
  orderGuideId: number;
  body: ReorderOrderGuideItemsRequestDto;
};
export type UploadOrderGuideApiResponse = unknown;
export type UploadOrderGuideApiArg = {
  businessId: number;
  body: UploadOrderGuideRequestDto;
};
export type GetOrderGuideUploadColumnTypesApiResponse = /** status 200 OK */ OrderGuideUploadColumnType[];
export type GetOrderGuideUploadColumnTypesApiArg = void;
export type GetOrderGuideUploadApiResponse = /** status 200 OK */ OrderGuideUploadDto;
export type GetOrderGuideUploadApiArg = number;
export type DeleteOrderGuideUploadApiResponse = unknown;
export type DeleteOrderGuideUploadApiArg = number;
export type GetOrderGuideUploadSummariesApiResponse = /** status 200 OK */ OrderGuideUploadSummaryDto[];
export type GetOrderGuideUploadSummariesApiArg = number;
export type ConfirmOrderGuideUploadApiResponse = /** status 200 OK */ number;
export type ConfirmOrderGuideUploadApiArg = ConfirmOrderGuideUploadRequestDto;
export type GetOrderGuideUploadEntryApiResponse = /** status 200 OK */ OrderGuideUploadEntryDto;
export type GetOrderGuideUploadEntryApiArg = {
  uploadId: number;
  entryId: number;
};
export type ConfirmOrderGuideUploadEntryApiResponse = unknown;
export type ConfirmOrderGuideUploadEntryApiArg = {
  uploadId: number;
  body: ConfirmUploadEntryRequestDto;
};
export type UnlinkOrderGuideUploadEntryApiResponse = unknown;
export type UnlinkOrderGuideUploadEntryApiArg = {
  uploadId: number;
  body: UnlinkUploadEntryRequestDto;
};
export type SetProductOrderGuideUploadEntryApiResponse = /** status 200 OK */ OrderGuideUploadEntryDto;
export type SetProductOrderGuideUploadEntryApiArg = {
  uploadId: number;
  body: SetProductUploadEntryRequestDto;
};
export type CreateProductFromOrderGuideEntryApiResponse = /** status 200 OK */ ProductLinkDto;
export type CreateProductFromOrderGuideEntryApiArg = {
  uploadId: number;
  body: CreateProductFromOrderGuideUploadEntryRequestDto;
};
export type GetRecentOrdersApiResponse = /** status 200 OK */ OrderSummaryDto[];
export type GetRecentOrdersApiArg = number;
export type GetOrdersApiResponse = /** status 200 OK */ OrderSummaryDto[];
export type GetOrdersApiArg = {
  startDate?: string;
  endDate?: string;
  status?: OrderStatus[];
  businessId?: number[];
  skip?: number;
  take?: number;
};
export type GetOrderDetailsByIdApiResponse = /** status 200 OK */ OrderDetailsDto;
export type GetOrderDetailsByIdApiArg = number;
export type CompleteOrderApiResponse = /** status 200 OK */ OrderDetailsDto;
export type CompleteOrderApiArg = number;
export type GetHostedPaymentTokenApiResponse = /** status 200 OK */ string;
export type GetHostedPaymentTokenApiArg = number;
export type CreatePaymentMethodApiResponse = /** status 200 OK */ CreatePaymentMethodResponse;
export type CreatePaymentMethodApiArg = {
  businessId: number;
  body: CreatePaymentMethodRequest;
};
export type DeletePaymentMethodApiResponse = unknown;
export type DeletePaymentMethodApiArg = {
  businessId: number;
  paymentMethodId: number;
};
export type GetBusinessPaymentMethodsApiResponse = /** status 200 OK */ PaymentMethodDto[];
export type GetBusinessPaymentMethodsApiArg = number;
export type SubmitPaymentApiResponse = /** status 200 OK */ TransactionResponse;
export type SubmitPaymentApiArg = SubmitPaymentRequest;
export type SearchProductsApiResponse = /** status 200 OK */ ListProductsResult;
export type SearchProductsApiArg = ListProductsRequest;
export type GetSearchProductFacetsApiResponse = /** status 200 OK */ SearchFacetDto[];
export type GetSearchProductFacetsApiArg = number;
export type GetDistributorsApiResponse = /** status 200 OK */ DistributorDto[];
export type GetDistributorsApiArg = number;
export type CreateDistributorApiResponse = /** status 200 OK */ DistributorDto;
export type CreateDistributorApiArg = CreateDistributorRequest;
export type GetProductLinkApiResponse = /** status 200 OK */ ProductLinkDto;
export type GetProductLinkApiArg = {
  id: number;
  includePendingUploadProducts?: boolean;
};
export type GetProductPriceHistoryApiResponse = /** status 200 OK */ ProductPriceHistoryDto;
export type GetProductPriceHistoryApiArg = number;
export type GetUserApiResponse = /** status 200 OK */ UserInfoDto;
export type GetUserApiArg = void;
export type GetUserAuthRolesApiResponse = /** status 200 OK */ string[];
export type GetUserAuthRolesApiArg = void;
export type UpdateUserApiResponse = /** status 200 OK */ UpdateUserInfoResponseDto;
export type UpdateUserApiArg = UpdateUserRequestDto;
export type OnboardUserApiResponse = /** status 200 OK */ boolean;
export type OnboardUserApiArg = OnboardUserRequestDto;
export type ContactRequest = {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string | null;
  message: string;
};
export type AddressDto = {
  careOf?: string | null;
  address1: string;
  address2?: string | null;
  city: string;
  state: string;
  zipCode: string;
};
export type BusinessDto = {
  id: number;
  name: string;
  address: AddressDto;
  taxId?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  operatingDbaName?: string | null;
  registrationType?: BusinessRegistrationType | null;
  createdAt: string;
};
export type CreateBusinessRequestDto = {
  name: string;
  taxId?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  operatingName?: string | null;
  address: AddressDto;
  registrationType?: BusinessRegistrationType | null;
};
export type BusinessStatDatumDto = {
  key: string;
  value: number;
};
export type BusinessStatDto = {
  name: string;
  stats: BusinessStatDatumDto[];
};
export type OrderGuideSummaryDto = {
  id: number;
  name: string;
  businessName: string;
  businessId: number;
  itemCount: number;
  itemsRequested: number;
  currentEstimatedTotal: number;
};
export type DistributorDto = {
  id: number;
  name: string;
  imageUrl?: string | null;
  ownedByBusinessId: number;
};
export type ProductDto = {
  id: number;
  name: string;
  sku?: string | null;
  description?: string | null;
  thumbnailUrl?: string | null;
  imageUrls?: string[] | null;
  price: number;
  unit: UnitSize;
  size: number;
  unitSize?: string | null;
  updatedAt: string;
  distributor?: DistributorDto | null;
  pricePerPiece?: number | null;
  unitsPerCase?: number | null;
  isCatchWeight: boolean;
};
export type ProductLinkDto = {
  id: number;
  name: string;
  brand: string;
  mpn?: string | null;
  upc?: string | null;
  description?: string | null;
  thumbnailUrl?: string | null;
  imageUrls?: string[] | null;
  categoryId?: number | null;
  categoryName?: string | null;
  unit: UnitSize;
  size: number;
  unitSize?: string | null;
  products?: ProductDto[] | null;
};
export type OrderGuideItemDto = {
  id: number;
  productLinkId: number;
  quantity: number;
  par?: number | null;
  onHand?: number | null;
  productLink?: ProductLinkDto | null;
  index: number;
  categoryId?: number | null;
};
export type OrderGuideItemCategoryDto = {
  id: number;
  name: string;
};
export type OrderGuideCategoryOrderDto = {
  category: OrderGuideItemCategoryDto;
  index: number;
};
export type OrderGuideDto = {
  id: number;
  businessId: number;
  name: string;
  description?: string | null;
  type: OrderGuideType;
  items: OrderGuideItemDto[];
  categories: OrderGuideCategoryOrderDto[];
};
export type CreateOrderGuideRequestDto = {
  businessId: number;
  name: string;
};
export type UpdateOrderGuideItemQuantityRequestDto = {
  quantity?: number | null;
  par?: number | null;
  onHand?: number | null;
};
export type OrderLineItemDto = {
  id: number;
  quantity: number;
  product: ProductDto;
};
export type OrderDetailsDto = {
  id: number;
  orderId: string;
  createdAt: string;
  status: OrderStatus;
  total: number;
  lineItems?: OrderLineItemDto[] | null;
  business?: BusinessDto | null;
};
export type UpdateOrderGuideRequestDto = {
  name?: string | null;
  description?: string | null;
};
export type OrderGuideDisplayCategoryDto = {
  id: number;
  name: string;
  index: number;
  items: OrderGuideItemDto[];
};
export type OrderGuideDisplayDto = {
  id: number;
  businessId: number;
  name: string;
  description?: string | null;
  type: OrderGuideType;
  categories: OrderGuideDisplayCategoryDto[];
};
export type CreateOrderGuideItemCategoryRequestDto = {
  name: string;
};
export type ReorderOrderGuideCategoriesRequestDto = {
  orderedCategoryIds: number[];
};
export type ReorderOrderGuideItemsRequestDto = {
  categoryId: number;
  itemId: number;
  newIndex: number;
};
export type UploadOrderGuideRequestDto = {
  fileName: string;
  fileType: string;
  fileSize: number;
  columns: string[];
  records: {
    [key: string]: any;
  }[];
  dataString?: string | null;
  columnTypes: {
    Unknown: string;
    Name: string;
    Description: string;
    Price: string;
    Unit: string;
    Size: string;
    UnitSize: string;
    Brand: string;
    Category: string;
    CategoryId: string;
    MPN: string;
    UPC: string;
    SKU: string;
    Thumbnail: string;
    Images: string;
    PricePerUnit: string;
    UnitsPerCase: string;
    IsCatchWeight: string;
  };
  skipFirstRow: boolean;
  extractMpnUpcFromDescription: boolean;
  distributorId: number;
  uploadType: UploadType;
};
export type OrderGuideUploadEntryDto = {
  id: number;
  rowData: string;
  potentialProductIds?: number[] | null;
  potentialProductScores?: number[] | null;
  status: UploadProcessingStatus;
  productLinkId?: number | null;
  parsedData?: string | null;
  productLink?: ProductLinkDto | null;
  errorReason?: string | null;
};
export type OrderGuideUploadDto = {
  id: number;
  businessId: number;
  distributorId: number;
  status: UploadProcessingStatus;
  uploadType: UploadType;
  message?: string | null;
  fileKey: string;
  entryCount: number;
  fileColumns: string[];
  fileType: string;
  fileSize: number;
  skipFirstRow: boolean;
  extractMpnUpcFromDescription: boolean;
  isInternalUpload: boolean;
  columnTypes: any;
  entries: OrderGuideUploadEntryDto[];
  createdAt: string;
  updatedAt: string;
};
export type OrderGuideUploadSummaryDto = {
  id: number;
  businessId: number;
  status: string;
  message?: string | null;
  fileKey: string;
  entryCount: number;
  processedEntryCount: number;
  createdAt: string;
  updatedAt: string;
  eta?: string | null;
  itemsPerSecond?: number | null;
};
export type ConfirmOrderGuideUploadRequestDto = {
  uploadId: number;
  uploadType: UploadType;
  loadItemsIntoOrderGuideId?: number | null;
};
export type ConfirmUploadEntryRequestDto = {
  entryId: number;
};
export type UnlinkUploadEntryRequestDto = {
  entryId: number;
};
export type SetProductUploadEntryRequestDto = {
  entryId: number;
  productLinkId: number;
};
export type CreateProductFromOrderGuideUploadEntryRequestDto = {
  entryId: number;
};
export type OrderSummaryDto = {
  id: number;
  orderId: string;
  createdAt: string;
  status: OrderStatus;
  total: number;
  business: BusinessDto;
};
export type CreatePaymentMethodResponse = {
  paymentMethodId: number;
};
export type CreatePaymentMethodRequest = {
  paymentMethodType: string;
  paymentMethodToken: string;
};
export type CreditCardMethodDto = {
  cardType: string;
  maskedCardNumber: string;
  expirationDate: string;
};
export type BankAccountMethodDto = {
  accountType: string;
  maskedAccountNumber: string;
};
export type PaymentMethodDto = {
  id: number;
  isCreditCard: boolean;
  creditCard?: CreditCardMethodDto | null;
  bankAccount?: BankAccountMethodDto | null;
};
export type TransactionResponse = {
  transactionId: string;
  transactionStatus: string;
  authCode: string;
};
export type SubmitPaymentRequest = {
  businessId: number;
  orderId: number;
  paymentMethodId: number;
  useAvailableCredit?: boolean | null;
};
export type SearchFacetDto = {
  facet: SearchFacet;
  value: string;
};
export type ListProductsResult = {
  totalCount: number;
  products: ProductLinkDto[];
  facets?: SearchFacetDto[] | null;
};
export type ListProductsRequest = {
  businessId: number;
  skip: number;
  take: number;
  search?: string | null;
  facets?: SearchFacetDto[] | null;
};
export type CreateDistributorRequest = {
  businessId: number;
  name: string;
  minimumOrderAmount: number;
  minimumOrderQuantity: number;
  servicesZipCodes?: string[] | null;
  contactName: string;
  contactEmail: string;
  contactPhone?: string | null;
};
export type ProductPriceHistoryDto = {
  distributors: string[];
  data: {
    [key: string]: string;
  }[];
};
export type UserInfoDto = {
  id: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  businesses?: BusinessDto[] | null;
  roles?: ApplicationRole[] | null;
  hasOnboarded: boolean;
  metadata?: {
    [key: string]: string;
  } | null;
};
export type UpdateUserInfoResponseDto = {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};
export type UpdateUserRequestDto = {
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
};
export type OnboardUserRequestDto = {
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  address: AddressDto;
  business: CreateBusinessRequestDto;
};
export enum BusinessRegistrationType {
  Unknown = "Unknown",
  SoleProprietorship = "SoleProprietorship",
  Partnership = "Partnership",
  Incorporated = "Incorporated",
  NonProfit = "NonProfit",
  LlcDisregardedEntity = "LLCDisregardedEntity",
  LlccCorporation = "LLCCCorporation",
  LlcsCorporation = "LLCSCorporation",
  LlcPartnership = "LLCPartnership",
  SubSCorporation = "SubSCorporation",
}
export enum OrderGuideType {
  Quantity = "Quantity",
  Par = "Par",
}
export enum UnitSize {
  Unknown = "Unknown",
  Each = "Each",
  Case = "Case",
  Pound = "Pound",
}
export enum OrderStatus {
  NotPlaced = "NotPlaced",
  Pending = "Pending",
  Shipped = "Shipped",
  Delivered = "Delivered",
  Cancelled = "Cancelled",
  Returned = "Returned",
  Refunded = "Refunded",
  Disputed = "Disputed",
}
export enum UploadType {
  NewOrderGuide = "NewOrderGuide",
  PricingOnly = "PricingOnly",
  LoadIntoOrderGuide = "LoadIntoOrderGuide",
}
export enum OrderGuideUploadColumnType {
  Unknown = "Unknown",
  Name = "Name",
  Description = "Description",
  Price = "Price",
  Unit = "Unit",
  Size = "Size",
  UnitSize = "UnitSize",
  Brand = "Brand",
  Category = "Category",
  CategoryId = "CategoryId",
  Mpn = "MPN",
  Upc = "UPC",
  Sku = "SKU",
  Thumbnail = "Thumbnail",
  Images = "Images",
  PricePerUnit = "PricePerUnit",
  UnitsPerCase = "UnitsPerCase",
  IsCatchWeight = "IsCatchWeight",
}
export enum UploadProcessingStatus {
  Unknown = "Unknown",
  Pending = "Pending",
  Processing = "Processing",
  Completed = "Completed",
  Failed = "Failed",
  Confirmed = "Confirmed",
}
export enum SearchFacet {
  Name = "Name",
  Brand = "Brand",
  Mpn = "MPN",
  Upc = "UPC",
  Sku = "SKU",
  UnitSize = "UnitSize",
  Category = "Category",
  Distributor = "Distributor",
  DistributorId = "DistributorId",
  Id = "Id",
  Description = "Description",
}
export enum ApplicationRole {
  None = "None",
  Moderator = "Moderator",
  Administrator = "Administrator",
  SuperAdministrator = "SuperAdministrator",
  Developer = "Developer",
}
export const {
  useGetHealthzQuery,
  useLazyGetHealthzQuery,
  usePostApiContactUsMutation,
  usePostApiMsgMutation,
  useGetBusinessesQuery,
  useLazyGetBusinessesQuery,
  useCreateBusinessMutation,
  useGetBusinessQuery,
  useLazyGetBusinessQuery,
  useUpdateBusinessMutation,
  useDeleteBusinessMutation,
  useGetBusinessStatsQuery,
  useLazyGetBusinessStatsQuery,
  useGetOrderGuideSummariesQuery,
  useLazyGetOrderGuideSummariesQuery,
  useCreateOrderGuideMutation,
  useAddProductToOrderGuideMutation,
  useRemoveProductFromOrderGuideMutation,
  useSetProductQuantityInOrderGuideMutation,
  useCreateOrderFromOrderGuideMutation,
  useGetOrderGuideQuery,
  useLazyGetOrderGuideQuery,
  useUpdateOrderGuideMutation,
  useDeleteOrderGuideMutation,
  useGetOrderGuideDisplayQuery,
  useLazyGetOrderGuideDisplayQuery,
  useChangeOrderGuideTypeMutation,
  useCreateOrderGuideItemCategoryMutation,
  useReorderOrderGuideCategoriesMutation,
  useDeleteOrderGuideItemCategoryMutation,
  useReorderOrderGuideItemsMutation,
  useUploadOrderGuideMutation,
  useGetOrderGuideUploadColumnTypesQuery,
  useLazyGetOrderGuideUploadColumnTypesQuery,
  useGetOrderGuideUploadQuery,
  useLazyGetOrderGuideUploadQuery,
  useDeleteOrderGuideUploadMutation,
  useGetOrderGuideUploadSummariesQuery,
  useLazyGetOrderGuideUploadSummariesQuery,
  useConfirmOrderGuideUploadMutation,
  useGetOrderGuideUploadEntryQuery,
  useLazyGetOrderGuideUploadEntryQuery,
  useConfirmOrderGuideUploadEntryMutation,
  useUnlinkOrderGuideUploadEntryMutation,
  useSetProductOrderGuideUploadEntryMutation,
  useCreateProductFromOrderGuideEntryMutation,
  useGetRecentOrdersQuery,
  useLazyGetRecentOrdersQuery,
  useGetOrdersQuery,
  useLazyGetOrdersQuery,
  useGetOrderDetailsByIdQuery,
  useLazyGetOrderDetailsByIdQuery,
  useCompleteOrderMutation,
  useGetHostedPaymentTokenQuery,
  useLazyGetHostedPaymentTokenQuery,
  useCreatePaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useGetBusinessPaymentMethodsQuery,
  useLazyGetBusinessPaymentMethodsQuery,
  useSubmitPaymentMutation,
  useSearchProductsMutation,
  useGetSearchProductFacetsQuery,
  useLazyGetSearchProductFacetsQuery,
  useGetDistributorsQuery,
  useLazyGetDistributorsQuery,
  useCreateDistributorMutation,
  useGetProductLinkQuery,
  useLazyGetProductLinkQuery,
  useGetProductPriceHistoryQuery,
  useLazyGetProductPriceHistoryQuery,
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetUserAuthRolesQuery,
  useLazyGetUserAuthRolesQuery,
  useUpdateUserMutation,
  useOnboardUserMutation,
} = injectedRtkApi;
