import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";
import { Log } from "@/Log.ts";
import { XIcon } from "lucide-react";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { dismissAlert, selectDismissedAlertIds } from "@/store/Notifications.slice.ts";

const alertVariants = cva(
  "relative w-full rounded-lg border p-4 [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground",
  {
    variants: {
      variant: {
        default: "bg-background text-foreground",
        destructive: "border-destructive/50 text-destructive dark:border-destructive [&>svg]:text-destructive",
        informative: "border-blue-500/50 text-blue-500 dark:border-blue-500 [&>svg]:text-blue-500",
        success: "border-emerald-700/50 text-emerald-700 dark:border-emerald-700 [&>svg]:text-emerald-700",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

const Alert = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants> & { dismissibleId?: string }>(
  ({ className, variant, dismissibleId, ...props }, ref) => {
    const dismissedAlertIds = useAppSelector(selectDismissedAlertIds);

    if (dismissibleId && dismissedAlertIds.includes(dismissibleId)) {
      return null;
    }

    return <div ref={ref} role="alert" className={cn(alertVariants({ variant }), className)} {...props} />;
  }
);
Alert.displayName = "Alert";

const AlertTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement> & { dismissibleId?: string }>(
  ({ className, dismissibleId, ...props }, ref) => {
    const dispatch = useAppDispatch();
    if (dismissibleId) {
      return (
        <div className="flex w-full justify-between">
          <h5 ref={ref} className={cn("mb-1 font-semibold leading-none tracking-tight", className)} {...props} />
          <button
            aria-label="Dismiss"
            onClick={() => {
              Log.debug("dismissing alert");
              dispatch(dismissAlert(dismissibleId));
            }}
            id={dismissibleId}
          >
            <XIcon className="h-5 w-5" />
          </button>
        </div>
      );
    }
    return <h5 ref={ref} className={cn("mb-1 font-semibold leading-none tracking-tight", className)} {...props} />;
  }
);
AlertTitle.displayName = "AlertTitle";

const AlertDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("text-sm [&_p]:leading-relaxed", className)} {...props} />
));
AlertDescription.displayName = "AlertDescription";

export { Alert, AlertTitle, AlertDescription };
