import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { loadRawFileDataAsync } from "@/store/order-guide-upload/thunks";
import { selectColumnNames, selectFile, selectRecords, setCanMoveToNextStep } from "@/store/order-guide-upload/wizard/OrderGuideUploadWizard.slice.ts";
import { SerializableJsFile, humanFileSize } from "@/utils.ts";
import { CheckIcon } from "@heroicons/react/20/solid";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";

export function UploadFileStep() {
  const dispatch = useAppDispatch();
  const uploadedFile: SerializableJsFile | undefined = useAppSelector(selectFile);
  const numColumns = useAppSelector(selectColumnNames)?.length ?? 0;
  const numRows = useAppSelector(selectRecords)?.length ?? 0;

  useEffect(() => {
    // Only go to the next step, if we aren't coming back to this step
    if (uploadedFile) {
      dispatch(setCanMoveToNextStep(true));
    }
  }, [uploadedFile]);

  return (
    <div className="p-4">
      <div className="grid gap-4">
        <div className="grid gap-0.5">
          <div className="flex items-center gap-2">
            <ArrowUpTrayIcon className="h-5 w-5" />
            <h3 className="font-semibold">Load File</h3>
          </div>
          <p className="text-sm leading-loose text-gray-500 dark:text-gray-400">Upload your order guide, or drag and drop it into the box below.</p>
        </div>
        <div>
          <div className="mt-4 flex w-full items-center justify-center">
            <label
              htmlFor="dropzone-file"
              className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100"
            >
              {!uploadedFile ? (
                <div className="flex flex-col items-center justify-center pb-6 pt-5">
                  <svg className="mb-4 h-8 w-8 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500">
                    <span className="font-semibold">Click to load</span> or drag and drop
                  </p>
                  <p className="text-xs text-gray-500">XLS, XLSX, CSV, TSV, or TXT (MAX. 10MB)</p>
                </div>
              ) : (
                <div className="flex h-64 w-full cursor-pointer flex-col items-center justify-center gap-4 rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100">
                  <div className="flex flex-col items-center gap-2">
                    <CheckIcon className="h-16 w-16 text-emerald-600" />
                    <h4 className="font-semibold text-emerald-600">Loaded</h4>
                  </div>
                  <div>
                    <p className="font-semibold text-gray-500">{uploadedFile?.name}</p>
                    <p className="text-center text-gray-500">
                      {humanFileSize(uploadedFile?.size || 0)} | {numColumns} columns | {numRows} rows
                    </p>
                  </div>
                </div>
              )}

              {/* Accept CSV, TSV, PSV, XLSX files */}
              <input
                id="dropzone-file"
                type="file"
                className="hidden"
                onInput={(event) => {
                  dispatch(loadRawFileDataAsync(event.currentTarget.files?.[0]));
                }}
                accept="text/csv, text/tab-separated-values, text/plain, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
