import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DevState {
  enabled: boolean;

  debugObjectData?: any;
}

export const initialState: DevState = {
  enabled: false,
};

export const devSlice = createSlice({
  name: "dev",
  initialState,
  reducers: {
    setDev: (state, action: PayloadAction<boolean>) => {
      state.enabled = action.payload;
    },

    setDebugObjectData: (state, action: PayloadAction<any>) => {
      state.debugObjectData = action.payload;
    },

    appendDebugObjectData: (state, action: PayloadAction<any>) => {
      state.debugObjectData = { ...state.debugObjectData, ...action.payload };
    },
  },
});

export const { setDev: setDevStateEnabled, setDebugObjectData, appendDebugObjectData } = devSlice.actions;

export const selectDevState = (state: { dev: DevState }) => state.dev;

export const selectDevStateEnabled = (state: { dev: DevState }) => state.dev.enabled;

export const selectDevStateDebugObjectData = (state: { dev: DevState }) => state.dev.debugObjectData;

export default devSlice.reducer;
