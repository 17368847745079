import { useIsInRole } from "@/hooks";
import { ApplicationRole } from "@/store/IRN.API.ts";

export function RoleGuard({ children, roles }: { children: React.ReactNode; roles: ApplicationRole[] }) {
  const isInRole = useIsInRole(...roles);

  if (!isInRole) {
    return null;
  }

  return <>{children}</>;
}
