import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch } from "@/store/Hooks.ts";
import { setCurrentOrderGuide } from "@/store/OrderGuide.slice.ts";
import { clearOrderGuideItems, orderGuideItemsLoaded } from "@/store/OrderGuideItem.slice.ts";
import { OrderGuideDto, useGetOrderGuideQuery } from "@/store/IRN.API.ts";
import { OrderGuideTableV2 } from "@/components/order-guide/OrderGuideTableV2.tsx";

export const OrderGuidePageV2 = () => {
  const { orderGuideId } = useParams();
  const orderGuideQuery = useGetOrderGuideQuery(parseInt(orderGuideId ?? "0", 10));
  // const orderGuides = useGetOrderGuideSummariesQuery();

  const [orderGuide, setOrderGuide] = useState<OrderGuideDto | null>(null);
  const [tableDisplayState, setTableDisplayState] = useState<"loading" | "error" | "visible">("loading");
  const appDispatch = useAppDispatch();

  useEffect(() => {
    if (orderGuideQuery.isLoading) {
      setTableDisplayState("loading");
    } else if (orderGuideQuery.error) {
      setTableDisplayState("error");
    } else if (orderGuideQuery.data) {
      setTableDisplayState("visible");
    } else {
      setTableDisplayState("error");
    }
  }, [orderGuideQuery.data, orderGuideQuery.error, orderGuideQuery.isLoading]);

  useEffect(() => {
    if (orderGuideQuery.isLoading) {
      appDispatch(setCurrentOrderGuide(undefined));
      appDispatch(clearOrderGuideItems());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orderGuideQuery.isLoading) {
      appDispatch(clearOrderGuideItems());
    }
  }, [appDispatch, orderGuideQuery.isLoading]);

  // We only want to set the order guide in the store if we have a valid order guide,
  // and if it has changed. This is to prevent unnecessary re-renders.

  useEffect(() => {
    if (orderGuideQuery.data) {
      const guide: OrderGuideDto = { ...orderGuideQuery.data };
      setOrderGuide(guide);
      appDispatch(setCurrentOrderGuide(guide));
      appDispatch(orderGuideItemsLoaded(guide.items));
    }
  }, [appDispatch, orderGuideQuery.data, orderGuideId]);

  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="w-full rounded bg-white shadow">
          {tableDisplayState === "loading" && (
            <div className="flex h-full w-full place-content-center place-items-center text-brand-600">
              <div
                className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
              </div>
            </div>
          )}

          {tableDisplayState === "error" && (
            <p>
              Error:{" "}
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                orderGuideQuery.error?.message ?? "Unknown error"
              }
            </p>
          )}
          {tableDisplayState === "visible" && orderGuide && <OrderGuideTableV2 orderGuide={orderGuide} />}
        </div>

        {/*<div className="w-full text-center text-sm">*/}
        {/*  <CreateOrderGuideDialog refetchSummaries={orderGuides.refetch}>*/}
        {/*    <Button variant="link">+ Create another order guide</Button>*/}
        {/*  </CreateOrderGuideDialog>*/}
        {/*</div>*/}
        {/*<Card className="bg-white">*/}
        {/*  <CardContent>*/}
        {/*    <UploadOrderGuide />*/}
        {/*  </CardContent>*/}
        {/*</Card>*/}
        {/*<p className="container text-center text-sm text-gray-500">*/}
        {/*  Note that prices are shown as estimates. Because we work with multiple distributors, your final price will be calculated at the time you place your*/}
        {/*  order so we can calculate the most optimal order to save you the most money. The prices listed above are estimates given all the pricing information*/}
        {/*  available as of right now. These prices are subject to change at any time.*/}
        {/*</p>*/}
      </div>
    </>
  );
};
