// MSAL configuration
import { Configuration, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { Log } from "@/Log.ts";

export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_SignUpSignIn_1",
    forgotPassword: "B2C_1_PasswordReset",
    editProfile: "B2C_1_EditProfile",
  },
  authorities: {
    signUpSignIn: {
      authority: "https://irnworks.b2clogin.com/irnworks.onmicrosoft.com/B2C_1_SignUpSignIn",
    },
    forgotPassword: {
      authority: "https://irnworks.b2clogin.com/irnworks.onmicrosoft.com/B2C_1_PasswordReset",
    },
    editProfile: {
      authority: "https://irnworks.b2clogin.com/irnworks.onmicrosoft.com/B2C_1_EditProfile",
    },
  },
  authorityDomain: "irnworks.b2clogin.com",
};

export const msalConfiguration: Configuration = {
  auth: {
    clientId: "dd8df816-c8f3-41be-b58c-06dc8c4ea31b",
    // authority: "https://login.microsoftonline.com/cc7edbfa-9b9b-4a2c-bb2c-8568633f9f06",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: "/app/dashboard",
    OIDCOptions: {
      defaultScopes: ["openid", "profile", "https://irnworks.onmicrosoft.com/api/user_access"],
    },
    navigateToLoginRequestUrl: true,
    // azureCloudOptions: {
    //   azureCloudInstance: AzureCloudInstance.AzurePublic,
    //   tenant: "cc7edbfa-9b9b-4a2c-bb2c-8568633f9f06",
    // },
    skipAuthorityMetadataCache: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message) => {
        switch (level) {
          case LogLevel.Error:
            Log.error(message);
            break;
          case LogLevel.Warning:
            Log.warn(message);
            break;
          case LogLevel.Verbose:
            Log.debug(message);
            break;
          case LogLevel.Trace:
            Log.debug(message);
            break;
          case LogLevel.Info:
            Log.info(message);
            return;
        }
      },
      logLevel: LogLevel.Warning,
      piiLoggingEnabled: true,
    },
  },
};

export const msalPca = new PublicClientApplication(msalConfiguration);

// Default to using the first account if no account is active on page load
if (!msalPca.getActiveAccount() && msalPca.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalPca.setActiveAccount(msalPca.getAllAccounts()[0]);
}
