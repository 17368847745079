import { useConfirm } from "@/components/dialogs/confirmation-dialog/ConfirmationDialog.tsx";
import { CreditCardFormData, CreditCardInput } from "@/components/payment/CreditCardInput.tsx";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { OrderDetailsDto, useCreatePaymentMethodMutation, useDeletePaymentMethodMutation, useGetBusinessPaymentMethodsQuery } from "@/store/IRN.API.ts";
import { classNames } from "@/utils.ts";
import { RadioGroup } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/solid";
import { CheckCircleIcon, CircleIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useAcceptJs } from "react-acceptjs";
import { LoadingSpinner } from "../LoadingSpinner";

export const productionAuthorizeNetAuthData = {
  apiLoginID: "72hz9aUzM8",
  clientKey: "6myNYyVsLanWJb3X4JvS7wF3Bz7gyWU4ZS9Dhg9PMmaW9e52c5777ExcLzsPkKX3",
};

export const authorizeNetAuthData = {
  apiLoginID: "5Hng4W9PHCh3",
  clientKey: "838c5VFrd5kJv3NACAHtYUKks856g47c9Chg3PRjxuHs22kbVr4xv6VSXUm7k55S",
};

export default function PaymentMethodForm({
  order,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
}: {
  order: OrderDetailsDto;
  setSelectedPaymentMethod: (value: number | null) => void;
  selectedPaymentMethod: number | null;
}) {
  // const hostedFormToken = useGetHostedPaymentTokenQuery(9);
  const { dispatchData, loading, error } = useAcceptJs({
    authData: authorizeNetAuthData,
    environment: "SANDBOX",
  });

  // const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<number | null>(null);
  const [newPaymentMethodType, setNewPaymentMethodType] = useState<"creditCard" | "bankAccount">("creditCard");
  const [showNewPaymentMethodForm, setShowNewPaymentMethodForm] = useState(false);
  const [createPaymentMethodTrigger, createPaymentMethodResult] = useCreatePaymentMethodMutation();
  const [deletePaymentMethodTrigger, deletePaymentMethodResult] = useDeletePaymentMethodMutation();
  const paymentMethodsQuery = useGetBusinessPaymentMethodsQuery(order.business!.id);
  const confirmationDialog = useConfirm();

  useEffect(() => {
    if (paymentMethodsQuery.isSuccess && paymentMethodsQuery.data) {
      // If there's only 1 payment method, select it by default
      if (paymentMethodsQuery.data.length === 1) {
        setSelectedPaymentMethod(paymentMethodsQuery.data[0].id);
      }
    }
  }, [paymentMethodsQuery.isSuccess, paymentMethodsQuery.data]);

  const saveCreditCard = async (data: CreditCardFormData) => {
    const response = await dispatchData({
      cardData: {
        cardNumber: data.cardNumber.replace(/\s/g, ""),
        cardCode: data.cvc,
        fullName: data.name,
        month: data.expiry.split("/")[0],
        year: data.expiry.split("/")[1],
      },
    });

    if (response.messages.resultCode !== "Ok") {
      console.error(response);
      return;
    }

    createPaymentMethodTrigger({
      businessId: order.business!.id,
      body: {
        paymentMethodToken: response.opaqueData.dataValue,
        paymentMethodType: response.opaqueData.dataDescriptor,
      },
    }).then((result) => {
      setShowNewPaymentMethodForm(false);
      paymentMethodsQuery.refetch();

      if ("data" in result) setSelectedPaymentMethod(result.data!.paymentMethodId);
    });

    console.log(response);
  };

  const deletePaymentMethod = async (paymentMethodId: number) => {
    const result = await confirmationDialog({
      title: "Delete Payment Method",
      body: "Are you sure you want to delete this payment method?",
      actionButton: "Delete",
    });

    if (result) {
      deletePaymentMethodTrigger({ paymentMethodId, businessId: order.business!.id });
    }
  };

  const getCardExpirationDate = (expirationDate: string | undefined) => {
    if (!expirationDate) {
      return "N/A";
    }

    const date = new Date(expirationDate);
    return `${date.getMonth() + 1}/${date.getFullYear().toString().slice(2)}`;
  };

  if (paymentMethodsQuery.isLoading) {
    return <LoadingSpinner size="4xl" />;
  }

  return (
    <div className="flex flex-col bg-gray-50 p-4">
      <Card className="w-full max-w-2xl bg-white">
        <CardHeader>
          <CardTitle>Saved Payment Methods</CardTitle>
        </CardHeader>
        <CardContent>
          {paymentMethodsQuery.currentData && paymentMethodsQuery.currentData.length !== 0 && (
            <RadioGroup
              value={selectedPaymentMethod}
              onChange={(value) => {
                setSelectedPaymentMethod(value);
                console.log("selected payment method", value);
              }}
            >
              <div className="space-y-4">
                {paymentMethodsQuery.currentData &&
                  paymentMethodsQuery.currentData.map((method) => (
                    <RadioGroup.Option
                      key={method.id}
                      value={method.id}
                      className={({ active }) =>
                        classNames(
                          active ? "border-brand-500 ring-2 ring-brand-500" : "border-gray-300",
                          "relative flex rounded-lg border bg-white p-4 shadow-sm focus:outline-none place-items-center",
                          // !uploadType.enabled ? "cursor-not-allowed opacity-50" : "cursor-pointer",
                        )
                      }
                    >
                      {({ checked, active }) => (
                        <>
                          <div className="w-7">
                            {checked && <CheckCircleIcon className="h-5 w-5 text-brand-500" aria-hidden="true" />}
                            {!checked && <CircleIcon className="h-5 w-5 text-brand-500" aria-hidden="true" />}
                          </div>
                          <span className="flex flex-1">
                            <span className="flex flex-col">
                              <RadioGroup.Label as="div" className="flex font-medium text-gray-900 items-center">
                                {method.isCreditCard
                                  ? `Visa ending in ${method.creditCard?.maskedCardNumber}`
                                  : `Bank Account ending in ${method.bankAccount?.maskedAccountNumber}`}
                              </RadioGroup.Label>
                              <RadioGroup.Description as="div" className="flex items-center text-sm text-gray-500">
                                {method.isCreditCard ? `Expires ${getCardExpirationDate(method.creditCard?.expirationDate)}` : ``}
                              </RadioGroup.Description>
                            </span>
                          </span>
                          <div className="flex items-center space-x-2">
                            <Button size="sm" variant="outline" disabled={deletePaymentMethodResult.isLoading} onClick={() => deletePaymentMethod(method.id)}>
                              Delete
                            </Button>
                          </div>
                          <span
                            className={classNames(
                              active ? "border" : "border-2",
                              checked ? "border-brand-500" : "border-transparent",
                              "pointer-events-none absolute -inset-px rounded-lg",
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}

                {/*<Label className="flex items-start justify-between border border-gray-200 rounded-lg p-4 cursor-pointer" htmlFor="visa">*/}
                {/*  <RadioGroupItem className="peer sr-only" id="visa" value="visa" />*/}
                {/*  <div className="flex flex-col space-y-2">*/}
                {/*    <p className="font-medium">Visa ending in 1234</p>*/}
                {/*    <p className="text-sm text-gray-500">Expires 01/24</p>*/}
                {/*  </div>*/}
                {/*  <div className="flex items-center space-x-2">*/}
                {/*    <Button size="sm" variant="outline">*/}
                {/*      Edit*/}
                {/*    </Button>*/}
                {/*    <Button size="sm" variant="outline">*/}
                {/*      Delete*/}
                {/*    </Button>*/}
                {/*  </div>*/}
                {/*</Label>*/}
                {/*<Label className="flex items-start justify-between border border-gray-200 rounded-lg p-4 cursor-pointer" htmlFor="bank">*/}
                {/*  <RadioGroupItem className="peer sr-only" id="bank" value="bank" />*/}
                {/*  <div className="flex flex-col space-y-2">*/}
                {/*    <p className="font-medium">Bank Account ending in 5678</p>*/}
                {/*    <p className="text-sm text-gray-500">Routing Number: 123456789</p>*/}
                {/*  </div>*/}
                {/*  <div className="flex items-center space-x-2">*/}
                {/*    <Button size="sm" variant="outline">*/}
                {/*      Edit*/}
                {/*    </Button>*/}
                {/*    <Button size="sm" variant="outline">*/}
                {/*      Delete*/}
                {/*    </Button>*/}
                {/*  </div>*/}
                {/*</Label>*/}
              </div>
            </RadioGroup>
          )}

          {paymentMethodsQuery.currentData && paymentMethodsQuery.currentData.length === 0 && (
            <div className="text-center">
              <p className="text-sm">You have no saved payment methods.</p>
              <p className="text-sm text-gray-500">Add a payment method to complete your order.</p>
            </div>
          )}

          {!showNewPaymentMethodForm && (
            <Button className="w-full mt-4" variant="outline" onClick={() => setShowNewPaymentMethodForm(true)}>
              <PlusIcon className="h-4 w-4 mr-2" />
              Add New Payment Method
            </Button>
          )}
        </CardContent>
      </Card>
      {showNewPaymentMethodForm && (
        <Card className="w-full max-w-2xl mt-8 bg-white">
          <CardHeader>
            <CardTitle>Payment Method</CardTitle>
            <CardDescription>Select your preferred payment method.</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-4" id="payment_form">
              <input type="hidden" name="dataValue" id="dataValue" />
              <input type="hidden" name="dataDescriptor" id="dataDescriptor" />

              <div className="space-y-2">
                <Label htmlFor="payment-method">Payment Method</Label>
                <Select onValueChange={(value) => setNewPaymentMethodType(value as "creditCard" | "bankAccount")} defaultValue="creditCard">
                  <SelectTrigger>
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="creditCard">Credit Card</SelectItem>
                    <SelectItem value="bankAccount">Bank Account</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              {newPaymentMethodType === "creditCard" && (
                <CreditCardInput onCardSubmit={(data) => saveCreditCard(data)} submitDisabled={createPaymentMethodResult.isLoading || loading || error} />
              )}
              {newPaymentMethodType === "bankAccount" && <div>Bank Account Form</div>}
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
}
