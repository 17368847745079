import * as React from "react";
import { Check, ChevronsUpDown, Plus } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

export function SearchCombobox({
  hint,
  options,
  createNew,
  createNewRequested,

  value,
  setValue,
}: {
  hint: string;
  createNew?: boolean;
  createNewRequested?: () => void;
  value: string;
  setValue: (value: string) => void;
  options: {
    value: string;
    label: string;
  }[];
}) {
  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" aria-expanded={open} className="justify-between">
          {value ? options.find((o) => o.value === value)?.label : `Select ${hint.toLowerCase()}...`}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder={`Search ${hint.toLowerCase()}s...`} />
          <CommandList>
            <CommandEmpty>No {hint.toLowerCase()}s found.</CommandEmpty>
            <CommandGroup>
              {options.map((o) => (
                <CommandItem
                  key={o.value}
                  value={o.value}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? "" : currentValue);
                    setOpen(false);
                  }}
                >
                  <Check className={cn("mr-2 h-4 w-4", value === o.value ? "opacity-100" : "opacity-0")} />
                  {o.label}
                </CommandItem>
              ))}
            </CommandGroup>
            {createNew && (
              <CommandItem
                value="create-new"
                className=""
                onSelect={() => {
                  setValue("");
                  setOpen(false);
                  createNewRequested?.();
                }}
              >
                <Plus className="mr-2 h-4 w-4" />
                Create new {hint.toLowerCase()}...
              </CommandItem>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
