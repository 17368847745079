export function RebatesPage() {
  return (
    <div className="flex flex-col gap-5 h-full">
      <h1 className="flex-grow-0">Rebates</h1>
      <p className="flex-grow-0">
        IRN works closely with{" "}
        <a href="https://mydiningalliance.com/dashboard/" target="_blank" rel="noreferrer" className="text-brand hover:underline">
          Buyers Edge Platform
        </a>{" "}
        to provide our customers with the best possible rebates. For your convenience, we have embedded their platform below. If you have any questions, please
        reach out to your account manager.
      </p>

      <iframe
        src="https://mydiningalliance.com/dashboard/"
        title="Buyers Edge Platform"
        className="w-full h-full min-h-[60dvh] border-brand border p-1 shadow"
      />
    </div>
  );
}
