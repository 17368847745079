import { classNames, toCurrency } from "@/utils.ts";
import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

export function OrderDetailsItemizedList({
  orderItemsByDistributor,
}: {
  orderItemsByDistributor: {
    [key: string]: {
      id: number;
      quantity: number;
      product: {
        name: string;
        price: number;
      };
    }[];
  };
}) {
  return (
    <>
      {orderItemsByDistributor &&
        Object.keys(orderItemsByDistributor).map((distributorName: string, index: number) => (
          <div key={distributorName}>
            <Disclosure as="div" className="border-b border-gray-200" defaultOpen={index === 0}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="group relative mt-4 flex w-full items-center justify-between bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2">
                    <div>
                      <span className="text-xs text-gray-500">Distributor</span>
                      <h3 className="text-lg font-medium leading-6 text-gray-900">{distributorName}</h3>
                    </div>
                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                      <p>
                        {orderItemsByDistributor[distributorName].length} products,{" "}
                        {orderItemsByDistributor[distributorName].reduce((acc, item) => acc + item.quantity, 0)} total items
                      </p>
                      <p>
                        Total:{" "}
                        {toCurrency(
                          orderItemsByDistributor[distributorName].reduce((acc, item) => acc + item.quantity * item.product.price, 0),
                          false,
                        )}
                      </p>
                    </div>
                    <span>{!open ? "Show" : "Hide"} order details</span>
                    <ChevronRightIcon
                      className={classNames(open ? "rotate-90 transform" : "rotate-0 transform", "ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-900")}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="bg-white px-4 sm:px-6  lg:px-8">
                    <div className="-mx-4 flow-root h-96 overflow-y-auto pr-5 sm:mx-0">
                      <table className="min-w-full ">
                        <colgroup>
                          <col className="w-full sm:w-1/2" />
                          <col className="sm:w-1/6" />
                          <col className="sm:w-1/6" />
                          <col className="sm:w-1/6" />
                        </colgroup>
                        <thead className="border-b border-gray-300 text-gray-900">
                          <tr>
                            <th scope="col" className="pl-4 pr-3 pt-1 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                              Product
                            </th>
                            <th scope="col" className="hidden px-3 pt-1 text-right text-sm font-semibold text-gray-900 sm:table-cell">
                              Quantity
                            </th>
                            <th scope="col" className="hidden px-3 pt-1 text-right text-sm font-semibold text-gray-900 sm:table-cell">
                              Unit Price
                            </th>
                            <th scope="col" className="pl-3 pr-4 pt-1 text-right text-sm font-semibold text-gray-900 sm:pr-0">
                              Price
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {[...(orderItemsByDistributor[distributorName] ?? [])]
                            ?.sort((a, b) => a.product.name.localeCompare(b.product.name))
                            ?.map((item) => (
                              <tr key={item.id} className="border-b border-gray-200">
                                <td className="max-w-0 py-1 pl-4 pr-3 text-sm sm:pl-0">
                                  <div className="font-medium text-gray-900">{item.product.name || "-"}</div>
                                  {/*<div className="mt-1 truncate text-xs text-gray-500">{item.product.distributor?.name}</div>*/}
                                </td>
                                <td className="hidden px-3 py-1 text-right text-sm text-gray-500 sm:table-cell">{item.quantity}</td>
                                <td className="hidden px-3 py-1 text-right text-sm text-gray-500 sm:table-cell">
                                  {item.product.price.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })}
                                </td>
                                <td className="py-1 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">
                                  {(item.product.price * item.quantity).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        ))}
    </>
  );
}
