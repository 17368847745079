import { Log } from "@/Log.ts";
import { irnApi, OrderGuideUploadDto, OrderGuideUploadEntryDto, ProductLinkDto, UploadProcessingStatus } from "@/store/IRN.API.ts";
import { OrderGuideUploadWizardState } from "@/store/order-guide-upload/wizard/OrderGuideUploadWizard.slice.ts";
import { isMutationDataResult, isMutationErrorResult } from "@/utils.ts";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface ParsedEntryData {
  Name?: string;
  Description?: string;
  Brand?: string;
  Price: number;
  PricePerPiece?: number;
  UnitsPerCase?: number;
  MPN?: string;
  UPC?: string;
  SKU?: string;
  Category?: string;
  UnitSize?: string;
  Unit?: string;
  Size?: string;
  CategoryId?: string;
  Thumbnail?: string;
  Images?: string[];
}

export interface OrderGuideUploadWizardProductMatchingState {
  uploadId: number;
  upload?: OrderGuideUploadDto;

  currentEntry?: OrderGuideUploadEntryDto;
  currentEntryIndex?: number;

  currentEntryParsedData?: ParsedEntryData;
  currentEntryRowData?: Record<string, any>;
  currentEntryProductLink?: ProductLinkDto;

  loadingEntry?: boolean;

  skipConfidentMatches?: boolean;

  isConfirmingExactMatches?: boolean;
  numExactMatchesConfirmed?: number;
  totalExactMatches?: number;
}

export const createCustomEntryProduct = createAsyncThunk<void, { entryId: number; uploadId: number; confirm?: boolean }>(
  "orderGuideUploadWizardProductMatching/createCustomEntryProduct",
  async (args, { dispatch, getState }) => {
    const state = getState() as {
      orderGuideUploadWizardProductMatching: OrderGuideUploadWizardProductMatchingState;
      orderGuideUploadWizard: OrderGuideUploadWizardState;
    };

    const uploadId = state.orderGuideUploadWizardProductMatching.uploadId;
    const entryId = args.entryId;

    const resp = await dispatch(
      irnApi.endpoints.createProductFromOrderGuideEntry.initiate({
        uploadId,
        body: {
          entryId,
        },
      }),
    );
    if (isMutationDataResult<ProductLinkDto>(resp)) {
      const productLink = resp.data;
      Log.info("createCustomEntryProduct", "productLink", productLink);

      dispatch(setCurrentEntryProduct(productLink));

      if (args.confirm) {
        const confirmResponse = await dispatch(
          irnApi.endpoints.confirmOrderGuideUploadEntry.initiate({
            uploadId,
            body: {
              entryId,
            },
          }),
        );

        if (!isMutationErrorResult(confirmResponse)) {
          dispatch(setCurrentEntryConfirmed());
          dispatch(gotoNextEntry(1));
        }
      }
    } else {
      Log.error("createCustomEntryProduct", "error", resp.error);
    }
  },
);

export const gotoNextEntry = createAsyncThunk<void, number>(
  "orderGuideUploadWizardProductMatching/gotoNextEntry",
  async (stepDirection, { dispatch, getState }) => {
    const state = getState() as {
      orderGuideUploadWizardProductMatching: OrderGuideUploadWizardProductMatchingState;
    };

    if (stepDirection < -1) {
      stepDirection = -1;
    }

    const nextEntryIndex =
      state.orderGuideUploadWizardProductMatching.currentEntryIndex !== undefined
        ? state.orderGuideUploadWizardProductMatching.currentEntryIndex + stepDirection
        : 0;

    await dispatch(goToEntry({ entryIndex: nextEntryIndex, forceRefetch: stepDirection == -100 }));
  },
);

export const goToEntry = createAsyncThunk<
  void,
  {
    entryIndex: number;
    forceRefetch?: boolean;
  }
>("orderGuideUploadWizardProductMatching/goToEntry", async ({ entryIndex, forceRefetch }, { dispatch, getState }) => {
  const state = getState() as {
    orderGuideUploadWizardProductMatching: OrderGuideUploadWizardProductMatchingState;
  };

  let upload: OrderGuideUploadDto | undefined = state.orderGuideUploadWizardProductMatching.upload;
  if (!upload || upload.id !== state.orderGuideUploadWizardProductMatching.uploadId || forceRefetch) {
    const resp = await dispatch(
      irnApi.endpoints.getOrderGuideUpload.initiate(state.orderGuideUploadWizardProductMatching.uploadId ?? 0, {
        forceRefetch: true,
      }),
    );

    if (resp.isSuccess) {
      // try {
      //   if (resp.data?.entries) {
      //     resp.data.entries = resp.data.entries.sort((a, b) => a.id - b.id);
      //   }
      // } catch (e) {
      //   console.log("gotoNextEntry", "error", e);
      // }

      dispatch(setUpload(resp.data!));
      upload = resp.data;
      // Log.info("gotoNextEntry", "getOrderGuideUpload", resp.data);
    } else {
      Log.error("gotoNextEntry", "getOrderGuideUpload", resp.error);
      return;
    }
  }

  if (entryIndex >= upload.entries!.length) {
    // Log.debug("nextEntryIndex", "DONE", "entries.length", upload.entries!.length);
    return;
  }

  dispatch(setCurrentEntryIndex(entryIndex));

  const nextEntry = upload.entries![entryIndex];
  // Log.debug("nextEntry", nextEntry);
  dispatch(setCurrentEntry(nextEntry));

  if (nextEntry.productLinkId && nextEntry.productLinkId !== 1) {
    // Log.debug("nextEntry.productLinkId", nextEntry.productLinkId);
    const resp = await dispatch(
      irnApi.endpoints.getProductLink.initiate({
        id: nextEntry.productLinkId,
        includePendingUploadProducts: true,
      }),
    );
    if (resp.isSuccess) {
      // This happens when the user quickly clicks the next/previous buttons
      if (resp.data.id !== nextEntry.productLinkId) {
        Log.error("nextEntry.productLinkId.resp", "id mismatch", resp.data.id, nextEntry.productLinkId);
        return;
      }

      // Log.debug("nextEntry.productLinkId.resp", resp.data);
      dispatch(setCurrentEntryProductLink(resp.data!));
    } else {
      Log.error("nextEntry.productLinkId", "error", resp.error);
      dispatch(setCurrentEntryProductLink(undefined));
    }
  } else {
    Log.error("nextEntry.productLinkId", "no productLinkId", nextEntry.productLinkId, nextEntry);
    // Log.debug("nextEntry.productLinkId", "no productLinkId");
    dispatch(setCurrentEntryProductLink(undefined));
  }
});

export const goToNextUnconfirmedEntry = createAsyncThunk<void, void>(
  "orderGuideUploadWizardProductMatching/goToNextUnconfirmedEntry",
  async (_, { dispatch, getState }) => {
    const state = getState() as {
      orderGuideUploadWizardProductMatching: OrderGuideUploadWizardProductMatchingState;
    };

    const upload = state.orderGuideUploadWizardProductMatching.upload;
    if (!upload) {
      return;
    }

    const nextEntryIndex = upload.entries.findIndex((entry) => entry.status !== UploadProcessingStatus.Confirmed);

    if (nextEntryIndex !== -1) {
      dispatch(setCurrentEntryIndex(nextEntryIndex));
      dispatch(setCurrentEntry(upload.entries[nextEntryIndex]));
    }
  },
);

export const confirmAllExactMatches = createAsyncThunk<void, void>(
  "orderGuideUploadWizardProductMatching/confirmAllExactMatches",
  async (_, { dispatch, getState }) => {
    dispatch(setNumExactMatchesConfirmed(0));
    dispatch(setTotalExactMatches(0));
    const state = getState() as {
      orderGuideUploadWizardProductMatching: OrderGuideUploadWizardProductMatchingState;
    };

    const uploadId = state.orderGuideUploadWizardProductMatching.uploadId;
    const entries = state.orderGuideUploadWizardProductMatching.upload?.entries;

    if (!entries || !entries.length) return;

    const entriesToConfirm = [];

    for (const entry of entries) {
      if (entry.status === UploadProcessingStatus.Confirmed) {
        continue;
      }

      if (entry.potentialProductScores && entry.potentialProductScores.length > 0) {
        if (entry.potentialProductScores[0] === 100) {
          entriesToConfirm.push(entry);
        }
      }
    }

    // TODO: Set state to show the progress
    dispatch(setNumExactMatchesConfirmed(0));
    dispatch(setTotalExactMatches(entriesToConfirm.length));

    let numConfirmed = 0;

    for (const entry of entriesToConfirm) {
      const resp = await dispatch(
        irnApi.endpoints.confirmOrderGuideUploadEntry.initiate({
          uploadId,
          body: {
            entryId: entry.id,
          },
        }),
      );
      if (!isMutationErrorResult(resp)) {
        dispatch(setEntryConfirmed(entry));
      }

      numConfirmed++;

      dispatch(setNumExactMatchesConfirmed(numConfirmed));
    }
  },
);

const initialState: OrderGuideUploadWizardProductMatchingState = {
  uploadId: 0,
};

export const orderGuideUploadProductMatchingSlice = createSlice({
  name: "orderGuideUploadWizardProductMatching",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(gotoNextEntry.pending, (state) => {
      state.loadingEntry = true;
    });
    builder.addCase(gotoNextEntry.fulfilled, (state) => {
      state.loadingEntry = false;
    });
    builder.addCase(gotoNextEntry.rejected, (state) => {
      state.loadingEntry = false;
    });

    builder.addCase(confirmAllExactMatches.pending, (state) => {
      state.isConfirmingExactMatches = true;
    });
    builder.addCase(confirmAllExactMatches.fulfilled, (state) => {
      state.isConfirmingExactMatches = false;
    });
    builder.addCase(confirmAllExactMatches.rejected, (state) => {
      state.isConfirmingExactMatches = false;
    });
  },
  reducers: {
    setUploadId: (state, action) => {
      state.uploadId = action.payload;
    },
    setUpload: (state, action) => {
      state.upload = action.payload;
    },
    setCurrentEntry: (state, action) => {
      state.currentEntry = action.payload;
      state.currentEntryParsedData = JSON.parse(action.payload.parsedData ?? "{}") as ParsedEntryData;
      state.currentEntryRowData = JSON.parse(action.payload.rowData ?? "{}");
      state.currentEntryProductLink = action.payload.productLink;
    },
    setCurrentEntryIndex: (state, action) => {
      state.currentEntryIndex = action.payload;
    },
    setCurrentEntryParsedData: (state, action) => {
      state.currentEntryParsedData = action.payload;
    },
    setCurrentEntryRowData: (state, action) => {
      state.currentEntryRowData = action.payload;
    },
    setCurrentEntryProductLink: (state, action) => {
      state.currentEntryProductLink = action.payload;
    },
    setSkipConfidentMatches: (state, action) => {
      state.skipConfidentMatches = action.payload;
    },

    setCurrentEntryUnlinked: (state) => {
      state.currentEntryProductLink = undefined;
      state.currentEntry = {
        ...state.currentEntry!,
        productLinkId: undefined,
        productLink: undefined,
      };

      const upload = state.upload!;
      upload.entries![state.currentEntryIndex!] = state.currentEntry!;

      state.upload = { ...upload };
    },

    setCurrentEntryConfirmed: (state) => {
      state.currentEntry = {
        ...state.currentEntry!,
        status: UploadProcessingStatus.Confirmed,
      };

      const upload = state.upload!;
      upload.entries![state.currentEntryIndex!] = state.currentEntry!;

      state.upload = { ...upload };
    },

    setEntryConfirmed: (state, action: { payload: OrderGuideUploadEntryDto }) => {
      const entryConfirmed = action.payload;

      // Find the entry in the upload
      const upload = state.upload!;
      const entryIndex = upload.entries.findIndex((entry) => entry.id === entryConfirmed.id);

      if (entryIndex === -1) {
        return;
      }

      // Update the entry
      upload.entries[entryIndex] = {
        ...entryConfirmed,
        status: UploadProcessingStatus.Confirmed,
      };

      state.upload = { ...upload };

      // Update the current entry if it matches
      if (state.currentEntry?.id === entryConfirmed.id) {
        state.currentEntry = { ...entryConfirmed };
      }

      // state.currentEntryProductLink = entryConfirmed.productLink;
    },

    setCurrentEntryProduct: (state, action: { payload: ProductLinkDto }) => {
      state.currentEntry = {
        ...state.currentEntry!,
        productLinkId: action.payload.id,
        productLink: action.payload,
      };

      const upload = state.upload!;
      upload.entries![state.currentEntryIndex!] = state.currentEntry!;

      state.upload = { ...upload };
    },

    setNumExactMatchesConfirmed: (state, action: { payload: number }) => {
      state.numExactMatchesConfirmed = action.payload;
    },
    setTotalExactMatches: (state, action: { payload: number }) => {
      state.totalExactMatches = action.payload;
    },

    reset: (state) => {
      state.uploadId = 0;
      state.upload = undefined;
      state.currentEntry = undefined;
      state.currentEntryIndex = undefined;
      state.currentEntryParsedData = undefined;
      state.currentEntryRowData = undefined;
      state.currentEntryProductLink = undefined;
      state.loadingEntry = undefined;
      state.skipConfidentMatches = undefined;
    },

    resetWithId: (state, action) => {
      state.uploadId = action.payload;
      state.upload = undefined;
      state.currentEntry = undefined;
      state.currentEntryIndex = undefined;
      state.currentEntryParsedData = undefined;
      state.currentEntryRowData = undefined;
      state.currentEntryProductLink = undefined;
      state.loadingEntry = undefined;
      state.skipConfidentMatches = undefined;
    },
  },
});

export const {
  setUploadId,
  setUpload,
  setCurrentEntry,
  setCurrentEntryIndex,
  setCurrentEntryParsedData,
  setCurrentEntryRowData,
  setCurrentEntryProductLink,
  setSkipConfidentMatches,
  setCurrentEntryUnlinked,
  setCurrentEntryConfirmed,
  setCurrentEntryProduct,
  setEntryConfirmed,
  reset,
  resetWithId,
  setNumExactMatchesConfirmed,
  setTotalExactMatches,
} = orderGuideUploadProductMatchingSlice.actions;

export default orderGuideUploadProductMatchingSlice.reducer;

export const selectUploadId = (state: { orderGuideUploadWizardProductMatching: OrderGuideUploadWizardProductMatchingState }) =>
  state.orderGuideUploadWizardProductMatching.uploadId;
export const selectUpload = (state: { orderGuideUploadWizardProductMatching: OrderGuideUploadWizardProductMatchingState }) =>
  state.orderGuideUploadWizardProductMatching.upload;
export const selectCurrentEntry = (state: { orderGuideUploadWizardProductMatching: OrderGuideUploadWizardProductMatchingState }) =>
  state.orderGuideUploadWizardProductMatching.currentEntry;
export const selectCurrentEntryIndex = (state: { orderGuideUploadWizardProductMatching: OrderGuideUploadWizardProductMatchingState }) =>
  state.orderGuideUploadWizardProductMatching.currentEntryIndex;
export const selectCurrentEntryParsedData = (state: { orderGuideUploadWizardProductMatching: OrderGuideUploadWizardProductMatchingState }) =>
  state.orderGuideUploadWizardProductMatching.currentEntryParsedData;
export const selectCurrentEntryRowData = (state: { orderGuideUploadWizardProductMatching: OrderGuideUploadWizardProductMatchingState }) =>
  state.orderGuideUploadWizardProductMatching.currentEntryRowData;

export const selectCurrentEntryProductLink = (state: { orderGuideUploadWizardProductMatching: OrderGuideUploadWizardProductMatchingState }) =>
  state.orderGuideUploadWizardProductMatching.currentEntryProductLink;

export const selectLoadingEntry = (state: { orderGuideUploadWizardProductMatching: OrderGuideUploadWizardProductMatchingState }) =>
  state.orderGuideUploadWizardProductMatching.loadingEntry;

export const selectSkipConfidentMatches = (state: { orderGuideUploadWizardProductMatching: OrderGuideUploadWizardProductMatchingState }) =>
  state.orderGuideUploadWizardProductMatching.skipConfidentMatches;

export const selectIsConfirmingExactMatches = (state: { orderGuideUploadWizardProductMatching: OrderGuideUploadWizardProductMatchingState }) =>
  state.orderGuideUploadWizardProductMatching.isConfirmingExactMatches;

export const selectNumExactMatchesConfirmed = (state: { orderGuideUploadWizardProductMatching: OrderGuideUploadWizardProductMatchingState }) =>
  state.orderGuideUploadWizardProductMatching.numExactMatchesConfirmed;

export const selectTotalExactMatches = (state: { orderGuideUploadWizardProductMatching: OrderGuideUploadWizardProductMatchingState }) =>
  state.orderGuideUploadWizardProductMatching.totalExactMatches;
