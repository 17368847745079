import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const labelVariants = cva("text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70", {
  variants: {
    size: {
      default: "text-sm font-medium leading-none",
      xs: "text-xs font-medium leading-none",
      sm: "text-sm font-medium leading-none",
      md: "text-base font-semibold leading-none",
      lg: "text-lg font-semibold leading-none",
    },
    variant: {
      default: "text-gray-900",
      brand: "text-brand-600",
      secondary: "text-gray-900",
      tertiary: "text-gray-500",
    },
  },
  defaultVariants: {
    size: "default",
    variant: "default",
  },
});

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>
>(({ className, variant, size, ...props }, ref) => (
  <LabelPrimitive.Root ref={ref} className={cn(labelVariants({ variant, size, className }), className)} {...props} />
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
