import { useNavigate, useParams } from "react-router-dom";
import { useGetOrderGuideQuery } from "@/store/IRN.API.ts";
import { toCurrency } from "@/utils.ts";
import { Button } from "@/components/ui/button.tsx";
import { SubmitOrderGuideOrderDialog } from "@/components/dialogs/submit-order-guide-order/SubmitOrderGuideOrderDialog.tsx";
import { useConfirm } from "@/components/dialogs/confirmation-dialog/ConfirmationDialog.tsx";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { DevDataProvider } from "@/components/dev/DevDataProvider.tsx";

export function CreateOrderPage() {
  const { orderGuideId } = useParams<{ orderGuideId: string }>();
  const navigate = useNavigate();

  const orderGuideQuery = useGetOrderGuideQuery(parseInt(orderGuideId!, 10), {
    skip: !orderGuideId,
  });

  const conifrmationDialog = useConfirm();

  const getPriceDisplayForItem = (item: any, multiplier = 1) => {
    let lowestPrice = item.productLink?.products?.[0]?.price ?? 0;
    let highestPrice = item.productLink?.products?.[0]?.price ?? 0;

    for (const x of item.productLink.products) {
      if (x.price < lowestPrice) lowestPrice = x.price;
      if (x.price > highestPrice) highestPrice = x.price;
    }

    lowestPrice *= multiplier;
    highestPrice *= multiplier;

    if (lowestPrice == highestPrice) {
      return toCurrency(lowestPrice);
    }

    return toCurrency(lowestPrice) + " - " + toCurrency(highestPrice);
  };

  return (
    <>
      <DevDataProvider data={orderGuideQuery.data} />
      <CardHeader>
        <CardTitle>Create Order Details</CardTitle>
        <CardDescription>
          <div>Order Guide - {orderGuideQuery.data?.name}</div>
        </CardDescription>
      </CardHeader>
      <Card>
        <CardHeader>
          <CardTitle>Items</CardTitle>
        </CardHeader>
        <CardContent>
          {/*<h2>Order Guide Items</h2>*/}
          <Table>
            <TableCaption>
              Prices shown above are estimated prices. Once you submit your order, our system will calculate the final prices and you will have the opportunity
              to accept or decline the final order.
            </TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead>Product</TableHead>
                <TableHead>Quantity</TableHead>
                <TableHead>Price</TableHead>
                <TableHead>Total</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {orderGuideQuery.data?.items
                .filter((item) => item.quantity > 0)
                .map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <p>{item.productLink!.name} </p>
                      <p className="text-xs text-gray-500">{item.productLink!.unitSize}</p>
                    </TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>{getPriceDisplayForItem(item)}</TableCell>
                    <TableCell>{getPriceDisplayForItem(item, item.quantity)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      <div className="my-10 flex flex-row-reverse gap-5">
        <SubmitOrderGuideOrderDialog orderGuideId={parseInt(orderGuideId!, 10)}>
          <Button>Submit Order</Button>
        </SubmitOrderGuideOrderDialog>
        <Button
          variant="secondary"
          onClick={async () => {
            const confirmed = await conifrmationDialog({
              title: "Cancel Order",
              body: "Are you sure you want to cancel this order?",
              actionButton: "Cancel Order",
              cancelButton: "Nevermind",
            });

            if (!confirmed) return;

            navigate(`/app/order-guide/${orderGuideId}`);
          }}
        >
          Cancel Order
        </Button>
      </div>
    </>
  );
}
