import { cn } from "@/lib/utils.ts";

const sizes = {
  sm: {
    super: "text-xs align-super",
    main: "text-xl",
    fontWeight: "font-normal",
  },
  md: {
    super: "text-sm align-super",
    main: "text-2xl mr-[0.05em]",
    fontWeight: "font-bold",
  },
  lg: {
    super: "text-lg align-super",
    main: "text-3xl",
    fontWeight: "font-bold",
  },
};

export function PriceDisplay({ price, size = "sm", className }: { price: number; size?: keyof typeof sizes; className?: string }) {
  // Display the price with two decimal places
  // The decimals should be fixed at 2, and displayed separate so we can make them smaller, and superscript

  const currencyDisplay = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(price);

  let [dollars, cents] = currencyDisplay.split(".");
  cents = cents || "00";
  cents = cents.padEnd(2, "0");

  dollars = dollars.replace("$", "");

  return (
    <div className={cn(sizes[size].fontWeight, className)}>
      <span className={sizes[size].super}>$</span>
      <span className={sizes[size].main}>{dollars}</span>
      <span className={sizes[size].super}>{cents}</span>
    </div>
  );
}
