import { Log } from "@/Log.ts";
import { Button } from "@/components/ui/button.tsx";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator } from "@/components/ui/command.tsx";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover.tsx";
import { cn } from "@/lib/utils.ts";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { ProductLinkDto, useAddProductToOrderGuideMutation, useGetOrderGuideSummariesQuery } from "@/store/IRN.API.ts";
import {
  selectAddToGuideLastSelectedBusinessId,
  selectAddToGuideLastSelectedGuideId,
  selectOrderGuidesForLastBusiness,
  setAddToGuideLastSelectedBusinessId,
  setAddToGuideLastSelectedGuideId,
  setOrderGuideSummaries,
} from "@/store/OrderGuide.slice.ts";
import { showNotification } from "@/store/Toast.slice.ts";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { Check, ChevronsUpDown } from "lucide-react";
import React, { Fragment, ReactNode, useEffect, useState } from "react";

export function AddToOrderGuideDialog({ children, selectedItem }: { children: ReactNode; selectedItem: ProductLinkDto | null }) {
  const [open, setOpen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [updateTrigger, updateResult] = useAddProductToOrderGuideMutation();
  const orderGuides = useGetOrderGuideSummariesQuery();
  const businessId = useAppSelector(selectAddToGuideLastSelectedBusinessId);
  const orderGuideId = useAppSelector(selectAddToGuideLastSelectedGuideId);
  const orderGuidesForBusiness = useAppSelector(selectOrderGuidesForLastBusiness);
  const [errorText, setErrorText] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (orderGuides.data && orderGuides.data.length > 0) {
      dispatch(setOrderGuideSummaries(orderGuides.data));
      // Set the default value for the businessId and orderGuideId
      dispatch(setAddToGuideLastSelectedBusinessId(orderGuides.data[0].businessId));
      // dispatch(setAddToGuideLastSelectedGuideId(orderGuides.data[0].id));
    }
  }, [orderGuides.data]);

  useEffect(() => {
    // console.log("businessId", businessId);
    // console.log("orderGuideId", orderGuideId);
    // console.log("orderGuidesForBusiness", orderGuidesForBusiness);
  }, [businessId, orderGuideId, orderGuidesForBusiness]);

  useEffect(() => {
    if (updateResult.isSuccess) {
      setOpen(false);
      dispatch(showNotification({ message: (selectedItem?.name || "Item") + " added to order guide", severity: "success" }));
    } else {
      if (updateResult.error) {
        const err = updateResult.error as FetchBaseQueryError;
        const problemDetails = err.data as { type: string; title: string; status: number; detail: string };
        if (err.status === 400 && problemDetails?.detail?.includes("already exists")) {
          setErrorText("This product is already in your order guide");
        } else {
          Log.error("AddToOrderGuideDialog", "Error adding product to order guide", err);
          setErrorText("An error occurred");
        }
      }
    }
  }, [dispatch, updateResult.isSuccess]);

  const onSubmit = () => {
    console.log("onSubmit", selectedItem, businessId, orderGuideId);
    if (selectedItem && businessId && orderGuideId) {
      updateTrigger({
        orderGuideId: orderGuideId || 0,
        productLinkId: selectedItem.id!,
      });
    }
  };

  const getBusinessOptions = () => {
    const businesses = orderGuides.data?.map((orderGuide) => ({ businessId: orderGuide.businessId!, name: orderGuide.businessName! }));

    // Get unique businesses
    const uniqueBusinesses: {
      value: string;
      label: React.ReactNode;
    }[] =
      businesses
        ?.filter((business, index, self) => self.findIndex((b) => b.businessId === business.businessId) === index)
        .map((business) => ({
          label: business.name,
          value: business.businessId.toString(),
        })) ?? [];

    return uniqueBusinesses;
  };

  const getCurrentOrderGuideLabel = () => {
    const orderGuide = orderGuides.data?.find((o) => o.id === orderGuideId);

    if (!orderGuide) return "Select order guide...";

    return `${orderGuide.businessName} - ${orderGuide.name}`;
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {children}
        {/*<Button variant="link">Update</Button>*/}
      </DialogTrigger>
      <DialogContent className="max-w-xl">
        <DialogHeader>
          <DialogTitle>Add to your order guide</DialogTitle>
          {/*<DialogDescription>Order guides are a way to organize your orders. You can create as many as you like.</DialogDescription>*/}
        </DialogHeader>
        <div className="mt-5 flex flex-col space-y-10">
          <div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full bg-blue-100">
            <ShoppingCartIcon className="h-16 w-16 text-blue-600" aria-hidden="true" />
          </div>

          <p className="text-center text-lg font-semibold">{selectedItem?.name}</p>

          <div className="space-y-6">
            <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
              <PopoverTrigger asChild>
                <Button variant="outline" role="combobox" aria-expanded={open} className="space-between flex w-full">
                  {getCurrentOrderGuideLabel()}
                  <ChevronsUpDown className="ml-auto h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-full p-0">
                <Command filter={(value, search) => (value.toLowerCase().includes(search.toLowerCase()) ? 1 : 0)}>
                  <CommandInput placeholder={`Search order guides...`} />
                  <CommandList>
                    <CommandEmpty>No order guides found.</CommandEmpty>
                    {getBusinessOptions().map((business, index) => (
                      <Fragment key={business.value + index}>
                        {index > 0 && <CommandSeparator />}
                        <CommandGroup key={business.value}>
                          <CommandItem value={business.value} disabled={true}>
                            {business.label}
                          </CommandItem>
                          {orderGuides.data
                            ?.filter((o) => o.businessId === parseInt(business.value))
                            .map((o) => (
                              <CommandItem
                                key={o.id}
                                value={o.id.toString()}
                                onSelect={(currentValue) => {
                                  dispatch(setAddToGuideLastSelectedGuideId(parseInt(currentValue)));
                                  setPopoverOpen(false);
                                }}
                              >
                                <Check className={cn("mr-2 h-4 w-4", orderGuideId === o.id ? "opacity-100" : "opacity-0")} />
                                {o.name}
                              </CommandItem>
                            ))}
                        </CommandGroup>
                      </Fragment>
                    ))}
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>

            {/*<div className="group">*/}
            {/*  <div className="flex">Business</div>*/}
            {/*  <Select onValueChange={(value) => dispatch(setAddToGuideLastSelectedBusinessId(parseInt(value)))} defaultValue={businessId ?? ""}>*/}
            {/*    <SelectTrigger>*/}
            {/*      <SelectValue />*/}
            {/*    </SelectTrigger>*/}
            {/*    <SelectContent>*/}
            {/*      {getBusinessOptions().map((option) => (*/}
            {/*        <SelectItem key={option.value} value={option.value}>*/}
            {/*          {option.label}*/}
            {/*        </SelectItem>*/}
            {/*      ))}*/}
            {/*    </SelectContent>*/}
            {/*  </Select>*/}
            {/*</div>*/}

            {/*{orderGuideId && (*/}
            {/*  <div className="group">*/}
            {/*    <div className="flex">Order Guide</div>*/}
            {/*    <Select onValueChange={(value) => dispatch(setAddToGuideLastSelectedGuideId(parseInt(value)))} defaultValue={orderGuideId ?? ""}>*/}
            {/*      <SelectTrigger>*/}
            {/*        <SelectValue />*/}
            {/*      </SelectTrigger>*/}
            {/*      <SelectContent>*/}
            {/*        {orderGuidesForBusiness?.map((option) => (*/}
            {/*          <SelectItem key={option.id} value={option.id.toString()}>*/}
            {/*            {option.name}*/}
            {/*          </SelectItem>*/}
            {/*        ))}*/}
            {/*      </SelectContent>*/}
            {/*    </Select>*/}
            {/*  </div>*/}
            {/*)}*/}

            {errorText && <div className="text-red-600">{errorText}</div>}

            <DialogFooter>
              <Button type="submit" className="w-full" onClick={() => onSubmit()}>
                Save
              </Button>
            </DialogFooter>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
