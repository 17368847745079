import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "sonner";

export type ToastMessage = {
  message: string;
  severity: "normal" | "success" | "info" | "warning" | "error" | "default";
  key?: string;
  isApiError?: boolean;
};

export const toastSlice = createSlice({
  name: "notification",
  initialState: [] as ToastMessage[],
  reducers: {
    showNotification: (_, { payload }: PayloadAction<ToastMessage>) => {
      switch (payload.severity) {
        case "normal":
        case "default":
          toast.message(payload.message, { position: payload.isApiError ? "bottom-right" : "top-center" });
          break;
        case "success":
          toast.success(payload.message, { position: payload.isApiError ? "bottom-right" : "top-center" });
          break;
        case "info":
          toast.info(payload.message, { position: payload.isApiError ? "bottom-right" : "top-center" });
          break;
        case "warning":
          toast.warning(payload.message, { position: payload.isApiError ? "bottom-right" : "top-center" });
          break;
        case "error":
          toast.error(payload.message, { position: payload.isApiError ? "bottom-right" : "top-center" });
          break;
      }
    },
  },
});

export const { showNotification } = toastSlice.actions;
