/* eslint-disable @typescript-eslint/ban-ts-comment */
import { apiQueryErrorLoggerMiddleware } from "@/store/ApiQueryErrorLoggerMiddleware.tsx";
import { businessSlice } from "@/store/Business.slice.ts";
import { notificationsSlice } from "@/store/Notifications.slice.ts";
import { onboardingSlice } from "@/store/Onboarding.slice.ts";
import { orderGuideWidgetSlice } from "@/store/OrderGuideWidget.slice.ts";
import { toastSlice } from "@/store/Toast.slice.ts";
import { orderGuideUploadProductMatchingSlice } from "@/store/order-guide-upload/product-matching/OrderGuideUploadProductMatching.slice.ts";
import { orderGuideUploadWizardSlice } from "@/store/order-guide-upload/wizard/OrderGuideUploadWizard.slice.ts";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
// import { persistStore } from "redux-persist";
// import { PersistConfig } from "redux-persist/es/types";
// import storage from "redux-persist/lib/storage";
import { apiSlice } from "./API.slice.ts";
import { authSlice } from "./Auth.Slice.ts";
import { devSlice } from "./Dev.slice.ts";
import { orderGuideSlice } from "./OrderGuide.slice.ts";
import { orderGuideItemSlice } from "./OrderGuideItem.slice.ts";

// const persistConfig: PersistConfig<any> = {
//   key: "root",
//   storage: storage,
//   blacklist: ["auth", "orderGuideUpload", "orderGuideItems", "orderGuide", "dev", "toast", apiSlice.reducerPath, irnApi.reducerPath],
//   debug: true,
// };

// console.log("persistConfig", persistConfig);

const rootReducer = combineReducers({
  dev: devSlice.reducer,
  onboarding: onboardingSlice.reducer,
  toasts: toastSlice.reducer,
  orderGuideWidget: orderGuideWidgetSlice.reducer,
  business: businessSlice.reducer,
  // notifications: persistReducer({ key: "notifications", storage: storage }, notificationsSlice.reducer),
  // auth: persistReducer({ key: "auth", storage: storage /*whitelist: ["token"]*/, debug: true }, authSlice.reducer),
  // orderGuide: persistReducer({ key: "orderGuide", storage: storage }, orderGuideSlice.reducer),
  // orderGuideItems: persistReducer({ key: "orderGuideItems", storage: storage }, orderGuideItemSlice.reducer),
  notifications: notificationsSlice.reducer,
  auth: authSlice.reducer,
  orderGuide: orderGuideSlice.reducer,
  orderGuideItems: orderGuideItemSlice.reducer,
  // orderGuideUpload: persistReducer({ key: "orderGuideUpload", storage: storage }, orderGuideUploadSlice.reducer),

  orderGuideUploadWizard: orderGuideUploadWizardSlice.reducer,
  orderGuideUploadWizardProductMatching: orderGuideUploadProductMatchingSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// @ts-ignore
export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
      immutableCheck: {
        ignoredPaths: ["api", "dev", "orderGuide", "orderGuideItems", "orderGuideUpload", "_persist"],
      },
    })
      // .prepend(setBusinessesStateMiddleware)
      .concat(apiQueryErrorLoggerMiddleware)
      .concat(apiSlice.middleware),

  /*.concat(logger)*/
});
// export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
// @ts-ignore
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
