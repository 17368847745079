import { Badge } from "@/components/ui/badge.tsx";
import { Button } from "@/components/ui/button.tsx";
import { selectActiveBusiness } from "@/store/Business.slice.ts";
import { useAppSelector } from "@/store/Hooks.ts";
import { UploadProcessingStatus, useGetOrderGuideUploadSummariesQuery } from "@/store/IRN.API.ts";
import { isToday } from "@/utils.ts";
import { ArrowUpTrayIcon, CheckBadgeIcon, ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import { ArrowPathIcon, ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { format } from "date-fns";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export function UploadOrderGuide() {
  const activeBusiness = useAppSelector(selectActiveBusiness);
  const pendingUploads = useGetOrderGuideUploadSummariesQuery(activeBusiness!.id!);

  // If pending uploads has data, then automatically refresh it every 5 seconds
  useEffect(() => {
    if (
      pendingUploads.data &&
      pendingUploads.data.length > 0 &&
      // Make sure there are some that are not completed/failed/confirmed/etc. We don't need to refresh if they are all done
      !pendingUploads.data.every(
        (x) => x.status === UploadProcessingStatus.Completed || x.status === UploadProcessingStatus.Failed || x.status === UploadProcessingStatus.Confirmed,
      )
    ) {
      const interval = setInterval(() => {
        pendingUploads.refetch();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [pendingUploads, pendingUploads.data]);

  // function getEta(item: OrderGuideUploadSummaryDto) {
  //   if (item.status === UploadProcessingStatus.Completed) {
  //     return item.processedEntryCount + " rows processed";
  //   } else if (item.status === UploadProcessingStatus.Failed) {
  //     return "Failed";
  //   } else if (item.status === UploadProcessingStatus.Confirmed) {
  //     return "Completed";
  //   } else if (item.status === "Uploaded") {
  //     return "Processing...";
  //   }
  //
  //   const eta = item.eta;
  //   const itemsPerSecond = item.itemsPerSecond;
  //
  //   if (!eta || !itemsPerSecond) {
  //     return "Calculating...";
  //   }
  //
  //   const etaDate = new Date(eta);
  //   const now = new Date();
  //   const diff = etaDate.getTime() - now.getTime();
  //   const diffSeconds = diff / 1000;
  //   const diffMinutes = diffSeconds / 60;
  //   const diffHours = diffMinutes / 60;
  //
  //   // return format:
  //   // ETA: 0h 0m 0s - 0/0 (0.0/s)
  //
  //   if (diffSeconds < 60) {
  //     return "Processing...";
  //   } else {
  //     return `ETA: ${Math.floor(diffHours)}h ${Math.floor(diffMinutes % 60)}m ${Math.floor(diffSeconds % 60)}s - ${item.processedEntryCount}/${
  //       item.entryCount
  //     } (${itemsPerSecond.toFixed(1)}/s)`;
  //   }
  // }

  return (
    <>
      <div className="mt-4 w-full">
        {(pendingUploads.data && pendingUploads.data.length > 0 && (
          <>
            <ul className="grid gap-2">
              {pendingUploads.data.map((item) => (
                <li className="grid grid-flow-col gap-4" key={item.id}>
                  <div className="flex flex-col">
                    <Link to={"/app/order-guide/upload/" + item.id} className="truncate">
                      {item.fileKey.split("/").pop()}
                    </Link>

                    <span className="text-xs text-gray-500">
                      {isToday(new Date(item.createdAt)) ? format(new Date(item.createdAt), "M/d/y h:mm a") : format(new Date(item.createdAt), "M/d/y")}
                    </span>
                  </div>
                  <span className="justify-end content-end flex h-min">
                    {item.status === UploadProcessingStatus.Completed && (
                      <Badge variant="yellow">
                        <ClipboardDocumentListIcon className="mr-1 h-5 w-5 " aria-hidden="true" />
                        Unfinished
                      </Badge>
                    )}
                    {item.status === UploadProcessingStatus.Failed && (
                      <Badge variant="destructive">
                        <ExclamationTriangleIcon className="mr-1 h-5 w-5" aria-hidden="true" />
                        Error
                      </Badge>
                    )}
                    {item.status === UploadProcessingStatus.Processing && (
                      <Badge variant="indigo">
                        <ArrowPathIcon className="mr-1 h-5 w-5 animate-spin" aria-hidden="true" />
                        Processing
                      </Badge>
                    )}
                    {item.status === UploadProcessingStatus.Confirmed && (
                      <Badge variant="green">
                        {" "}
                        <CheckBadgeIcon className="mr-1 h-5 w-5" aria-hidden="true" />
                        Completed
                      </Badge>
                    )}
                  </span>

                  {/*<span className="text-xs text-gray-500">{getEta(item)}</span>*/}
                </li>
              ))}
            </ul>

            <div className="flex justify-center gap-10">
              <Button type="button" variant="icon" asChild>
                <Link to={"/app/order-guide/upload/new"}>
                  <ArrowUpTrayIcon className="mr-1 inline-block h-4 w-4" />
                  Upload Order Guide
                </Link>
              </Button>

              <Button type="button" variant="icon" onClick={() => pendingUploads.refetch()} className="text-sky-700 hover:text-sky-800 hover:bg-sky-100">
                {/*<a href="#" className="mt-4 flex place-items-center justify-center text-sm text-sky-700" onClick={() => pendingUploads.refetch()}>*/}
                <ArrowPathIcon className="mr-1 h-5 w-5" />
                Refresh
              </Button>
            </div>
          </>
        )) || (
          <>
            <div className="flex flex-col items-center justify-center gap-5 rounded-lg border-2 border-dashed border-gray-500 p-10">
              <p className="">No uploaded order guides yet</p>

              <Button type="button" variant="icon" asChild>
                <Link to={"/app/order-guide/upload/new"}>
                  <ArrowUpTrayIcon className="mr-1 inline-block h-4 w-4" />
                  Upload Order Guide
                </Link>
              </Button>
              <div className="space-y-2 text-center text-xs">
                <p>
                  You can upload an order guide you&apos;ve downloaded or received from other distributors, to include in our system for price matching and
                  comparisons.
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
