import { OrderGuideUploadColumnType } from "@/store/IRN.API.ts";
import { loadRawFileDataAsync, uploadOrderGuideAsync } from "@/store/order-guide-upload/thunks.ts";
import { SerializableJsFile } from "@/utils.ts";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";

export interface UploadRecord {
  raw: string;
  record: Record<string, any>;
}

export interface FileUploadState {
  records?: UploadRecord[];
  columns?: string[];

  columnTypes?: Record<OrderGuideUploadColumnType, string | null>;
  errorColumns?: OrderGuideUploadColumnType[];
  error?: string;

  // rawFileData?: any;

  skipFirstRow?: boolean;

  extractMpnUpcFromDescription?: boolean;
  file?: SerializableJsFile;

  autoDetectedGuideDistributor?: string;
}

export const defaultNumberOfSteps = 7;

export interface OrderGuideUploadWizardState {
  numberOfSteps: number;
  currentStep: number;

  uploadType?: string;

  canMoveToStep: boolean[];

  selectedDistributorPreset?: string;
  distributorPresetTransformsApplied: boolean;
  distributorPresetTransformsAppliedNotificationShown: boolean;
  uploadedToServer: boolean;
  uploadToServerError?: string;
  uploadedOrderGuidedId?: number;
  distributorId?: number;

  columnSelectionErrors: Partial<Record<OrderGuideUploadColumnType, string | undefined>>;

  showAdvancedControls?: boolean;

  fileState?: FileUploadState;
}

export const initialState: OrderGuideUploadWizardState = {
  numberOfSteps: defaultNumberOfSteps,
  currentStep: 1,
  canMoveToStep: [true, true, ...Array(defaultNumberOfSteps - 2).fill(false)],
  distributorPresetTransformsApplied: false,
  distributorPresetTransformsAppliedNotificationShown: false,
  selectedDistributorPreset: undefined,
  uploadedToServer: false,
  columnSelectionErrors: {},
};

export const orderGuideUploadWizardSlice = createSlice({
  name: "orderGuideUploadWizard",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(loadRawFileDataAsync.rejected, (state, action) => {
      state.uploadToServerError = action.payload as string;
    });

    // builder.addCase(loadRawFileDataAsync.fulfilled, (state, action) => {
    //   state.fileState = {
    //     file: action.payload?.file,
    //   };
    // });

    builder.addCase(uploadOrderGuideAsync.pending, (state) => {
      // state.uploadedToServer = false;
      state.uploadToServerError = undefined;
    });

    builder.addCase(uploadOrderGuideAsync.fulfilled, (state, action) => {
      state.uploadedToServer = true;
      state.uploadToServerError = undefined;
      state.uploadedOrderGuidedId = action.payload;
      console.log("uploadOrderGuideAsync.fulfilled", action.payload);
    });

    builder.addCase(uploadOrderGuideAsync.rejected, (state, action) => {
      state.uploadedToServer = false;
      state.uploadToServerError = action.payload as string;
    });

    builder.addMatcher(isAnyOf(loadRawFileDataAsync.fulfilled, loadRawFileDataAsync.rejected, loadRawFileDataAsync.pending), (state) => {
      state.distributorPresetTransformsApplied = false;
      state.distributorPresetTransformsAppliedNotificationShown = false;
      state.uploadedToServer = false;
      state.columnSelectionErrors = {};
      state.uploadedOrderGuidedId = undefined;
    });
  },
  reducers: {
    incrementStep: (state) => {
      state.currentStep += 1;
    },
    decrementStep: (state) => {
      state.currentStep -= 1;
    },

    setUploadType: (state, action: PayloadAction<string>) => {
      state.uploadType = action.payload;
    },

    setNumberOfSteps: (state, action: PayloadAction<number>) => {
      state.numberOfSteps = action.payload;
    },

    setCanMoveToStep: (state, action: PayloadAction<{ step: number; canMove: boolean }>) => {
      if (!state.canMoveToStep) {
        state.canMoveToStep = [...initialState.canMoveToStep];
      }
      state.canMoveToStep[action.payload.step] = action.payload.canMove;
    },

    setCanMoveToNextStep: (state, action: PayloadAction<boolean>) => {
      console.log("setCanMoveToNextStep", action.payload, state.currentStep, state.canMoveToStep);
      if (!state.canMoveToStep) {
        state.canMoveToStep = [...initialState.canMoveToStep];
      }
      state.canMoveToStep[state.currentStep] = action.payload;
    },

    setSelectedDistributorPreset: (state, action: PayloadAction<string>) => {
      state.selectedDistributorPreset = action.payload;
    },

    setDistributorPresetTransformsApplied: (state, action: PayloadAction<boolean>) => {
      state.distributorPresetTransformsApplied = action.payload;
    },

    setDistributorPresetTransformsAppliedNotificationShown: (state, action: PayloadAction<boolean>) => {
      state.distributorPresetTransformsAppliedNotificationShown = action.payload;
    },

    setColumnSelectionError: (state, action: PayloadAction<{ column: OrderGuideUploadColumnType; error?: string }>) => {
      if (!state.columnSelectionErrors) {
        state.columnSelectionErrors = {};
      }

      state.columnSelectionErrors = {
        ...state.columnSelectionErrors,
        [action.payload.column]: action.payload.error,
      };
    },

    setUploadedToServer: (state, action: PayloadAction<boolean>) => {
      state.uploadedToServer = action.payload;
    },

    setShowAdvancedControls: (state, action: PayloadAction<boolean>) => {
      state.showAdvancedControls = action.payload;
    },

    resetWizard: () => {
      return { ...initialState };
    },

    setSkipFirstRow: (state, action: PayloadAction<boolean>) => {
      if (!state.fileState) {
        state.fileState = {};
      }
      state.fileState.skipFirstRow = action.payload;
    },

    setColumnTypes: (state, action: PayloadAction<Record<OrderGuideUploadColumnType, string | null>>) => {
      if (!state.fileState) {
        state.fileState = {};
      }
      state.fileState.columnTypes = action.payload;
    },

    setExtractMpnUpcFromDescription: (state, action: PayloadAction<boolean>) => {
      if (!state.fileState) {
        state.fileState = {};
      }
      state.fileState.extractMpnUpcFromDescription = action.payload;
    },

    setFile: (state, action: PayloadAction<SerializableJsFile>) => {
      if (!state.fileState) {
        state.fileState = {};
      }
      state.fileState.file = action.payload;
    },

    setAutoDetectedGuideDistributor: (state, action: PayloadAction<string>) => {
      if (!state.fileState) {
        state.fileState = {};
      }
      state.fileState.autoDetectedGuideDistributor = action.payload;
    },

    setColumnNames: (state, action: PayloadAction<string[]>) => {
      if (!state.fileState) {
        state.fileState = {};
      }
      state.fileState.columns = action.payload;
    },

    setRecords: (state, action: PayloadAction<UploadRecord[]>) => {
      if (!state.fileState) {
        state.fileState = {};
      }
      state.fileState.records = action.payload;
    },

    setErrorColumns: (state, action: PayloadAction<OrderGuideUploadColumnType[]>) => {
      if (!state.fileState) {
        state.fileState = {};
      }
      state.fileState.errorColumns = action.payload;
    },

    setError: (state, action: PayloadAction<string>) => {
      if (!state.fileState) {
        state.fileState = {};
      }
      state.fileState.error = action.payload;
    },

    setDistributorId: (state, action: PayloadAction<number>) => {
      state.distributorId = action.payload;
    },

    setFileState: (state, action: PayloadAction<FileUploadState>) => {
      state.fileState = action.payload;
    },

    resetOrderGuideUploadState: () => {
      return initialState;
    },
  },
});

export const {
  incrementStep,
  decrementStep,
  setUploadType,
  setNumberOfSteps,
  setCanMoveToStep,
  setCanMoveToNextStep,
  setSelectedDistributorPreset,
  setDistributorPresetTransformsApplied,
  setDistributorPresetTransformsAppliedNotificationShown,
  setColumnSelectionError,
  resetWizard,
  setUploadedToServer,
  setShowAdvancedControls,
  setSkipFirstRow,
  setColumnTypes,
  setExtractMpnUpcFromDescription,
  setFile,
  setAutoDetectedGuideDistributor,
  setColumnNames,
  setRecords,
  setErrorColumns,
  setError,
  resetOrderGuideUploadState,
  setDistributorId,
  setFileState,
} = orderGuideUploadWizardSlice.actions;

export default orderGuideUploadWizardSlice.reducer;

export const selectCurrentStep = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) => state.orderGuideUploadWizard.currentStep;
export const selectNumberOfSteps = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) => state.orderGuideUploadWizard.numberOfSteps;

export const selectIsFirstStep = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) => state.orderGuideUploadWizard.currentStep === 1;
export const selectIsLastStep = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) =>
  state.orderGuideUploadWizard.currentStep === state.orderGuideUploadWizard.numberOfSteps;
export const selectUploadType = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) => state.orderGuideUploadWizard.uploadType;

export const selectCanMoveToStep = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }, step: number) =>
  state.orderGuideUploadWizard.canMoveToStep[step];

export const selectCanMoveToNextStep = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) =>
  state.orderGuideUploadWizard.canMoveToStep?.[state.orderGuideUploadWizard.currentStep] ?? false;

export const selectSelectedDistributorPreset = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) =>
  state.orderGuideUploadWizard.selectedDistributorPreset;

export const selectDistributorPresetTransformsApplied = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) =>
  state.orderGuideUploadWizard.distributorPresetTransformsApplied;

export const selectDistributorPresetTransformsAppliedNotificationShown = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) =>
  state.orderGuideUploadWizard.distributorPresetTransformsAppliedNotificationShown;

export const selectUploadedToServer = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) => state.orderGuideUploadWizard.uploadedToServer;

export const selectColumnSelectionErrors = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) =>
  state.orderGuideUploadWizard.columnSelectionErrors;

export const selectUploadedOrderGuidedId = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) =>
  state.orderGuideUploadWizard.uploadedOrderGuidedId;

export const selectUploadToServerError = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) => state.orderGuideUploadWizard.uploadToServerError;

export const selectShowAdvancedControls = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) => state.orderGuideUploadWizard.showAdvancedControls;

export const selectFileState = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) => state.orderGuideUploadWizard.fileState;

export const selectSkipFirstRow = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) => state.orderGuideUploadWizard.fileState?.skipFirstRow;

export const selectColumnTypes = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) => state.orderGuideUploadWizard.fileState?.columnTypes;

export const selectExtractMpnUpcFromDescription = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) =>
  state.orderGuideUploadWizard.fileState?.extractMpnUpcFromDescription;

export const selectFile = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) => state.orderGuideUploadWizard.fileState?.file;

export const selectAutoDetectedGuideDistributor = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) =>
  state.orderGuideUploadWizard.fileState?.autoDetectedGuideDistributor;

export const selectColumnNames = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) => state.orderGuideUploadWizard.fileState?.columns;

export const selectRecords = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) => state.orderGuideUploadWizard.fileState?.records;

export const selectErrorColumns = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) => state.orderGuideUploadWizard.fileState?.errorColumns;

export const selectError = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) => state.orderGuideUploadWizard.fileState?.error;

export const selectDistributorId = (state: { orderGuideUploadWizard: OrderGuideUploadWizardState }) => state.orderGuideUploadWizard.distributorId;
