import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { UploadType } from "@/store/IRN.API.ts";
import { selectUploadType, setCanMoveToNextStep, setUploadType } from "@/store/order-guide-upload/wizard/OrderGuideUploadWizard.slice.ts";
import { classNames } from "@/utils.ts";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";

const uploadTypes = [
  {
    id: UploadType.NewOrderGuide,
    name: "New order guide",
    description: "Create a new order guide from scratch.",
    enabled: true,
  },
  {
    id: UploadType.PricingOnly,
    name: "Update prices",
    description: "Update prices for existing products in an order guide.",
    enabled: true,
  },
  {
    id: UploadType.LoadIntoOrderGuide,
    name: "Load products only",
    description: "Load products into an existing order guide without updating prices.",
    enabled: true,
  },
];

export function InitialInfoStep() {
  const dispatch = useAppDispatch();
  const uploadTypeId = useAppSelector(selectUploadType);

  useEffect(() => {
    if (!uploadTypeId) {
      dispatch(setUploadType(uploadTypes[0].id));
    }
  }, []);

  useEffect(() => {
    if (uploadTypeId) {
      dispatch(setCanMoveToNextStep(true));
    }
  }, [uploadTypeId]);

  return (
    <div className="bg-white p-4">
      <div className="grid gap-4">
        <div className="grid gap-0.5">
          <div className="flex items-center gap-2">
            <Cog6ToothIcon className="h-5 w-5" />
            <h3 className="font-semibold">Upload Options</h3>
          </div>
          <p className="text-sm leading-loose text-gray-500 dark:text-gray-400">Choose the type of upload that best fits what you're trying to achieve.</p>
        </div>
        <div>
          <div className="border-0 shadow-none">
            <div className="flex items-center gap-4">
              <RadioGroup
                value={uploadTypeId}
                onChange={(value) => {
                  dispatch(setUploadType(value));
                }}
              >
                <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">Select an upload type</RadioGroup.Label>

                <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                  {uploadTypes.map((uploadType) => (
                    <RadioGroup.Option
                      key={uploadType.id}
                      disabled={!uploadType.enabled}
                      value={uploadType.id}
                      className={({ active }) =>
                        classNames(
                          active ? "border-brand-500 ring-2 ring-brand-500" : "border-gray-300",
                          "relative flex rounded-lg border bg-white p-4 shadow-sm focus:outline-none",
                          !uploadType.enabled ? "cursor-not-allowed opacity-50" : "cursor-pointer",
                        )
                      }
                    >
                      {({ checked, active }) => (
                        <>
                          <span className="flex flex-1">
                            <span className="flex flex-col">
                              <RadioGroup.Label as="span" className="block font-medium text-gray-900">
                                {uploadType.name}
                              </RadioGroup.Label>
                              <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                                {uploadType.description}
                              </RadioGroup.Description>
                              {!uploadType.enabled && (
                                <RadioGroup.Description as="span" className="mt-1 flex items-center text-xs font-medium text-red-500">
                                  Coming soon
                                </RadioGroup.Description>
                              )}
                            </span>
                          </span>
                          <CheckCircleIcon className={classNames(!checked ? "invisible" : "", "h-5 w-5 text-brand-500")} aria-hidden="true" />
                          <span
                            className={classNames(
                              active ? "border" : "border-2",
                              checked ? "border-brand-500" : "border-transparent",
                              "pointer-events-none absolute -inset-px rounded-lg",
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
