import { AuthHydration } from "@/components/authentication/AuthHydration.tsx";
import { AuthWrapper } from "@/components/authentication/AuthWrapper.tsx";
import { MainSidebarLayout } from "@/components/layout/MainSidebarLayout.tsx";

export function AppContainer() {
  return (
    // <MsalProvider instance={msalPca}>
    <AuthWrapper>
      <AuthHydration />
      {/*<MainLayout />*/}
      <MainSidebarLayout />
    </AuthWrapper>
    // </MsalProvider>
  );
}
