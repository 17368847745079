import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils";

const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "border-primary bg-primary/25 text-primary hover:bg-primary/80",
        secondary: "border-secondary bg-secondary/25 text-secondary hover:bg-secondary/80",
        destructive: "border-destructive bg-destructive/25 text-destructive hover:bg-destructive/80",
        outline: "text-foreground",

        yellow: "border-yellow-900 bg-yellow-400/25 text-yellow-900 hover:bg-yellow-300 hover:text-yellow-800/80",
        green: "border-green-900 bg-green-400/25 text-green-900 hover:bg-green-300 hover:text-green-800/80",
        blue: "border-blue-900 bg-blue-400/25 text-blue-900 hover:bg-blue-300 hover:text-blue-800/80",
        indigo: "border-indigo-900 bg-indigo-400/25 text-indigo-900 hover:bg-indigo-300 hover:text-indigo-800/80",
        purple: "border-purple-900 bg-purple-400/25 text-purple-900 hover:bg-purple-300 hover:text-purple-800/80",

        "outline-yellow": "border-yellow-500 text-yellow-600 hover:bg-yellow-300 hover:text-yellow-800/80",
        "outline-green": "border-green-900 text-green-900 hover:bg-green-300 hover:text-green-800/80",
        "outline-blue": "border-blue-900 text-blue-900 hover:bg-blue-300 hover:text-blue-800/80",
        "outline-indigo": "border-indigo-900 text-indigo-900 hover:bg-indigo-300 hover:text-indigo-800/80",
        "outline-purple": "border-purple-900 text-purple-900 hover:bg-purple-300 hover:text-purple-800/80",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
}

export { Badge, badgeVariants };
