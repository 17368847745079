import { Redirect } from "@/components/authentication/AuthenticatedRoute.tsx";
import { useConfirm } from "@/components/dialogs/confirmation-dialog/ConfirmationDialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { Label } from "@/components/ui/label.tsx";
import { Switch } from "@/components/ui/switch.tsx";
import { InitialInfoStep } from "@/pages/app/order-guide/upload/wizard/steps/InitialInfoStep.tsx";
import { SelectFileColumnsStep } from "@/pages/app/order-guide/upload/wizard/steps/SelectFileColumnsStep.tsx";
import { SelectUploadDistributorDefaultsStep } from "@/pages/app/order-guide/upload/wizard/steps/SelectUploadDistributorDefaultsStep.tsx";
import { UploadFileStep } from "@/pages/app/order-guide/upload/wizard/steps/UploadFileStep.tsx";
import { UploadToServerStep } from "@/pages/app/order-guide/upload/wizard/steps/UploadToServerStep.tsx";
import { WaitForMatchesStep } from "@/pages/app/order-guide/upload/wizard/steps/WaitForMatchesStep.tsx";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { useDeleteOrderGuideUploadMutation } from "@/store/IRN.API.ts";
import { showNotification } from "@/store/Toast.slice.ts";
import { selectUpload } from "@/store/order-guide-upload/product-matching/OrderGuideUploadProductMatching.slice.ts";
import {
  decrementStep,
  incrementStep,
  resetOrderGuideUploadState,
  resetWizard,
  selectCanMoveToNextStep,
  selectCurrentStep,
  selectIsFirstStep,
  selectIsLastStep,
  selectNumberOfSteps,
  selectShowAdvancedControls,
  selectUploadedOrderGuidedId,
  setCanMoveToNextStep,
  setShowAdvancedControls,
} from "@/store/order-guide-upload/wizard/OrderGuideUploadWizard.slice.ts";
import { ActivityIcon, ArrowLeft, ArrowRight, ClockIcon, MoreVertical, Trash } from "lucide-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function OrderGuideNewUploadWizardPage() {
  const dispatch = useAppDispatch();

  const resetUploadStates = () => {
    dispatch(resetOrderGuideUploadState());
    dispatch(resetWizard());
  };

  resetUploadStates();

  return <Redirect to="/app/order-guide/upload" />;
}

export function OrderGuideUploadWizardPage() {
  const currentStep = useAppSelector(selectCurrentStep);
  const numberOfSteps = useAppSelector(selectNumberOfSteps);
  const isFirstStep = useAppSelector(selectIsFirstStep);
  const isLastStep = useAppSelector(selectIsLastStep);
  const canMoveToNextStep = useAppSelector(selectCanMoveToNextStep);
  // const currentUploadId = useAppSelector(selectUploadedOrderGuidedId);
  const showAdvancedControls = useAppSelector(selectShowAdvancedControls);
  const navigate = useNavigate();
  const confirmationDialog = useConfirm();
  const [deleteOrderGuideUploadTrigger] = useDeleteOrderGuideUploadMutation();
  const upload = useAppSelector(selectUpload);
  const uploadedOrderGuideId = useAppSelector(selectUploadedOrderGuidedId);

  const dispatch = useAppDispatch();
  //
  // // If the route has an :uploadId, we should use that to fetch the upload
  // const params = useParams();
  //
  // if (params.uploadId) {
  //   const uploadId = parseInt(params.uploadId);
  //   if (!isNaN(uploadId) && uploadId !== currentUploadId) {
  //     dispatch(setUploadId(uploadId));
  //     navigate("/app/order-guide/upload/" + uploadId);
  //   }
  // }

  // useEffect(() => {
  //   if (params.step && !isNaN(parseInt(params.step)) && parseInt(params.step) !== currentStep) {
  //     // Whenever the step changes, update the URL to reflect the current step
  //     // Use the "step" query parameter to keep track of the current step
  //     navigate(`?step=${currentStep}`);
  //     console.log("Navigating to step", currentStep);
  //   }
  //   dispatch(setNumberOfSteps(defaultNumberOfSteps));
  // }, [currentStep]);

  // Whenever we change steps, disable the next button until the new step is ready
  useEffect(() => {
    if (currentStep > 1) {
      dispatch(setCanMoveToNextStep(false));
    }
  }, [currentStep]);

  // useEffect(() => {
  //   if (currentUploadId && !isNaN(currentUploadId)) {
  //     dispatch(setUploadId(currentUploadId));
  //
  //     if (!params.uploadId) {
  //       navigate("/app/order-guide/upload/" + currentUploadId);
  //     }
  //   }
  // }, [currentUploadId]);

  const resetUploadStates = () => {
    dispatch(resetOrderGuideUploadState());
    dispatch(resetWizard());
    navigate("/app/order-guide/upload");
  };

  // Pass down a callback to each step about when the previous step button is clicked
  const previousStep = () => {
    dispatch(decrementStep());
  };

  const nextStep = () => {
    if (currentStep + 1 === numberOfSteps) {
      navigate(`/app/order-guide/upload/${uploadedOrderGuideId}`);
    }
    dispatch(incrementStep());
  };

  return (
    <div className="mx-auto max-w-8xl">
      <div className="rounded-lg border bg-white">
        <div className="flex items-center border-b p-4">
          <div className="flex items-center gap-2">
            <ActivityIcon className="h-6 w-6" />
            <h2 className="text-lg font-semibold">Upload an order guide</h2>
          </div>
          <div className="ml-auto flex items-center gap-2 text-sm">
            <ClockIcon className="h-4 w-4" />
            <span className="font-medium">
              Step {currentStep} of {numberOfSteps}
            </span>

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="px-2">
                  <MoreVertical className="h-5 w-5" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                  <div className="flex items-center gap-1">
                    <Switch
                      id="showAdvancedFeatures"
                      checked={showAdvancedControls}
                      onCheckedChange={(checked) => dispatch(setShowAdvancedControls(checked))}
                    ></Switch>
                    <Label htmlFor="showAdvancedFeatures">Show Advanced Features</Label>
                  </div>
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="cursor-pointer text-red-500 hover:bg-red-200 focus:bg-red-200 focus:text-red-600"
                  onClick={async () => {
                    const confirmed = await confirmationDialog({
                      title: "Remove Upload",
                      body: (
                        <div className="flex flex-col gap-5">
                          <p>Are you sure you want to delete this upload?</p>
                          <p className="font-semibold">{upload?.fileKey}</p>
                          <p className="font-semibold text-destructive">This action cannot be undone.</p>
                        </div>
                      ),
                      actionButton: "Delete",
                      actionVariant: "destructive",
                      cancelButton: "Cancel",
                      cancelVariant: "link",
                    });

                    if (confirmed) {
                      deleteOrderGuideUploadTrigger(upload!.id).then((result) => {
                        if ("error" in result && result.error) {
                          console.error(result.error);
                          navigate(`/app/dashboard`);
                          dispatch(resetOrderGuideUploadState());
                          dispatch(resetWizard());
                        } else {
                          navigate(`/app/dashboard`);
                          dispatch(showNotification({ message: "Upload deleted", severity: "success" }));
                          dispatch(resetOrderGuideUploadState());
                          dispatch(resetWizard());
                        }
                      });
                    }
                    // const confirmed = await confirmationDialog({
                    //   title: "Remove Item",
                    //   body: (
                    //     <div className="flex flex-col gap-5">
                    //       <p>Are you sure you want to delete this item?</p>
                    //       <p className="font-semibold">{item.row.original.productLink?.name}</p>
                    //       <p className="font-semibold text-destructive">This action cannot be undone.</p>
                    //     </div>
                    //   ),
                    //   actionButton: "Delete",
                    //   actionVariant: "destructive",
                    //   cancelButton: "Cancel",
                    //   cancelVariant: "link",
                    // });
                    //
                    // if (confirmed) {
                    //   deleteOrderGuideItemTrigger({
                    //     orderGuideId: guide!.id,
                    //     productLinkId: item.row.original.productLink!.id!,
                    //   });
                    // }
                  }}
                >
                  {/* Delete */}
                  <Trash className="mr-2 h-4 w-4" />
                  <span>Delete</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>

        {currentStep === 1 && <InitialInfoStep />}
        {currentStep === 2 && <UploadFileStep />}
        {currentStep === 3 && <SelectUploadDistributorDefaultsStep />}
        {currentStep === 4 && <SelectFileColumnsStep />}
        {currentStep === 5 && <UploadToServerStep />}
        {currentStep === 6 && <WaitForMatchesStep />}
        {/* {currentStep === 7 && <Redirect to={`/app/order-guide/upload/${uploadId}`} />} */}
        {/* {currentStep === 7 && <ReviewAndMatchItemsStep />} */}

        <div className="mt-10 flex justify-between p-5">
          <div className="flex gap-4">
            {!isFirstStep && (
              <Button onClick={previousStep}>
                <ArrowLeft className="mr-1 h-4 w-4" />
                Previous
              </Button>
            )}

            <Button onClick={resetUploadStates} variant="secondary">
              Reset
            </Button>
          </div>

          {!isLastStep && (
            <Button onClick={nextStep} disabled={!canMoveToNextStep}>
              Next <ArrowRight className="ml-1 h-4 w-4" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
