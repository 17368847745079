import { OrderGuideItemListing } from "@/components/order-guide/OrderGuideItemListing.tsx";
import { OrderGuideDisplayCategoryDto, OrderGuideDisplayDto, OrderGuideItemDto } from "@/store/IRN.API.ts";
import { Droppable } from "@hello-pangea/dnd";
import { twMerge } from "tailwind-merge";

type Props = {
  orderGuide: OrderGuideDisplayDto;
  category: OrderGuideDisplayCategoryDto;
  items: OrderGuideItemDto[];
  allowItemReordering: boolean;
};

export function OrderGuideCategoryListing({ allowItemReordering, category, items, orderGuide }: Props) {
  return (
    <div className={twMerge("grid w-full grid-cols-1")}>
      <div className="p-2 text-brand-600">
        <h3>{category.name}</h3>
      </div>
      <div className="grid w-full grid-cols-1 divide-y divide-gray-400">
        <Droppable droppableId={`ogi-cat-${category.id}`} isDropDisabled={!allowItemReordering}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={twMerge(snapshot.isDraggingOver ? "bg-gray-100" : "", snapshot.draggingFromThisWith ? "bg-brand-50/50" : "")}
            >
              {items?.map((item, index) => (
                <OrderGuideItemListing orderGuide={orderGuide} itemId={item.id} key={`ogi-${item.id}`} index={index} canDrag={allowItemReordering} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
}
