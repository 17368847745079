import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip.tsx";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import React, { useEffect } from "react";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";

export function IrnInput<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  control,
  label,
  type,
  placeholder,
  description,
  name,
  ...props
}: {
  control: Control<TFieldValues, TName>;
  label?: string;
  type?: string;
  placeholder?: string;
  description?: string;
  name: TName;
  [key: string]: unknown;
} & Omit<React.ComponentProps<typeof Input>, "name" | "type" | "placeholder">) {
  if (!type) {
    type = "text";
  }
  // const { field, formState } = useController({ control, name });
  const [required, setRequired] = React.useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { errors } = await control._executeSchema([name]);

        // Pull the name the errors. Names are part1.part2.partN.name

        const nameParts = name.split(".");
        let errorsForName: any = errors;
        for (const namePart of nameParts) {
          errorsForName = errorsForName[namePart];
        }

        // If there are errors, log them to the console
        if (errorsForName && errorsForName.type == "required") {
          setRequired(true);
        }
      } catch (err) {
        //
      }
    })();
  }, [control, name]);

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="group">
          {label && (
            <FormLabel>
              {/* If the field is required, show a marker */}

              {label}
              {required && <span className="ml-1 text-red-500">*</span>}
              {description && (
                <Tooltip>
                  <TooltipTrigger className="group-hover:opacity-100 group-focus:opacity-100 sm:opacity-0">
                    <QuestionMarkCircledIcon className="ml-1 h-4 w-4 text-blue-500" />
                  </TooltipTrigger>
                  <TooltipContent>{description}</TooltipContent>
                </Tooltip>
              )}
            </FormLabel>
          )}
          <FormControl>
            <Input {...field} type={type} placeholder={placeholder} {...props} />
          </FormControl>
          {description && <FormDescription className="sm:hidden">{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function IrnTextArea<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  control,
  label,
  type,
  placeholder,
  description,
  name,
  ...props
}: {
  control: Control<TFieldValues, TName>;
  label?: string;
  type?: string;
  placeholder?: string;
  description?: string;
  name: TName;
  [key: string]: unknown;
}) {
  if (!type) {
    type = "text";
  }
  // const { field, formState } = useController({ control, name });
  const [required, setRequired] = React.useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { errors } = await control._executeSchema([name]);

        // Pull the name the errors. Names are part1.part2.partN.name

        const nameParts = name.split(".");
        let errorsForName: any = errors;
        for (const namePart of nameParts) {
          errorsForName = errorsForName[namePart];
        }

        // If there are errors, log them to the console
        if (errorsForName && errorsForName.type == "required") {
          setRequired(true);
        }
      } catch (err) {
        //
      }
    })();
  }, [control, name]);

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="group">
          {label && (
            <FormLabel>
              {/* If the field is required, show a marker */}

              {label}
              {required && <span className="ml-1 text-red-500">*</span>}
              {description && (
                <Tooltip>
                  <TooltipTrigger className="group-hover:opacity-100 group-focus:opacity-100 sm:opacity-0">
                    <QuestionMarkCircledIcon className="ml-1 h-4 w-4 text-blue-500" />
                  </TooltipTrigger>
                  <TooltipContent>{description}</TooltipContent>
                </Tooltip>
              )}
            </FormLabel>
          )}
          <FormControl>
            <Textarea {...field} placeholder={placeholder} {...props} />
          </FormControl>
          {description && <FormDescription className="sm:hidden">{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function IrnSelect<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  control,
  label,
  options,
  required,

  placeholder = "---",
  description,
  name,
}: {
  control: Control<TFieldValues, TName>;
  options: { value: string; label: React.ReactNode }[];
  label?: React.ReactNode;
  placeholder?: string | null;
  description?: React.ReactNode;
  required?: boolean;
  name: TName;
}) {
  // const { field, formState } = useController({ control, name });
  const [requiredLabel, setRequiredLabel] = React.useState(required);

  useEffect(() => {
    (async () => {
      try {
        const { errors } = await control._executeSchema([name]);

        // Pull the name the errors. Names are part1.part2.partN.name

        const nameParts = name.split(".");
        let errorsForName: any = errors;
        for (const namePart of nameParts) {
          errorsForName = errorsForName[namePart];
        }

        console.log(errorsForName);
        // If there are errors, log them to the console
        if (errorsForName && errorsForName.type == "required") {
          setRequiredLabel(true);
        }
      } catch (err) {
        //
      }
    })();
  }, [control, name]);
  // When anything in the control is hovered (or screen sizes are small), show the description tooltip button

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="group">
          {label && (
            <FormLabel className="flex">
              {label}
              {requiredLabel && <span className="ml-1 text-red-500">*</span>}
              {description && (
                <Tooltip>
                  <TooltipTrigger className="group-hover:opacity-100 group-focus:opacity-100 sm:opacity-0">
                    <QuestionMarkCircledIcon className="ml-1 h-4 w-4 text-blue-500" />
                  </TooltipTrigger>
                  <TooltipContent>{description}</TooltipContent>
                </Tooltip>
              )}
            </FormLabel>
          )}
          <Select onValueChange={field.onChange} defaultValue={field.value}>
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {options.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          {description && <FormDescription className="sm:hidden">{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
