import { Button } from "@/components/ui/button.tsx";
import { Drawer, DrawerClose, DrawerContent, DrawerFooter, DrawerHeader, DrawerTitle, DrawerTrigger } from "@/components/ui/drawer.tsx";
import { SearchFacet, SearchFacetDto } from "@/store/IRN.API.ts";
import { Disclosure } from "@headlessui/react";
import { FilterIcon, Minus, Plus } from "lucide-react";
import { SearchFilter } from "./SearchFilter.ts";

export function MiniFormatProductFilter(props: {
  searchFilters: SearchFilter[];
  activeSearchFacets: SearchFacetDto[];
  setActiveSearchFacets: (facets: SearchFacetDto[]) => void;
}) {
  const checkFacet = (facetName: SearchFacet, facetValue: string, checked: boolean) => {
    const filter = props.searchFilters.find((f) => f.id === facetName);
    if (filter) {
      filter.options.forEach((option) => {
        if (option.value === facetValue) {
          if (checked) {
            option.checked = true;

            if (!props.activeSearchFacets.find((f) => f.facet === facetName && f.value === facetValue)) {
              props.setActiveSearchFacets([...props.activeSearchFacets, { facet: facetName, value: facetValue }]);
            }
          } else {
            option.checked = false;

            props.setActiveSearchFacets(props.activeSearchFacets.filter((f) => f.facet !== facetName || f.value !== facetValue));
          }
        }
      });
    }
  };
  return (
    <div className="w-full flex flex-row-reverse pt-2">
      {/*
      Filter button
      */}

      <Drawer>
        <DrawerTrigger asChild>
          <Button variant="outline" className="">
            <FilterIcon className="text-gray-500 mr-2 h-5 w-5" /> Filter
          </Button>
        </DrawerTrigger>

        <DrawerContent>
          <div className="mx-auto w-full max-w-sm">
            <DrawerHeader>
              <DrawerTitle>Search Filters</DrawerTitle>
            </DrawerHeader>
            {props.searchFilters.map((section) => (
              <Disclosure as="div" key={section.id} className="py-6 last:pb-0" defaultOpen={section.name !== "Distributor"}>
                {({ open }) => (
                  <>
                    <h3 className="-my-3 flow-root">
                      <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                        <span className="font-medium text-gray-900">{section.name}</span>
                        <span className="ml-6 flex items-center">
                          {open ? <Minus className="h-5 w-5" aria-hidden="true" /> : <Plus className="h-5 w-5" aria-hidden="true" />}
                        </span>
                      </Disclosure.Button>
                    </h3>
                    <Disclosure.Panel className="pt-6">
                      <div className="max-h-96 space-y-4 overflow-y-auto pl-1">
                        {section.options.map((option, optionIdx) => (
                          <div key={option.value + optionIdx} className="flex items-center">
                            <input
                              id={`filter-${section.id}-${optionIdx}`}
                              name={`${section.id}[]`}
                              defaultValue={option.value}
                              type="checkbox"
                              defaultChecked={option.checked}
                              className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                              onChange={(e) => {
                                checkFacet(section.id, option.value, e.target.checked);
                              }}
                            />
                            <label htmlFor={`filter-${section.id}-${optionIdx}`} className="ml-3 text-sm text-gray-600">
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
            <DrawerFooter>
              <DrawerClose asChild>
                <Button>Close</Button>
              </DrawerClose>
            </DrawerFooter>
          </div>
        </DrawerContent>
      </Drawer>

      {/* Filters */}
      {/*<h2 className="">Filter</h2>*/}
      {/*<div className="divide-y">*/}

      {/*</div>*/}

      {/*/!* Product grid *!/*/}
      {/*<div className="lg:col-span-3">/!* Your content *!/</div>*/}
    </div>
  );
}
