import { OrderGuideItemDto } from "@/store/IRN.API.ts";
import { Label } from "@/components/ui/label.tsx";
import { NumericInput } from "@/components/ui/numeric-input.tsx";

export function OrderGuideItemListingParControls(props: {
  parEditingEnabled: any;
  item: OrderGuideItemDto;
  quantity: number;
  onParChange: React.ChangeEventHandler<HTMLInputElement>;
  onOnHandChange: React.ChangeEventHandler<HTMLInputElement>;
}) {
  return (
    <>
      <div className="flex flex-col gap-2 text-center">
        <Label>PAR</Label>
        {props.parEditingEnabled && (
          <NumericInput
            id={props.item.id.toString() + "-par"}
            className="w-[3.5rem]"
            min={0}
            max={1000000}
            step={1}
            defaultValue={props.item.par ?? undefined}
            onChange={props.onParChange}
          />
        )}
        {!props.parEditingEnabled && <div className="w-[3.5rem] align-middle font-semibold">{props.item.par}</div>}
      </div>
      <div className="flex flex-col gap-2 text-center">
        <Label>Needed</Label>
        <div className="w-[3.5rem] align-middle font-semibold">{props.item.quantity}</div>
      </div>
      <div className="flex flex-col gap-2 text-center">
        <Label>On-Hand</Label>
        <NumericInput
          id={props.item.id.toString() + "-onHand"}
          className="w-[3.5rem]"
          min={0}
          max={props.item.par ?? 1000}
          step={1}
          disabled={props.item.par === null || props.item.par === undefined}
          defaultValue={props.item.onHand ?? undefined}
          onChange={(event) => {
            props.onOnHandChange(event);
          }}
        />
      </div>
    </>
  );
}
