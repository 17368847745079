import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { UploadProcessingStatus, useGetOrderGuideUploadQuery } from "@/store/IRN.API.ts";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { selectUploadedOrderGuidedId, setCanMoveToNextStep } from "@/store/order-guide-upload/wizard/OrderGuideUploadWizard.slice.ts";
import { useEffect } from "react";
import { Badge } from "@/components/ui/badge.tsx";
import { AnimatedCheckmark } from "@/components/display/AnimatedCheckmark.tsx";

export function WaitForMatchesStep() {
  const dispatch = useAppDispatch();
  const uploadId = useAppSelector(selectUploadedOrderGuidedId);
  const upload = useGetOrderGuideUploadQuery(uploadId!, {
    pollingInterval: 2000,
  });
  const numPending = upload?.data?.entries.filter((e) => e.status === UploadProcessingStatus.Pending).length;
  const numProcessing = upload?.data?.entries.filter((e) => e.status === UploadProcessingStatus.Processing).length;
  const numMatched = upload?.data?.entries.filter((e) => e.status === UploadProcessingStatus.Completed && e.productLinkId && e.productLinkId !== 1).length;
  const numUnmatched = upload?.data?.entries.filter((e) => e.status === UploadProcessingStatus.Completed && (e.productLinkId === 1 || !e.productLinkId)).length;
  const numFailed = upload?.data?.entries.filter((e) => e.status === UploadProcessingStatus.Failed).length;

  // useInterval(() => {
  //   if (uploadId) {
  //     getOrderGuideUploadQueryTrigger(uploadId);
  //   }
  // }, /*upload?.data?.status === UploadProcessingStatus.Completed || upload?.data?.status === UploadProcessingStatus.Confirmed ? null :*/ 2000);
  //
  // useEffect(() => {
  //   if (uploadId) {
  //     getOrderGuideUploadQueryTrigger(uploadId);
  //   }
  // }, [uploadId]);

  useEffect(() => {
    if (upload?.data?.status === UploadProcessingStatus.Completed || upload?.data?.status === UploadProcessingStatus.Confirmed) {
      dispatch(setCanMoveToNextStep(true));
    }
  }, [upload.data]);

  const badges = () => {
    return (
      <div className="grid w-max gap-2 text-center">
        {numPending ? (
          <Badge variant="blue" className="ml-2">
            {numPending} pending
          </Badge>
        ) : null}
        {numProcessing ? (
          <Badge variant="purple" className="ml-2">
            {numProcessing} processing
          </Badge>
        ) : null}
        {numMatched ? (
          <Badge variant="green" className="ml-2">
            {numMatched} matched
          </Badge>
        ) : null}
        {numUnmatched ? (
          <Badge variant="yellow" className="ml-2">
            {numUnmatched} unmatched
          </Badge>
        ) : null}
        {numFailed ? (
          <Badge variant="destructive" className="ml-2">
            {numFailed} failed
          </Badge>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <div className="p-4">
        <div className="flex items-center gap-2">
          <MagnifyingGlassIcon className="h-5 w-5" />
          <h3 className="font-semibold">Product Matching</h3>
        </div>
        {upload.data?.status === UploadProcessingStatus.Completed || upload.data?.status === UploadProcessingStatus.Confirmed ? (
          <>
            <div className="mt-10 grid place-items-center gap-4">
              <AnimatedCheckmark />
              <p className="text-sm leading-loose">Your upload has been processed. You can now review and confirm the matches.</p>
              {badges()}
            </div>
          </>
        ) : (
          <>
            <div className="mt-10 grid place-items-center gap-4">
              <p className="text-sm leading-loose text-gray-500 dark:text-gray-400">
                Your upload is being processed. We are matching your products with our database. This may take a few minutes.
              </p>
              <img src="/search.gif" alt="searching" className="ml-8 h-32 w-32" />
              {badges()}
            </div>
          </>
        )}
      </div>
    </>
  );
}
