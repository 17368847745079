import { FormEvent, useState } from "react";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { formClasses } from "../../components/forms/fields/FormUtils.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useNavigate } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { IrnInput, IrnTextArea } from "@/components/forms/IrnInput.tsx";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "@/components/ui/form.tsx";
import { PublicFooter } from "@/components/layout/PublicFooter.tsx";

type ContactUsRequest = {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  message: string;
};

const contactFormSchema: yup.ObjectSchema<ContactUsRequest> = yup.object<ContactUsRequest>().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phone: yup.string().optional(),
  message: yup.string().required("Message is required"),
});

export function HomeV2() {
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const navigate = useNavigate();
  const contactForm = useForm({
    mode: "onBlur",
    resolver: yupResolver(contactFormSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "I want to learn more about IRN! Please get in touch with me ASAP!",
    },
  });

  async function handleContactSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsSending(true);
    setIsSent(false);
    const form = event.currentTarget;
    const data = Object.fromEntries(new FormData(form).entries());
    // console.log(data);

    try {
      const resp = await fetch("./api/contact-us", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phone,
          message: data.message,
        }),
      });

      setIsSent(resp.ok);
    } catch (e) {
      console.error(e);
    } finally {
      setIsSending(false);
    }
  }

  return (
    <div className="scroll-smooth">
      <div className="relative">
        {/*<header className="fixed inset-x-0 top-0 z-50">*/}
        {/*  <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">*/}
        {/*    <div className="flex">*/}
        {/*      <a href="#" className="-m-1.5 p-1.5">*/}
        {/*        <span className="sr-only">IRN</span>*/}
        {/*        <img className="h-8 w-auto" src="irn-white.png" alt="" />*/}
        {/*      </a>*/}
        {/*    </div>*/}
        {/*  </nav>*/}
        {/*</header>*/}

        <div className="relative isolate h-screen overflow-hidden pt-14">
          <img src="cafe2.jpg" alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
          <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
            <div className="mb-8 flex justify-center">
              <span className="sr-only">IRN</span>
              <img src="irn-white.png" alt="" className="h-36 w-auto drop-shadow-2xl" />
            </div>
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-white [text-shadow:_0_3px_0_rgb(0_0_0_/_40%)] sm:text-4xl">
                <span className="text-white">I</span>ndependent <span className="text-white">R</span>estaurant <span className="text-white">N</span>etwork
              </h1>
              {/*<p className="mt-6 text-lg leading-8 text-gray-300">Tagline here - Lorem ipsum dolor sit amet</p>*/}
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <Button
                  onClick={() => document.getElementById("contact-us")?.scrollIntoView({ behavior: "smooth" })}
                  className="px-6 py-2.5 text-lg font-semibold"
                >
                  Contact us
                </Button>
              </div>

              <div className="mt-10 flex items-center justify-center gap-x-6">
                <AuthenticatedTemplate>
                  <Button onClick={() => navigate("/app/dashboard")} className="px-6 py-2.5 text-lg font-semibold">
                    My Dashboard
                    <ArrowLongRightIcon className="ml-2 h-6 w-6" />
                  </Button>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Button onClick={() => navigate("/login")} className="px-6 py-2.5 text-lg font-semibold">
                    Sign in
                    <ArrowLongRightIcon className="ml-2 h-6 w-6" />
                  </Button>
                </UnauthenticatedTemplate>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-auto mt-20 max-w-7xl space-y-40 px-4 text-gray-500 sm:px-6 lg:px-8">
          <section className="mx-auto grid max-w-6xl gap-10 lg:grid-cols-2">
            <img src="table-food.jpg" alt="" className="h-full w-full rounded-lg object-cover" />
            <div>
              <h2 className="font-serif text-7xl">Our Story</h2>
              <div className="space-y-10">
                <p className="text-lg">
                  IRN was created in 2016 by a restaurant owner who decided that it was time for independent restaurants to take matters into their own hands.
                  His goal is to create a network that stands by the restaurant owners, no matter their origin or their problem. He is putting together programs
                  that aim at helping restaurants succeed.
                </p>
                <p className="text-lg">
                  For this reason, joining IRN is free and non-engaging. There is no kind of fee at any stage and you can be a member to other groups with no
                  conflict of interest.
                </p>
              </div>
            </div>
          </section>
          <section className="mx-auto grid max-w-6xl gap-10 lg:grid-cols-2">
            <div className="order-1 lg:order-none">
              <h2 className="font-serif text-7xl">Our Mission</h2>
              <p className="text-lg">
                The objective of IRN is to help restaurants succeed. We are aware that the world of restaurants is going through major changes. Customers are
                becoming more demanding and technology is invading the industry. The best way for independent restaurants to face those challenges is to stick
                together and organize themselves. That is why IRN was created. To help them face their everyday problems and prepare them for tomorrow.
              </p>
            </div>
            <img src="burgers.jpg" alt="" className="order-none h-full w-full rounded-lg object-cover lg:order-1" />
          </section>
          <section className="mx-auto grid max-w-6xl gap-10 lg:grid-cols-2">
            <img src="table-and-counter.jpg" alt="" className="h-full w-full rounded-lg object-cover" />
            <div>
              <h2 className="font-serif text-7xl">Our Values</h2>
              <div className="space-y-10">
                <p className="text-lg">We stand by our values of confidentiality, education, dependability, and service to the restaurant community.</p>
                <p className="text-lg">
                  <span className="font-bold"> Education</span>: We currently offer ServSafe classes throughout the year for you and your employees. As our
                  organization expands, we plan on sponsoring educational classes and hosting various events with keynote speakers. These educational classes
                  will include topics such as social media, accounting, digital marketing, and much more.
                </p>
                <p className="text-lg">
                  <span className="font-bold"> Dependability</span>: We are an organization that you can rely on. Our goal is to be there for you and your
                  business every step of the way and find solutions in a fast and efficient manner.
                </p>
                <p className="text-lg">
                  <span className="font-bold"> Service to the restaurant community</span>: IRN is a network of independent restaurants. However, we find that
                  networking is better in numbers. The objective is to put all the restaurants under IRN in contact with one another to create a sense of
                  community. This community allows businesses to help one another by exchanging ideas and growing together. We will create a sense of community
                  through a contact info database and organized events.
                </p>
              </div>
            </div>
          </section>
        </div>

        <div className="mt-20 flex w-full flex-col items-center justify-center lg:mt-40" id="contact-us">
          <div
            className="flex w-full flex-col items-center justify-center rounded-md bg-cover py-20"
            style={{
              backgroundImage: "url(food-catering-08.jpg)",
              backgroundSize: "auto",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom center",
            }}
          >
            <h4 className="px-5 text-center font-serif text-4xl">Let's Work Together!</h4>
            {(isSent && (
              <div role="alert" className="rounded-md border border-teal-700 bg-green-100 p-10 text-center text-green-700">
                Thanks for your message, we'll get back to you as soon as possible!
              </div>
            )) || (
              <Form {...contactForm}>
                <form className="grid w-full gap-5 p-5 md:grid-cols-2 lg:w-1/2" onSubmit={handleContactSubmit}>
                  <IrnInput control={contactForm.control} name="firstName" label="First Name" className="w-full" autoComplete="given-name" />
                  <IrnInput control={contactForm.control} name="lastName" label="Last Name" className="w-full" autoComplete="family-name" />
                  <IrnInput control={contactForm.control} name="email" label="Email" className="w-full" autoComplete="email" type="email" />
                  <IrnInput control={contactForm.control} name="phone" label="Phone (Optional)" className="w-full" autoComplete="tel" type="tel" />

                  <div className="col-span-full">
                    <IrnTextArea
                      control={contactForm.control}
                      id="message"
                      name="message"
                      rows={4}
                      className={formClasses}
                      placeholder="Message"
                      type="textarea"
                      defaultValue="I want to learn more about IRN! Please get in touch with me ASAP!"
                    />
                  </div>

                  <Button color="brand" className="col-span-full w-full" disabled={isSending}>
                    {isSending ? "Sending..." : "Contact us"}
                  </Button>
                </form>
              </Form>
            )}
          </div>
        </div>
      </div>
      <PublicFooter />
    </div>
  );
}
