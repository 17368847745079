import { Logo } from "@/components/Logo.tsx";
import { PublicFooter } from "@/components/layout/PublicFooter.tsx";
import { Link } from "react-router-dom";

export function PrivacyPolicy() {
  return (
    <>
      <div className="container py-10">
        <div className="flex w-full justify-center">
          <Link to="/">
            <Logo className="h-24" />
          </Link>
        </div>

        <article className="prose max-w-none lg:prose-xl ">
          <h1 className="font-semibold">Privacy Policy</h1>
          <p>
            IRN&#39;s Privacy Policy is hereby incorporated into this Agreement and applies to the collection, use, disclosure, retention, protection and
            accuracy of your personal information and your business&#39; information (the &quot;Information&quot;) collected for the purposes of the Services
            offered through our website. Our Privacy Policy is based on applicable legislation as well as 10 internationally recognized privacy principles, and
            may be updated from time to time.
          </p>
          <h2>PRIVACY TERMS</h2>
          <p>
            Using our website or those of certain third parties results in IRN{" "}
            <em>
              We have a privacy policy concerning your information, personal and business. It is totally legal and we invite you to have a look as it applies to
              you.
            </em>
          </p>
          <p>
            collecting certain information about you. That information is both personal and non-personal. For purposes of these Terms of Use, “personal
            information” is information about you that can be used to personally identify you and that is not otherwise publicly available. “Non-personal”
            information is everything else. IRN Inc. may also collect information about your transactions with our Company and/or your transactions with its
            business affiliates, third parties, etc. We automatically receive and record information our server logs from your browser, including your IP
            address, company cookie information (including without limitation cookies, flash cookies and beacons), and the pages you request. We may use some or
            all of your information for the following general purposes:
          </p>
          <ul>
            <li>To customize the user experience of its and its affiliates’ websites</li>
            <li>To customize advertising and the content you see</li>
            <li>To offer customized features</li>
            <li>To improve our services</li>
            <li>To conduct research</li>
          </ul>
          <p>
            We may share with or sell to affiliates, advertisers, distributors, manufacturers and other third parties your non-personal information for
            commercial purposes with no compensation to you. We may also share with affiliates, advertisers, distributors, manufacturers and other third parties
            in order for such parties to provide you with more useful information, superior products, or specialized services. However, we do not sell, rent or
            otherwise provide your personal information to any third parties without your permission, other than as specified below.{" "}
            <em>
              When you join us, you share with us some of your information. That is personal (when it regards only and solely you) and non-personal in any other
              case. We have the right to use your information in order to improve our services. As far as sharing personal information, we will do it only after
              your consent; only in cases of helping the justice or protecting our company should we use personal information without your consent. We also use
              cookies to improve your use of our website which you are free to disable. As for third parties’ cookies which might be on our website, you must
              read their terms of use.
            </em>
            <br />
            We may share your personal and/or non-personal information in response to subpoenas, court orders, or legal processes, or, when necessary, to
            establish or exercise Company’s legal rights or defend against legal claims; we may share your personal and/or non-personal information with
            appropriate authorities when we, in our sole discretion, regard it as necessary to share such information in order to facilitate an investigation,
            prevent or take action regarding illegal activities, suspected fraud, or in similar situations involving potential threats to safety of any person,
            violations or suspected violations of these Terms of Use related to personal safety, or as otherwise required by law. We may set and access cookies
            on your computer to, among other things, customize the user experience. You may realize certain losses of functionality if you turn your cookies off
            in your browser or otherwise disable them. Affiliates, advertisers, distributors, manufactures and other third parties displayed on the Website may
            also contain cookies or tag your computer with cookies. We do not control these third party cookies, and, for your own benefit, you should review
            the relevant Terms of Use and/or Privacy Terms of such third party websites. Third party ads displayed on the Website may also include tags enabling
            such third parties to collection information about the user’s interaction with such ads.
          </p>
          <h2>Scope</h2>
          <p>
            For the purposes of this Privacy Policy, the term &quot;Site&quot; means this website, www.irnworks.com, all related webpages, and all related
            websites operated by affiliates or divisions of IRN, but does not include any third-party websites which are linked to or may link from this website
            whether or not such third-party websites are used in connection with the Services.
          </p>
          <h3>1. Accountability</h3>
          <p>
            We have designated a Privacy Officer responsible for the creation, oversight and implementation of our Privacy Policy and procedures to protect
            Personal Information, for receiving your privacy-related questions, and for providing you with information about our privacy practices. Our Privacy
            Officer is also responsible for notifying you of any material changes to our privacy practices, by posting updated information on our Site.
          </p>
          <p>You can contact our Privacy Officer:</p>
          <p>
            Eirini Sakellari
            <br />
            Privacy officer
            <br />
            21 Tanner Street
            <br />
            08033 Haddonfield
            <br />
            NJ USA
          </p>
          <p>
            <a href="mailto:eirini@irnworks.com">eirini@irnworks.com</a>
          </p>
          <p>Attention: Privacy Officer</p>
          <h3>2. Identifying Purposes</h3>
          <p>
            IRN collects, uses, and discloses Personal Information only for the purposes of providing you with our Services and for such other ancillary
            purposes consented to by you as a member of our community.
          </p>
          <h3>3. Personal Information</h3>
          <p>More specifically, IRN collects, uses and discloses information (which may include Personal Information) for the following purposes:</p>
          <ul>
            <li>to provide you with our Services</li>
            <li>
              to promote or offer you products, services and offers (including new and other Services which are offered by us, our sponsors and partners) that
              may be relevant to you and your business. We only provide third companies with non-identifiable, aggregate information
            </li>
            <li>
              to contact you for the purposes of product information, service updates, notifications relating to the Services and newsletters. IRN will never
              sell your contact details to a third party
            </li>
            <li>to monitor system usage, server and software performance, to improve system design, to create benchmarks and to conduct trending analyses</li>
            <li>
              to assist you with technical support issues. It is important to remember that most technical issues can be resolved without an IRN customer
              service representative viewing your Personal Information
            </li>
            <li>
              to comply with any laws, regulation, court orders, subpoenas or other legal process or investigation and to protect ourselves and other
              individuals from harm
            </li>
            <li>
              to assist in due diligence relating to any corporate action such as a financing, merger, amalgamation, sale or divestiture, provided that
              reasonable safeguards are taken to protect the confidentiality of Personal Information in our possession. You will be notified via email and/or a
              prominent notice on our Site of any change in ownership or uses of your personal information, as well as any choices you may have regarding your
              personal information.
            </li>
          </ul>
          <p>
            <em>We take your personal information only from you so that you can use our services.</em>
          </p>
          <h3>4. Consent</h3>
          <p>
            IRN takes a consent-based approach to the collection, use and disclosure of Personal Information. We collect Personal Information directly from you
            and for the purpose of providing you with our Services. We use and disclose the Personal Information only as described in this policy and our Terms
            of Use.
          </p>
          <p>
            Anonymous transactional data (which does not personally identify you or any of your employees, agents, contractors and representatives) might be
            retained in our database.
          </p>
          <p>
            At any time, members can opt-out of email communication from us by choosing to not receive our newsletter or any other notifications from us.
            However, we may still contact you for administrative purposes (e.g. if we need to notify you of a change to our service, changes to our terms of use
            or privacy policy, there is a problem with your account or an anticipated service interruption, etc.).
          </p>
          <p>Withdrawing your consent will not apply to actions IRN has already taken based on your prior consent.</p>
          <h3>5. Limiting Collection</h3>
          <p>
            We collect only the information required to fulfill the identified purposes. It will be the minimum amount of information required to facilitate the
            Services. However, you can choose to provide us with additional information to enhance your use of the Services. We will share your personal
            information with third parties only in the ways that are described in this privacy statement.
          </p>
          <p>We do not knowingly or intentionally collect Personal Information from visitors who are under the age of eighteen (18).</p>
          <h3>6. Limiting Use, Disclosure and Retention</h3>
          <p>
            IRN will not use or disclose Personal Information for purposes other than the identified purposes of the Services or such other purposes which we
            identify from time to time.
          </p>
          <p>
            We also ensure that only those employees responsible for the Services&#39; operations have physical or technical access to Personal Information and
            only where such access is required to perform work authorized by their supervisors.
          </p>
          <p>
            We will retain Personal Information only for the duration of your IRN membership. Personal Information will be retained in access-secured databases.
            If you choose to withdraw from the Services, we will securely destroy your Personal Information in our possession and control within 45 days unless
            we are required to retain such Personal Information longer to fulfill our obligations to you or to third parties. However, we may retain
            non-personal information indefinitely.
          </p>
          <h3>7. Accuracy</h3>
          <p>
            We trust that the Personal Information you provide to us while using the Services is as accurate, complete and up-to-date as necessary for the
            purposes for which it is to be used. Upon request we will provide you with information about whether we hold any of your personal information. You
            are welcome to make changes, request deletion or corrections to Personal Information at any time by contacting us at the contact information listed
            below. We will respond to your request within a reasonable timeframe.
          </p>
          <p>You are responsible for ensuring that the information you have provided is truthful, accurate, reliable and complete.</p>
          <p>
            We will retain your information for as long as your account is active, or as needed to provide the Services to you. We will retain and use your
            information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
          </p>
          <h3>8. Safeguards</h3>
          <p>
            We implement industry best practices appropriate to the sensitivity of your Personal Information. We use administrative, technical, and physical
            safeguards to protect your Personal Information against loss, theft, and unauthorized access, use, disclosure, copying, modification, disposal, or
            destruction in accordance with applicable legal requirements and industry best practices.
          </p>
          <p>
            We train our employees to follow privacy and security practices specific to the Services. We also undertake security assessments to ensure that we
            maintain strong security controls.
          </p>
          <h3>9. Openness</h3>
          <p>
            We make clear and transparent notice of our privacy practices publicly available via our Privacy Commitment and this policy. Our notices describe
            the collection, use, disclosure, and protection of Personal Information and provide the contact information of our privacy contact person.
          </p>
          <p>
            We will advise you at the first reasonable opportunity upon discovering or being advised of an incident where your personal information is lost,
            stolen, accessed, used, disclosed, copied, modified or disposed of by unauthorized persons or in an unauthorized manner.
          </p>
          <h3>10. Challenging Compliance</h3>
          <p>
            Our Privacy Officer is responsible for receiving your privacy-related questions. You may send us your privacy-related questions or challenge our
            compliance with our Privacy Commitment and Privacy Policy by submitting your concern to:
          </p>
          <p>Eirini Sakellari</p>
          <p>Information Security Officer</p>
          <p>21 Tanner Street</p>
          <p>Haddonfield 08033 NJ</p>
          <p>USA</p>
          <p>
            Email: <a href="mailto:eirini@irnworks.com">eirini@irnworks.com</a>
          </p>
          <h3>11. Information Received from Third Party Services</h3>
          <p>
            Should IRN retrieve information regarding you and/or your business from other third party service providers for the purpose of providing our
            Services, we shall not review this information for any purpose, including but not limited to accuracy. It is entirely your responsibility to ensure
            the accuracy, completeness and timeliness of any information required by us or any third party service providers in connection with any of the
            Services.
          </p>
          <p>
            Our Site includes links to other websites whose privacy practices may differ from those of IRN. If you submit personal information to any of those
            sites, your information is governed by their privacy policies. We encourage you to carefully read the privacy policy of any website you visit.
          </p>
          <h3>12. Notification of Privacy Policy Changes</h3>
          <p>
            We may update this privacy policy to reflect changes to our information practices. If we make any material changes we will notify you by email (sent
            to the e-mail address specified in your account) or by means of a notice on our website prior to the change becoming effective. We encourage you to
            periodically review our Privacy Policy for the latest information on our privacy practices.
          </p>
          <h2>MISCELLANEOUS</h2>
          <h3>1. Digital Millennium Copyright Act</h3>
          <p>
            Some of the content provided on the website from time to time may be contributed by members, users and/or our collaborators. You represent and
            warrant that any content or material provided to us does not breach the rights, including copyright, of others.
          </p>
          <h3>2. Procedure for Reporting Copyright Infringement Claims</h3>
          <p>
            If you believe your copyright in any work has been infringed and such work is accessible through our website, you may notify IRN’s copyright agent
            pursuant to the Digital Millennium Copyright Act of 1998 (DMCA) for notice of claims of copyright infringement here, Attention: Copyright Agent. For
            your complaint to be valid under the DMCA, you must provide the following information when providing notice of the claimed copyright infringement,
            which IRN may then forward to the alleged infringer:
          </p>
          <p>a. identification of the copyrighted material that you believe has been infringed;</p>
          <p>b. identification of the allegedly infringing material on the IRN website, with sufficient detail to enable us to locate it on our website;</p>
          <p>c. your address, telephone number and email address;</p>
          <p>d. a statement that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</p>
          <p>
            e. a statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act
            on behalf of the owner of an exclusive right that is allegedly infringed; and
          </p>
          <p>f. provide your or your agent&#39;s physical or electronic signature.</p>
          <p>You may provide this information in writing or by email notification to the following Designated Agent: DMCA Designated Agent, IRN Inc.</p>
          <p>Eirini Sakellari, 21 Tanner Street, Haddonfield, 08033, NJ, USA</p>
          <p>
            <a href="mailto:eirini@irnworks.com">eirini@irnworks.com</a>
          </p>
          <p>Upon receiving your complaint, IRN may remove content that you believe infringes your copyright.</p>
          <p>
            Procedure for Counter-Notification: If material that you have posted to the IRN website has been taken down, you may file a counter-notification by
            contacting IRN&#39;s copyright agent, who can be reached here, Attention: Copyright Agent, which counter-notification must contain the following
            details:
          </p>
          <p>
            a. Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before
            it was removed or disabled;
          </p>
          <p>
            b. A statement, under penalty of perjury, that you have a good faith belief that the material was removed or disabled as a result of mistake or
            misidentification of the material in question;
          </p>
          <p>c. Your name, address and telephone number;</p>
          <p>
            d. A statement that, notwithstanding the choice of laws and venue otherwise set out herein, you consent for the purposes of this
            counter-notification and other proceedings under the DMCA to the jurisdiction of the Federal District Court for judicial district in which your
            address is located or, if your address is outside of the USA, for the jurisdiction of the Courts of New Jersey, USA, and that you will accept
            service of process from the person who submitted a notice in compliance with the section (c)(1)(C) of the DMCA, as generally described above; and
          </p>
          <p>e. Your physical or electronic signature.</p>
          <p>
            NOTE THAT YOU MAY BE HELD LIABLE FOR DAMAGES, COURT COSTS AND ATTORNEY&#39;S FEES INCURRED BY US, BY A COPYRIGHT OWNER, OR BY A COPYRIGHT
            OWNER&#39;S LICENSEE IF YOU PROVIDE ANY FALSE INFORMATION, OR MISREPRESENT YOURSELF AS THE RIGHTFUL OWNER OF COPYRIGHTED MATERIAL. YOU MAY ALSO BE
            SUBJECT TO CRIMINAL PROSECUTION FOR PERJURY.
          </p>
          <p>
            This information should not be construed as legal advice. For further details on the information required for valid DMCA notifications, see 17
            U.S.C. 512(c).
          </p>
          <p>
            Independent Restaurant Network Inc. (collectively &quot;IRN&quot;, &quot;we&quot;, &quot;us&quot;, or &quot;our&quot;) values your trust and
            respects your privacy.
          </p>
          <p>
            We struggle to support independent restaurant owners like you by offering you programs by our approved vendors and price comparison, while
            respecting your privacy expectations and protecting your Personal Information.
          </p>
          <h3>3. Additional Features</h3>
          <p>
            Our website may offer publicly accessible blogs and community forums. Any information you provide in these areas may be read, collected, and used by
            others who access them. We take no responsibility for any information which you or your employees, agents, contractors and representatives post or
            publish on our website and you agree to indemnify and hold the IRN Parties harmless for any loss, cost, complaint, damage, claim or liability
            whatsoever arising from any such post or publication.
          </p>
          <h3>4. Cookies and Other Tracking Technologies/Third-Party Tracking Technologies &amp; Cookies</h3>
          <p>
            As is true of most websites, we gather certain information automatically and store it in log files. This information may include, but is not limited
            to, internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or
            clickstream data.
          </p>
          <p>
            We may combine this automatically collected log information with other information we collect about you. We do this to improve the Services we offer
            you, to improve marketing, analytics, and site functionality.
          </p>
          <p>
            Technologies such as cookies or similar technologies are used by IRN, our advertising and analytics partners, and affiliates. These technologies are
            used in analyzing trends, administering the Site, tracking users&#39; movements around the Site and to gather demographic information about our
            userbase as a whole.
          </p>
          <p>
            We use cookies for session tracking to make it possible to navigate the secure environment inside our Site. When you use our website, your browser
            will ask you if you accept our cookies policy. If you accept, the session ID becomes a persistent cookie, which expires after two weeks of non use.
            Users can control the use of cookies at the individual browser level. If you reject or refuse cookies, you will not be able to use IRN.
          </p>
          <p>
            We use local storage objects (LSOs) such as HTML5 to store preferences. Third parties with whom we partner to provide certain features on our site
            or to display advertising based on your browsing activity use LSOs such as HTML5 to collect and store information.
          </p>
          <p>Various browsers may offer their own management tools for removing HTML5 LSOs.</p>
          <h3>5. Behavioral Targeting/Re-Targeting</h3>
          <p>
            We partner with a third party to either display advertising on our Site or to manage our advertising on other sites. Our third-party partner may use
            technologies such as cookies to gather information about your activities on this site and other sites in order to provide you advertising based upon
            your browsing activities and interests. If you wish to not have this information used for the purpose of serving you interest-based ads, you may
            opt-out by clicking <a href="http://preferences-mgr.truste.com/">here</a>. Please note this does not opt you out of being served ads. You will
            continue to receive generic ads. <em>.</em>
          </p>
          <h3>6. Agents/Service Providers</h3>
          <p>
            We use third-party email services providers in order to provide certain services to IRN to help us run our operations, including, without
            limitation, for purposes of delivering emails to you. If required, we will disclose your personal information to these service providers to the
            extent required by us to receive these services. To unsubscribe from either or both emails, please see the &quot;Consent&quot; section above.
          </p>
          <h3>7. Data Security</h3>
          <p>
            Secure data transmission: When you load a page in your browser, or upload something to IRN, all information is locked up with up to 256-bit SSL/TLS
            encryption. That’s the sort of thing you get with online banking and online shopping. We also support a wide variety of cyphers to ensure the best
            match to your browser and the highest level of encryption possible (based on your browser) for our communications.
          </p>
          <p>
            Secure data storage: Your accounting data is stored on servers that have strict physical access protocols. The facilities are controlled with 24/7
            monitoring, and the technology is digitally protected.
          </p>
        </article>
      </div>
      <PublicFooter />
    </>
  );
}
