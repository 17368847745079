import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";
import { ReactNode } from "react";

export function WaitingStatusDialog({ open, title, children }: { open?: boolean; title?: string; children: ReactNode }) {
  return (
    <Dialog open={open} modal>
      <DialogContent className="max-w-xl" disableClose>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        {children}
      </DialogContent>
    </Dialog>
  );
}
