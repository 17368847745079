/*
<form class="max-w-xs mx-auto">
    <label for="quantity-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Choose quantity:</label>
    <div class="relative flex items-center max-w-[8rem]">
        <button type="button" id="decrement-button" data-input-counter-decrement="quantity-input"
          class="bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
            <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16"/>
            </svg>
        </button>
        <input type="text" id="quantity-input" data-input-counter aria-describedby="helper-text-explanation" class="bg-gray-50 border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="999" required />
        <button type="button" id="increment-button" data-input-counter-increment="quantity-input" class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
            <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16"/>
            </svg>
        </button>
    </div>
    <p id="helper-text-explanation" class="mt-2 text-sm text-gray-500 dark:text-gray-400">Please select a 5 digit number from 0 to 9.</p>
</form>
*/

import { useScript } from "usehooks-ts";

export function DevPage() {
  // const form = useForm({
  //   defaultValues: {
  //     number: 0,
  //   },
  // });
  // return (
  //   <div className="bg-gray-500">
  //     <div className="w-96 p-32"></div>
  //   </div>
  // );

  // useScript("https://jstest.authorize.net/v3/AcceptUI.js");
  useScript("https://jstest.authorize.net/v3/Accept.js");

  return (
    <>
      <form id="payment_form"></form>
    </>
  );
  //
  // const hostedFormToken = useGetHostedPaymentTokenQuery(9);
  //
  // console.log("hostedFormToken", hostedFormToken);
  //
  // return (
  //   <AcceptHosted
  //     formToken={hostedFormToken.data ?? ""}
  //     integration="redirect"
  //     environment="SANDBOX"
  //     // onTransactionResponse={(response) => console.log(JSON.stringify(response, null, 2))}
  //     // onCancel={() => console.log("Cancelled")}
  //     // onSuccessfulSave={() => console.log("Successful Save")}
  //     // onResize={(width, height) => console.log(`Width: ${width}, Height: ${height}`)}
  //   >
  //     <Button disabled={hostedFormToken.isLoading}>Continue to payment</Button>
  //     {/*<AcceptHosted.Button className="btn btn-danger">Continue to IFrame</AcceptHosted.Button>*/}
  //     {/*<AcceptHosted.IFrameBackdrop />*/}
  //     {/*<AcceptHosted.IFrameContainer>*/}
  //     {/*  <AcceptHosted.IFrame />*/}
  //     {/*</AcceptHosted.IFrameContainer>*/}
  //   </AcceptHosted>
  // );
}
