import { OrderGuideUploadDto, OrderGuideUploadEntryDto, ProductLinkDto, useUnlinkOrderGuideUploadEntryMutation } from "@/store/IRN.API.ts";
import { ParsedEntryData, setCurrentEntryUnlinked } from "@/store/order-guide-upload/product-matching/OrderGuideUploadProductMatching.slice.ts";
import { useAppDispatch } from "@/store/Hooks.ts";
import { interpolateColors } from "@/utils.ts";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert.tsx";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip.tsx";
import { ProductTableItemDetailsCell } from "@/components/dashboard/products/ProductTableItemDetailsCell.tsx";
import { ProductMatchedInfoDetails } from "@/pages/app/order-guide/upload/wizard/steps/components/ProductMatchedInfoDetails.tsx";
import { Button } from "@/components/ui/button.tsx";
import { CheckCircledIcon } from "@radix-ui/react-icons";
import { useEffect } from "react";

export function MatchingProductDisplay({
  entryProduct,
  entry,
  entryInfo,
  upload,
}: {
  entryProduct?: ProductLinkDto;
  entry?: OrderGuideUploadEntryDto;
  entryInfo?: ParsedEntryData;
  upload: OrderGuideUploadDto;
}) {
  const [unlinkEntryTrigger, unlinkEntryResult] = useUnlinkOrderGuideUploadEntryMutation();
  const dispatch = useAppDispatch();

  // console.log(entry, entryProduct);

  useEffect(() => {
    if (unlinkEntryResult.isSuccess) {
      // Why are we missing shit?
      console.log(unlinkEntryResult);

      dispatch(setCurrentEntryUnlinked());
    }
  }, [unlinkEntryResult]);

  if (!entryProduct || !entry) {
    return null;
  }

  let bestScore = entry?.potentialProductScores?.[0] ?? 0;

  if (bestScore > 0) {
    bestScore /= 100;
  }

  const confidenceColor = interpolateColors(["#dc2626", "#dc9c26", "#16a34a"], bestScore);

  const getConfidencePct = () => {
    if (bestScore <= 0) {
      return "0%";
    }
    return bestScore.toLocaleString("en-US", { style: "percent", maximumFractionDigits: 2 });
  };

  return (
    <Alert variant="success">
      <CheckCircledIcon className="h-5 w-5" />
      <AlertTitle className="flex w-full justify-between">
        <span>Product matched</span>
        <TooltipProvider delayDuration={300}>
          <Tooltip>
            <TooltipTrigger asChild>
              <span className={`text-xs font-semibold`} style={{ color: confidenceColor }}>
                Confidence: {getConfidencePct()}
              </span>
            </TooltipTrigger>
            <TooltipContent>
              <div className="text-sm font-medium">
                <p>The confidence score is a measure of how closely the product in the file matches the product in the catalog.</p>
                <p>A higher score indicates a closer match.</p>
                <p>The confidence score is calculated based on the fields that were matched.</p>
                <p>A score of 100% indicates a perfect match, while a score of 0% indicates no match.</p>
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <Tooltip></Tooltip>
      </AlertTitle>
      <AlertDescription className="grid gap-4">
        <p>
          We found a product that seems to match this entry. If you believe this is not the correct product, you can click the "Link" button on one of the
          products below to select a different product.
        </p>
        <ProductTableItemDetailsCell item={entryProduct} showSku />
        <ProductMatchedInfoDetails product={entryProduct} parsedInfo={entryInfo} />
        <div className="flex w-full justify-end">
          <p className="text-foreground">
            Not the correct product?{" "}
            <Button
              className="ml-4"
              variant="default"
              onClick={() => {
                unlinkEntryTrigger({
                  body: {
                    entryId: entry.id,
                  },
                  uploadId: upload.id,
                });
              }}
            >
              Unlink
            </Button>
          </p>
        </div>
      </AlertDescription>
    </Alert>
  );
}
