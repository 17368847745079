import { Input } from "@/components/ui/input.tsx";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { OrderGuideDto, OrderGuideItemDto, OrderGuideType, useSetProductQuantityInOrderGuideMutation } from "@/store/IRN.API.ts";
import { selectEnableParEditing } from "@/store/OrderGuide.slice.ts";
import { orderGuideItemsUpdated } from "@/store/OrderGuideItem.slice.ts";
import { CellContext } from "@tanstack/react-table";
import { useEffect, useState } from "react";

export function OrderGuideTableQuantityCell({ item, guide }: { item: CellContext<OrderGuideItemDto, unknown>; guide: OrderGuideDto }) {
  const initialValue = item.getValue() as number;
  const appDispatch = useAppDispatch();
  const [value, setValue] = useState<number>(initialValue);
  const [updateQuantityTrigger] = useSetProductQuantityInOrderGuideMutation();

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (guide?.type === OrderGuideType.Par) {
    return <div className="">{initialValue}</div>;
  }

  const onBlur = () => {
    updateQuantityTrigger({
      orderGuideId: guide?.id ?? 0,
      productLinkId: item.row.original.productLink!.id!,
      body: {
        quantity: value,
      },
    }).then((result) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (result.data) {
        appDispatch(
          orderGuideItemsUpdated({
            id: item.row.original.id!,
            changes: {
              quantity: value,
            },
          })
        );
      }
    });
  };

  return (
    <Input
      type="number"
      name="quantity"
      className="min-w-[10rem]"
      value={value as number}
      min={0}
      max={1000000}
      step={1}
      onChange={(e) => {
        const newValue = Number(e.target.value);
        setValue(newValue);
      }}
      onBlur={onBlur}
      // Set focus on the element, so the onBlur actually takes effect in firefox
      onInput={(e) => e.currentTarget.focus()}
    />
  );
}

export function OrderGuideTableParCell({ item, guide }: { item: CellContext<OrderGuideItemDto, unknown>; guide: OrderGuideDto }) {
  const initialValue = item.getValue() as number;
  const appDispatch = useAppDispatch();
  const [value, setValue] = useState<number>(initialValue);
  const [updateQuantityTrigger] = useSetProductQuantityInOrderGuideMutation();

  const parEditing = useAppSelector(selectEnableParEditing);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!parEditing || guide?.type !== OrderGuideType.Par) {
    return <div>{initialValue}</div>;
  }

  const onBlur = () => {
    updateQuantityTrigger({
      orderGuideId: guide?.id ?? 0,
      productLinkId: item.row.original.productLink!.id!,
      body: {
        par: value,
      },
    }).then((result) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (result.data) {
        appDispatch(
          orderGuideItemsUpdated({
            id: item.row.original.id!,
            changes: {
              par: value,
            },
          })
        );
      }
    });
  };

  return (
    <Input
      type="number"
      name="quantity"
      className="min-w-[10rem]"
      value={value as number}
      min={0}
      max={1000000}
      step={1}
      onChange={(e) => {
        const newValue = Number(e.target.value);
        setValue(newValue);
      }}
      onBlur={onBlur}
      // Set focus on the element, so the onBlur actually takes effect in firefox
      onInput={(e) => e.currentTarget.focus()}
    />
  );
}

export function OrderGuideTableOnHandCell({ item, guide }: { item: CellContext<OrderGuideItemDto, unknown>; guide: OrderGuideDto }) {
  const initialValue = item.getValue() as number;
  const appDispatch = useAppDispatch();
  const [value, setValue] = useState<number>(initialValue);
  const [updateQuantityTrigger] = useSetProductQuantityInOrderGuideMutation();

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (guide?.type !== OrderGuideType.Par) {
    return <div className="">{initialValue}</div>;
  }
  const onBlur = () => {
    updateQuantityTrigger({
      orderGuideId: guide?.id ?? 0,
      productLinkId: item.row.original.productLink!.id!,
      body: {
        onHand: value,
      },
    }).then((result) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (result.data) {
        appDispatch(
          orderGuideItemsUpdated({
            id: item.row.original.id!,
            changes: {
              onHand: value,
            },
          })
        );
      }
    });
  };

  return (
    <Input
      type="number"
      name="quantity"
      className="w-20 min-w-[10rem]"
      value={value as number}
      min={0}
      max={1000000}
      step={1}
      onChange={(e) => {
        const newValue = Number(e.target.value);
        setValue(newValue);
      }}
      onBlur={onBlur}
      // Set focus on the element, so the onBlur actually takes effect in firefox
      onInput={(e) => e.currentTarget.focus()}
    />
  );
}
