import { CreateOrderGuideDialog } from "@/components/dialogs/create-order-guide/CreateOrderGuideDialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import { LinkCard } from "@/components/ui/link-card.tsx";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select.tsx";
import { selectActiveBusiness } from "@/store/Business.slice.ts";
import { useAppSelector } from "@/store/Hooks.ts";
import { selectCurrentOrderGuide } from "@/store/OrderGuide.slice.ts";
import { selectBusinessOrderGuidesForBusiness } from "@/store/OrderGuideWidget.slice.ts";
import { toCurrency } from "@/utils.ts";
import { useNavigate } from "react-router-dom";
import { RefetchOrderGuideSummariesType } from "./RefetchOrderGuideSummariesType.tsx";

export function OrderGuideSelection({ refetchSummaries, compact }: { refetchSummaries: RefetchOrderGuideSummariesType; compact: boolean }) {
  const navigate = useNavigate();
  const activeBusiness = useAppSelector(selectActiveBusiness);
  const selectedBusinessOrderGuides = useAppSelector(selectBusinessOrderGuidesForBusiness(activeBusiness!.id!));
  const currentOrderGuide = useAppSelector(selectCurrentOrderGuide);

  // If we are showing a compact version of the order guide select, use a select box with custom content
  if (compact) {
    return (
      <div className="flex flex-col gap-2">
        <Select
          value={currentOrderGuide?.id.toString()}
          onValueChange={(e) => {
            console.log(e);
            navigate("/app/order-guide/" + e);
          }}
        >
          <SelectTrigger className="h-18 min-w-[20rem]">
            <SelectValue>
              <span className="text-base font-semibold md:text-3xl">{currentOrderGuide?.name}</span>
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            {[...(selectedBusinessOrderGuides?.guides ?? [])]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((guide) => (
                <SelectItem key={guide.id} value={guide.id.toString()} className="w-full">
                  <div className="p-3">
                    <p key={guide.id} className="col-span-full whitespace-pre-wrap text-lg text-brand-600">
                      {guide.name}
                    </p>

                    <div className="text-sm text-gray-500">
                      {guide.itemCount} items - {guide.orderedCount} pcs. - {toCurrency(guide.estimatePrice, false)}
                    </div>
                  </div>
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>
    );
  }

  return (
    <>
      <div className="mt-4 grid gap-2 truncate">
        {[...(selectedBusinessOrderGuides?.guides ?? [])]
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((guide) => (
            <LinkCard to={"/app/order-guide/" + guide.id} key={guide.id} className="grid grid-cols-2 p-3 bg-white">
              <p key={guide.id} className="col-span-full whitespace-pre-wrap text-brand-600">
                {guide.name}
              </p>
              <span className="text-xs text-gray-500">{guide.itemCount} items</span>{" "}
              <span className="text-right text-xs text-gray-500">
                {guide.orderedCount} pcs. - {guide.estimatePrice == 0 ? "$0.00" : toCurrency(guide.estimatePrice, false)}
              </span>
            </LinkCard>
          ))}

        <div className="w-full text-right text-sm">
          <CreateOrderGuideDialog refetchSummaries={refetchSummaries}>
            <Button variant="link">+ Create another</Button>
          </CreateOrderGuideDialog>
        </div>
      </div>
    </>
  );
}
