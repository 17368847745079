import { cn } from "@/lib/utils.ts";

export function PlaceholderImage({ className }: { className?: string }) {
  return (
    <svg className={cn("fill-brand", className)} viewBox="0 0 63.875 63.875" xmlns="http://www.w3.org/2000/svg">
      <g strokeWidth="5.802">
        <path d="M57.11 23.309a.726.726 0 0 0-1.027 0l-8.213 8.21a.823.823 0 0 1-1.164-1.163l8.214-8.21a.725.725 0 1 0-1.027-1.026l-8.214 8.21c-.32.32-.843.32-1.163 0s-.321-.843 0-1.164l8.213-8.21a.725.725 0 1 0-1.026-1.025l-8.214 8.21a.823.823 0 0 1-1.164-1.163l8.214-8.21a.725.725 0 1 0-1.027-1.026L38.561 27.688a2.275 2.275 0 0 0 0 3.215c.32.32.32.842 0 1.163l-5.025 5.022a3.055 3.055 0 0 0-3.737.45L18.848 48.486c-1.793 1.792-1.793 4.707 0 6.5s4.71 1.79 6.502 0l10.952-10.947a3.051 3.051 0 0 0 .451-3.735l5.025-5.022a.824.824 0 0 1 1.163 0c.887.887 2.33.887 3.217 0L57.11 24.335a.725.725 0 0 0 0-1.026zM35.275 43.012 24.324 53.958c-1.227 1.226-3.223 1.226-4.45 0s-1.226-3.221 0-4.447l10.952-10.946a1.6 1.6 0 0 1 2.259 0l2.19 2.19a1.598 1.598 0 0 1 0 2.257zm8.693-8.757a2.278 2.278 0 0 0-3.217 0l-4.963 4.96-1.163-1.163 4.962-4.96a2.275 2.275 0 0 0 0-3.215.823.823 0 0 1 0-1.163l1.24-1.238a2.266 2.266 0 0 0 2 1.354 2.264 2.264 0 0 0 2.189 2.188 2.264 2.264 0 0 0 1.354 1.999l-1.238 1.238a.824.824 0 0 1-1.164 0zM42.873 9.08c-1.402-1.402-3.453-1.862-5.777-1.296-2.348.572-4.872 2.155-7.297 4.579a6.924 6.924 0 0 0-1.772 6.732c.215.776-.025 1.631-.625 2.232l-4.818 4.815a3.055 3.055 0 0 0-3.736.45L7.896 37.54c-1.793 1.791-1.793 4.707 0 6.499s4.71 1.792 6.502 0L25.35 33.092a3.051 3.051 0 0 0 .451-3.735l4.818-4.815c.6-.6 1.456-.84 2.233-.625a6.932 6.932 0 0 0 6.735-1.771c2.425-2.424 4.01-4.946 4.582-7.294.566-2.322.105-4.372-1.296-5.773zm-18.55 22.986L13.372 43.012a3.15 3.15 0 0 1-4.45 0 3.147 3.147 0 0 1 0-4.447l10.952-10.946a1.6 1.6 0 0 1 2.26 0l2.19 2.19a1.598 1.598 0 0 1 0 2.257zm8.916-9.548c-1.28-.354-2.677.029-3.647.998l-4.755 4.753-1.164-1.163 4.756-4.753c.969-.97 1.352-2.366.998-3.645a5.47 5.47 0 0 1 1.399-5.319c2.237-2.236 4.524-3.686 6.614-4.195 1.838-.448 3.362-.133 4.406.911s1.36 2.567.912 4.404c-.509 2.089-1.96 4.375-4.197 6.61a5.477 5.477 0 0 1-5.322 1.399z" />
        <path d="M22.612 30.423a.726.726 0 0 0-1.027 0l-1.095 1.095a.725.725 0 1 0 1.027 1.026l1.095-1.094a.725.725 0 0 0 0-1.027zM19.327 33.707a.726.726 0 0 0-1.027 0l-4.928 4.926a.725.725 0 1 0 1.026 1.026l4.929-4.926a.725.725 0 0 0 0-1.026zM33.564 41.37a.726.726 0 0 0-1.027 0l-1.095 1.094a.725.725 0 1 0 1.027 1.026l1.095-1.094a.725.725 0 0 0 0-1.026zM30.279 44.653a.726.726 0 0 0-1.027 0l-4.928 4.926a.725.725 0 1 0 1.026 1.026l4.929-4.926a.725.725 0 0 0 0-1.026z" />
        <path
          d="M51.105 0C22.87 0 0 22.871 0 51.105v268.49c0 28.235 22.871 51.104 51.105 51.104h268.29c28.235 0 51.105-22.869 51.105-51.104V51.105C370.5 22.87 347.629 0 319.395 0zm0 8.943h268.29c23.435 0 42.162 18.727 42.162 42.162v268.49c0 23.435-18.727 42.162-42.162 42.162H51.105c-23.435 0-42.162-18.727-42.162-42.162V51.105c0-23.435 18.727-42.162 42.162-42.162z"
          // stroke-linecap="round"
          // stroke-miterlimit="5.8"
          transform="scale(.1724 .17231)"
        />
      </g>
    </svg>
    //
    // <svg className={cn("rounded-lg border-2 border-brand fill-brand p-4", className)} viewBox="0 0 511 511">
    //   <g className="origin-center rotate-45 overflow-visible">
    //     <path
    //       d="M391.5,0c-4.142,0-7.5,3.358-7.5,7.5v120c0,4.687-3.813,8.5-8.5,8.5s-8.5-3.813-8.5-8.5V7.5c0-4.142-3.358-7.5-7.5-7.5   S352,3.358,352,7.5v120c0,4.687-3.813,8.5-8.5,8.5s-8.5-3.813-8.5-8.5V7.5c0-4.142-3.358-7.5-7.5-7.5S320,3.358,320,7.5v120   c0,4.687-3.813,8.5-8.5,8.5s-8.5-3.813-8.5-8.5V7.5c0-4.142-3.358-7.5-7.5-7.5S288,3.358,288,7.5v160   c0,12.958,10.542,23.5,23.5,23.5c4.687,0,8.5,3.813,8.5,8.5v73.409c-13.759,3.374-24,15.806-24,30.591v160   c0,26.191,21.309,47.5,47.5,47.5s47.5-21.309,47.5-47.5v-160c0-14.785-10.241-27.216-24-30.591V199.5c0-4.687,3.813-8.5,8.5-8.5   c12.958,0,23.5-10.542,23.5-23.5V7.5C399,3.358,395.642,0,391.5,0z M376,303.5v160c0,17.92-14.58,32.5-32.5,32.5   S311,481.42,311,463.5v-160c0-9.098,7.402-16.5,16.5-16.5h32C368.598,287,376,294.402,376,303.5z M375.5,176   c-12.958,0-23.5,10.542-23.5,23.5V272h-17v-72.5c0-12.958-10.542-23.5-23.5-23.5c-4.687,0-8.5-3.813-8.5-8.5v-18.097   c2.638,1.027,5.503,1.597,8.5,1.597c6.177,0,11.801-2.399,16-6.31c4.199,3.911,9.823,6.31,16,6.31s11.801-2.399,16-6.31   c4.199,3.911,9.823,6.31,16,6.31c2.997,0,5.862-0.57,8.5-1.597V167.5C384,172.187,380.187,176,375.5,176z" />
    //     <path
    //       d="M183.5,0c-20.479,0-38.826,11.623-51.663,32.728C118.86,54.064,112,84.07,112,119.5c0,25.652,13.894,49.464,36.26,62.144   c7.242,4.105,11.74,12.106,11.74,20.88v70.385c-13.759,3.374-24,15.806-24,30.591v160c0,26.191,21.309,47.5,47.5,47.5   s47.5-21.309,47.5-47.5v-160c0-14.785-10.241-27.216-24-30.591v-70.385c0-8.774,4.499-16.775,11.74-20.88   C241.106,168.964,255,145.152,255,119.5c0-35.43-6.86-65.436-19.837-86.772C222.326,11.623,203.979,0,183.5,0z M216,303.5v160   c0,17.92-14.58,32.5-32.5,32.5S151,481.42,151,463.5v-160c0-9.098,7.402-16.5,16.5-16.5h32C208.598,287,216,294.402,216,303.5z    M211.343,168.595C199.412,175.359,192,188.36,192,202.524V272h-17v-69.476c0-14.164-7.412-27.165-19.342-33.929   C137.981,158.574,127,139.762,127,119.5c0-32.68,6.104-59.99,17.653-78.978C154.809,23.826,168.242,15,183.5,15   s28.691,8.826,38.847,25.522C233.896,59.51,240,86.82,240,119.5C240,139.762,229.019,158.574,211.343,168.595z" />
    //     <path d="M191.5,304c-4.142,0-7.5,3.358-7.5,7.5v16c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-16   C199,307.358,195.642,304,191.5,304z" />
    //     <path d="M191.5,352c-4.142,0-7.5,3.358-7.5,7.5v72c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-72   C199,355.358,195.642,352,191.5,352z" />
    //     <path d="M351.5,304c-4.142,0-7.5,3.358-7.5,7.5v16c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-16   C359,307.358,355.642,304,351.5,304z" />
    //     <path d="M351.5,352c-4.142,0-7.5,3.358-7.5,7.5v72c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-72   C359,355.358,355.642,352,351.5,352z" />
    //   </g>
    // </svg>
  );
}
