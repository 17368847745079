import { JsonDataDump } from "@/components/JsonDataDump.tsx";
import { PlaceholderImage } from "@/components/PlaceholderImage.tsx";
import { RoleGuard } from "@/components/authentication/RoleGuard.tsx";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion.tsx";
import { ApplicationRole, ProductLinkDto, UnitSize } from "@/store/IRN.API.ts";
import { colorLerp, toCurrency } from "@/utils.ts";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export function ItemDetailsPanel({ productLink, showProductName }: { productLink: ProductLinkDto; showProductName?: boolean }) {
  console.log("ItemDetailsPanel", productLink);
  // const { brand, thumbnailUrl, description, mpn, name, size, unit, upc } = item;

  // Get the product that is the median price
  let priceRange = {
    min: productLink.products?.[0]?.price ?? 0,
    max: productLink.products?.[0]?.price ?? 0,
    median: productLink.products?.[0]?.price ?? 0,
  };
  const prices = productLink.products?.map((product) => product.price);

  if (prices && prices.length > 0) {
    prices.sort((a, b) => a - b);
    const min = prices[0];
    const max = prices[prices.length - 1];
    const median = prices[Math.floor(prices.length / 2)];

    priceRange = { min, max, median };
  }

  const displayImage =
    productLink.thumbnailUrl ||
    (productLink.imageUrls && productLink.imageUrls.length > 0 ? productLink.imageUrls?.[productLink.imageUrls.length - 1] : null) ||
    null;

  const getPriceDisplay = (price: number) => {
    if (price === 0) {
      return (
        <div className="flex items-center text-gray-500">
          <p className="mr-1">n/a</p>
        </div>
      );
    }

    // Interpolate the color from green (min), to yellow (median), to red (max)
    // Also show the % difference from the median
    // If the price is below the median, it will be green and negative, and show a "down" arrow
    // If the price is above the median, it will be red and positive, and show an "up" arrow
    const percentDifference = Math.round(((price - priceRange.median) / priceRange.median) * 100 * 10) / 10;
    const percentInRange = Math.round(((price - priceRange.min) / (priceRange.max - priceRange.min)) * 100);

    let interpolatedColor = colorLerp("#16a34a", "#dc2626", percentInRange / 100);
    if (percentDifference === 0) {
      interpolatedColor = "#4b5563";
    }
    return (
      <div style={{ color: interpolatedColor }} className="flex items-center">
        <p className="mr-1">{toCurrency(price)}</p>

        {percentDifference !== 0 && (
          <p className="text-xs">
            ({percentDifference < 0 ? "-" : "+"}
            {percentDifference < 0 ? -percentDifference : percentDifference}%)
          </p>
        )}
      </div>
    );
  };

  return (
    <div className="max-h-[75dvh] overflow-y-auto sm:overflow-hidden rounded-lg p-5 text-sm shadow-lg">
      {/*<p className="text-xs text-gray-400">TODO: This will be redesigned to be smaller, and show the information in a more compact way</p>*/}
      <div className="flex justify-around md:justify-between flex-wrap mb-5 md:mb-0">
        {showProductName && <p className="pb-2 text-lg font-semibold">{productLink.name || "No Name Provided"}</p>}
        {/* Ensure the text + icon is aligned in the middle horizontally */}
        <Link to={"/app/products/" + productLink.id} className="text-brand-600 flex items-center hover:underline">
          View Product
          {/*  HeroIcons 'external link' icon */}
          <ArrowTopRightOnSquareIcon className="h-4 w-4 inline-block ml-2" />
        </Link>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 content-center items-center justify-items-center">
        <object data={displayImage!} type="image/png" className="h-32 w-32 rounded object-fill">
          <PlaceholderImage className="h-32 w-32" />
          {/*<svg className="h-32 w-32 rounded" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">*/}
          {/*  <rect width="1000" height="1000" fill="#E6F4E4"></rect>*/}
          {/*  <path*/}
          {/*    d="M778.911 314.83C778.911 322.344 772.82 328.435 765.306 328.435L656.463 328.435C648.948 328.435 642.857 322.344 642.857 314.83C642.857 307.316 648.948 301.224 656.463 301.224L765.306 301.224C772.82 301.224 778.911 307.316 778.911 314.83Z"*/}
          {/*    fill="#56B146"*/}
          {/*  ></path>*/}
          {/*  <path*/}
          {/*    d="M765.306 382.857C772.82 382.857 778.911 376.766 778.911 369.252C778.911 361.738 772.82 355.646 765.306 355.646H710.884C703.37 355.646 697.279 361.738 697.279 369.252C697.279 376.766 703.37 382.857 710.884 382.857H765.306Z"*/}
          {/*    fill="#56B146"*/}
          {/*  ></path>*/}
          {/*  <path*/}
          {/*    fillRule="evenodd"*/}
          {/*    clipRule="evenodd"*/}
          {/*    d="M479.592 253.605C479.592 246.091 485.683 240 493.197 240H819.728C827.242 240 833.333 246.091 833.333 253.605V743.401C833.333 750.915 827.242 757.007 819.728 757.007H493.197C485.683 757.007 479.592 750.915 479.592 743.401V253.605ZM506.803 267.211V372.086L806.122 459.388V267.211H506.803ZM506.803 576.168V400.431L806.122 487.732V663.469L506.803 576.168ZM506.803 604.512V729.796H806.122V691.814L506.803 604.512Z"*/}
          {/*    fill="#56B146"*/}
          {/*  ></path>*/}
          {/*  <path*/}
          {/*    d="M452.369 729.797H438.764V430.49H452.369C455.977 430.49 459.438 429.057 461.989 426.505C464.54 423.954 465.974 420.493 465.974 416.885C465.974 413.277 464.54 409.816 461.989 407.265C459.438 404.714 455.977 403.28 452.369 403.28H403.582L434.791 372.098C437.346 369.543 438.781 366.078 438.781 362.466C438.781 358.853 437.346 355.388 434.791 352.833C432.237 350.279 428.772 348.844 425.159 348.844C421.546 348.844 418.081 350.279 415.527 352.833L365.107 403.28H180.272C176.663 403.28 173.203 404.714 170.651 407.265C168.1 409.816 166.667 413.277 166.667 416.885C166.667 420.493 168.1 423.954 170.651 426.505C173.203 429.057 176.663 430.49 180.272 430.49H193.876V729.797H180.272C176.663 729.797 173.203 731.23 170.651 733.782C168.1 736.333 166.667 739.794 166.667 743.402C166.667 747.01 168.1 750.471 170.651 753.022C173.203 755.573 176.663 757.007 180.272 757.007H452.369C455.977 757.007 459.438 755.573 461.989 753.022C464.54 750.471 465.974 747.01 465.974 743.402C465.974 739.794 464.54 736.333 461.989 733.782C459.438 731.23 455.977 729.797 452.369 729.797ZM221.086 484.909H411.554V675.378H221.086V484.909ZM411.554 430.49V457.7H221.086V430.49H411.554ZM221.086 729.797V702.587H411.554V729.797H221.086Z"*/}
          {/*    fill="#56B146"*/}
          {/*  ></path>*/}
          {/*  <path*/}
          {/*    d="M316.32 512.124C302.866 512.124 289.715 516.114 278.528 523.588C267.341 531.063 258.623 541.687 253.474 554.117C248.325 566.546 246.978 580.224 249.603 593.419C252.228 606.615 258.706 618.735 268.22 628.249C277.733 637.762 289.854 644.241 303.049 646.866C316.245 649.49 329.922 648.143 342.352 642.995C354.782 637.846 365.406 629.127 372.88 617.941C380.355 606.754 384.345 593.602 384.345 580.148C384.345 562.107 377.178 544.805 364.421 532.048C351.664 519.291 334.361 512.124 316.32 512.124ZM316.32 620.963C308.248 620.963 300.357 618.569 293.645 614.084C286.933 609.6 281.702 603.225 278.612 595.767C275.523 588.31 274.715 580.103 276.29 572.186C277.865 564.269 281.752 556.996 287.46 551.288C293.168 545.58 300.44 541.693 308.358 540.118C316.275 538.543 324.481 539.351 331.939 542.441C339.397 545.53 345.772 550.761 350.256 557.473C354.741 564.185 357.135 572.076 357.135 580.148C357.135 590.973 352.835 601.354 345.181 609.009C337.526 616.663 327.145 620.963 316.32 620.963Z"*/}
          {/*    fill="#56B146"*/}
          {/*  ></path>*/}
          {/*</svg>*/}
        </object>
        <p className="col-span-3 line-clamp-5 overflow-y-auto px-1 text-sm leading-tight text-gray-400">
          {productLink.description || "No description available"}
        </p>
      </div>
      <div className="grid gap-5 divide-y leading-tight">
        {/* Description list of the various information */}
        <dl className="mt-5 grid auto-cols-fr grid-flow-col gap-5 text-sm">
          <div>
            <dt className="font-medium text-gray-500">Brand</dt>
            <dd className="text-gray-900">{productLink.brand}</dd>
          </div>
          {productLink.mpn && (
            <div>
              <dt className="font-medium text-gray-500">MPN</dt>
              <dd className="text-gray-900">{productLink.mpn}</dd>
            </div>
          )}
          {productLink.upc && (
            <div>
              <dt className="font-medium text-gray-500">UPC</dt>
              <dd className="text-gray-900">{productLink.upc}</dd>
            </div>
          )}
          <div>
            <dt className="font-medium text-gray-500">Pack Size</dt>
            <dd className="text-gray-900">{productLink.unitSize}</dd>
          </div>
          {productLink.unit === UnitSize.Pound ? (
            <>
              <div>
                <dt className="font-medium text-gray-500">Average Weight</dt>
                <dd className="text-gray-900">{productLink.size} lb(s)</dd>
              </div>
            </>
          ) : (
            <>
              <div>
                <dt className="font-medium text-gray-500">Unit</dt>
                <dd className="text-gray-900">{productLink.unit}</dd>
              </div>
              <div>
                <dt className="font-medium text-gray-500">Size</dt>
                <dd className="text-gray-900">{productLink.size}</dd>
              </div>
            </>
          )}
        </dl>
        <div className="w-full">
          <p className="py-4">Distributor Pricing</p>
          <div className="grid grid-flow-row-dense grid-cols-5 gap-2 text-sm">
            {productLink.products?.map((product) => (
              <div key={product.id}>
                <p className="font-semibold tracking-wide text-gray-400">
                  {product.distributor?.name}
                  <RoleGuard roles={[ApplicationRole.Administrator, ApplicationRole.Developer, ApplicationRole.SuperAdministrator]}>
                    {" "}
                    - {product.distributor?.id}
                  </RoleGuard>
                </p>
                {getPriceDisplay(product.price)}
              </div>
            ))}
          </div>
        </div>
      </div>

      <RoleGuard roles={[ApplicationRole.Developer]}>
        <Accordion type="single" collapsible>
          <AccordionItem value="item-1">
            <AccordionTrigger className="mt-2 border-t text-sm font-bold">JSON Data</AccordionTrigger>
            <AccordionContent className="space-y-2">
              <p className="font-semibold">Product Data</p>
              <JsonDataDump data={productLink} />
              <p className="font-semibold">Price Range</p>
              <JsonDataDump data={priceRange} />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </RoleGuard>
    </div>
  );
}
