import { Log } from "@/Log.ts";
import { CheckIcon, LoadingSpinner } from "@/components/LoadingSpinner.tsx";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { uploadOrderGuideAsync } from "@/store/order-guide-upload/thunks";
import {
  incrementStep,
  selectUploadedToServer,
  selectUploadToServerError,
  setUploadedToServer,
} from "@/store/order-guide-upload/wizard/OrderGuideUploadWizard.slice";
import { useEffect } from "react";

export function UploadToServerStep() {
  const dispatch = useAppDispatch();
  const uploadedToServer = useAppSelector(selectUploadedToServer);
  const error = useAppSelector(selectUploadToServerError);

  useEffect(() => {
    dispatch(setUploadedToServer(false));
    if (!uploadedToServer) {
      Log.debug("Uploading order guide to server");
      dispatch(uploadOrderGuideAsync());
    }
  }, []);

  // When !error && uploadedToServer,
  // wait 3 seconds and then redirect to the next step
  useEffect(() => {
    if (!error && uploadedToServer) {
      const timer = setTimeout(() => {
        Log.debug("Redirecting to next step");
        dispatch(incrementStep());
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error, uploadedToServer]);

  return (
    <div className="grid w-full place-items-center pt-10">
      {error && <div className="text-red-500">{error}</div>}
      {!error && !uploadedToServer && (
        <>
          <LoadingSpinner size="3xl" />
          <h2 className="text-lg font-semibold">Uploading to server...</h2>
          <p>
            We are currently uploading your order guide to the server. This may take a few seconds, depending on the size of your file and your internet
            connection.
          </p>
          <p>You will be redirected to the next step once the upload is complete.</p>
        </>
      )}
      {!error && uploadedToServer && (
        <>
          <CheckIcon size="3xl" />
          <h2 className="text-lg font-semibold">Upload Complete</h2>
          <p>Your order guide has been uploaded to the server.</p>
          <p>You will be redirected to the next step momentarily.</p>
        </>
      )}
    </div>
  );
}
