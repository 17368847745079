import { useIsInRole } from "@/hooks";
import { appendDebugObjectData } from "@/store/Dev.slice";
import { useAppDispatch } from "@/store/Hooks.ts";
import { ApplicationRole } from "@/store/IRN.API.ts";
import { useEffect } from "react";

export function DevDataProvider({ data }: { data: any }) {
  const isDeveloper = useIsInRole(ApplicationRole.Developer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isDeveloper) {
      dispatch(appendDebugObjectData(data));
    }
  }, [data]);

  return null;
}
