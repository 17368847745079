import { b2cPolicies, msalPca } from "@/Security.ts";
import { AccountInfo, AuthenticationResult, AuthError, EventMessage, EventType, InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { ReactNode, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Logo } from "@/components/Logo.tsx";
import { LoadingSpinner } from "@/components/LoadingSpinner.tsx";

function ErrorComponent({ error }: { error: any }) {
  console.log("ErrorComponent", error);

  const errorText = error.message || error;

  if (errorText.includes("AADB2C90118")) {
    return (
      <div className="flex h-full items-center justify-center">
        <Card>
          <CardHeader>
            <CardTitle>Reset Password</CardTitle>
          </CardHeader>
          <CardContent className="flex max-w-2xl flex-col gap-5">
            <p>You have successfully reset your password. Please sign in with your new password.</p>
            <Button onClick={() => history.back()}>Go Back</Button>
          </CardContent>
        </Card>
      </div>
    );
  }

  if (errorText.includes("AADB2C90091")) {
    // User canceled sign up
    return (
      <div className="flex h-full items-center justify-center">
        <Card>
          <CardHeader>
            <CardTitle>Sign Up Canceled</CardTitle>
          </CardHeader>
          <CardContent className="flex max-w-2xl flex-col gap-5">
            <p>You have canceled the sign up process.</p>
            <Button onClick={() => history.back()}>Go Back</Button>
          </CardContent>
        </Card>
      </div>
    );
  }

  // The error is most likely a string, but it could also be an object with a message property
  // Display the error in a card in the middle of the screen via shadcn

  return (
    <div className="flex h-full items-center justify-center">
      <Card>
        <CardHeader>
          <CardTitle>Authentication Error</CardTitle>
        </CardHeader>
        <CardContent className="flex max-w-2xl flex-col gap-5">
          <pre className="whitespace-pre-line break-words text-red-500">{error.message || error}</pre>
          <Button onClick={() => history.back()}>Go Back</Button>
        </CardContent>
      </Card>
    </div>
  );
}

function LoadingComponent() {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-10">
      <Logo className="h-20 w-auto" />
      <h1 className="text-center text-3xl font-bold">Please wait while we log you in</h1>
      <LoadingSpinner size="6xl" />
    </div>
  );
}

/**
 * Compare the token issuing policy with a specific policy name
 * @param {object} idTokenClaims - Object containing the claims from the parsed token
 * @param {string} policyToCompare - ID/Name of the policy as expressed in the Azure portal
 * @returns {boolean}
 */
function compareIssuingPolicy(idTokenClaims: any, policyToCompare: string) {
  // eslint-disable-next-line no-prototype-builtins
  const tfpMatches = idTokenClaims.hasOwnProperty("tfp") && idTokenClaims["tfp"].toLowerCase() === policyToCompare.toLowerCase();
  // eslint-disable-next-line no-prototype-builtins
  const acrMatches = idTokenClaims.hasOwnProperty("acr") && idTokenClaims["acr"].toLowerCase() === policyToCompare.toLowerCase();
  return tfpMatches || acrMatches;
}

export function AuthWrapper({ children }: { children: ReactNode }) {
  useEffect(() => {
    const callbackId = msalPca.addEventCallback((event: EventMessage) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload &&
        "account" in event.payload &&
        event.payload.account
      ) {
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        const payload = event.payload as AuthenticationResult;
        if (compareIssuingPolicy(payload.idTokenClaims, b2cPolicies.names.editProfile)) {
          // retrieve the account from initial sign-in to the app
          const originalSignInAccount = msalPca
            .getAllAccounts()
            .find(
              (account: AccountInfo) =>
                account.idTokenClaims &&
                account.idTokenClaims.oid === (payload.idTokenClaims as any).oid &&
                account.idTokenClaims.sub === (payload.idTokenClaims as any).sub &&
                compareIssuingPolicy(account.idTokenClaims, b2cPolicies.names.signUpSignIn)
            );

          const signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          msalPca.ssoSilent(signUpSignInFlowRequest);
          return;
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow
         */
        if (compareIssuingPolicy(payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
          const signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            scopes: ["https://irnworks.onmicrosoft.com/api/user_access"],
          };
          msalPca.loginRedirect(signUpSignInFlowRequest);
          return;
        }

        // Otherwise, try to set the active account
        msalPca.setActiveAccount(event.payload.account);
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        const authError = event.error as AuthError;
        if (authError && authError.errorMessage.includes("AADB2C90118")) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
          };
          msalPca.loginRedirect(resetPasswordRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        msalPca.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [msalPca]);

  return (
    // <MsalProvider instance={msalPca}>
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} errorComponent={ErrorComponent} loadingComponent={LoadingComponent}>
      {children}
    </MsalAuthenticationTemplate>
    // </MsalProvider>
  );
}
