import { Skeleton } from "@/components/ui/skeleton.tsx";
import { cn } from "@/lib/utils.ts";
import { ProductLinkDto, SearchFacetDto, UnitSize, useSearchProductsMutation } from "@/store/IRN.API.ts";
import { toCurrency } from "@/utils.ts";
import clsx from "clsx";
import { Dispatch, SetStateAction, useEffect } from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../Tooltip.tsx";
import { ProductTableItemDetailsCell } from "./ProductTableItemDetailsCell.tsx";

export function ProductsTableListing({
  searchFacets,
  page,
  pageSize,
  setTotalProducts,

  filter,
  clearSearchFacetsOnFilterChange,
  actionsColumnHeader,
  actionsColumn,

  businessId,
}: {
  searchFacets: SearchFacetDto[];
  page: number;
  pageSize: number;
  setTotalProducts: Dispatch<SetStateAction<number>>;
  filter: string;
  clearSearchFacetsOnFilterChange?: boolean;
  actionsColumnHeader?: string;
  actionsColumn?: (product: any) => React.ReactNode;

  businessId: number;
}) {
  const [productsQueryTrigger, productsQuery] = useSearchProductsMutation();

  useEffect(() => {
    // console.log(searchFacets);
    productsQueryTrigger({
      businessId,
      search: filter,
      skip: (page - 1) * pageSize,
      take: pageSize,
      facets: clearSearchFacetsOnFilterChange && filter ? [] : searchFacets,
    });
  }, [page, pageSize, filter, searchFacets, clearSearchFacetsOnFilterChange]);

  useEffect(() => {
    if (productsQuery.data) {
      setTotalProducts(productsQuery.data?.totalCount || 0);
    }
  }, [productsQuery.data, setTotalProducts]);

  const getProductPriceRange = (product: ProductLinkDto) => {
    const prices: number[] = product.products?.map((p) => p.pricePerPiece ?? 0) || [];
    const sortedPrices = prices.sort((a, b) => a! - b!);

    return {
      min: sortedPrices[0],
      max: sortedPrices[sortedPrices.length - 1],
    };
  };
  const getProductCasePriceRange = (product: ProductLinkDto) => {
    const prices = product.products?.map((p) => p.price) ?? [];
    const sortedPrices = prices.sort((a, b) => a - b);

    return {
      min: sortedPrices[0],
      max: sortedPrices[sortedPrices.length - 1],
    };
  };

  const renderPriceDisplay = (
    priceRange: { min: number; max: number },
    hasMultiplePrices: boolean,
    product: ProductLinkDto,
    isSubscriptCasePrice: boolean = false,
  ) => {
    return (
      <p>
        <span className={cn(!isSubscriptCasePrice ? "text-base" : "text-xs text-gray-500")}>
          {toCurrency(priceRange.min)}
          {hasMultiplePrices && (
            <>
              <span className="text-xs text-gray-500">
                /{product.unit === UnitSize.Case || isSubscriptCasePrice ? "cs" : product.unit === UnitSize.Pound ? "lb" : "ea"}
              </span>
              {" - "}
              {toCurrency(priceRange.max)}
            </>
          )}
        </span>
        <span className="text-xs text-gray-500">
          /{product.unit === UnitSize.Case || isSubscriptCasePrice ? "cs" : product.unit === UnitSize.Pound ? "lb" : "ea"}
        </span>
      </p>
    );
  };

  return (
    <>
      <div className="">
        <div className="sm:mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Product
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Price
                    </th>
                    <th scope="col" className="py-3.5 pl-3 pr-4 text-sm font-semibold text-gray-900 sm:pr-0">
                      {actionsColumnHeader || "Actions"}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {productsQuery.isLoading &&
                    Array.from(Array(pageSize).keys()).map((i) => (
                      <tr key={i}>
                        <td className="whitespace-nowrap px-4 py-5 pr-3 text-sm sm:pl-0">
                          <Skeleton className="h-10 w-full" />
                        </td>
                        <td className="whitespace-nowrap px-4 py-5 pr-3 text-sm sm:pl-0">
                          <Skeleton className="h-10 w-full" />
                        </td>
                        <td className="whitespace-nowrap px-4 py-5 pr-3 text-sm sm:pl-0">
                          <Skeleton className="h-10 w-full" />
                        </td>
                        {/*<td className="whitespace-nowrap px-3 py-5 text-sm ">*/}
                        {/*  <Skeleton height={40} />*/}
                        {/*</td>*/}
                        {/*<td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0"></td>*/}
                      </tr>
                    ))}

                  {!productsQuery.isLoading && productsQuery.data && (!productsQuery.data.products || productsQuery.data.products.length === 0) && (
                    <tr>
                      <td colSpan={3} className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                        <div className="flex h-40 items-center justify-center text-gray-400">No products found</div>
                      </td>
                    </tr>
                  )}

                  {!productsQuery.isLoading &&
                    productsQuery.data &&
                    productsQuery.data.products &&
                    productsQuery.data.products.length > 0 &&
                    productsQuery.data?.products?.map((product) => {
                      const productPriceRange = getProductPriceRange(product);
                      const productCasePriceRange = getProductCasePriceRange(product);
                      const hasMultiplePrices = productPriceRange.min !== productPriceRange.max;
                      const hasNoPiecePrice = productPriceRange.min === productPriceRange.max && productPriceRange.min === 0;
                      const hasNoCasePrice = productCasePriceRange.min === productCasePriceRange.max && productCasePriceRange.min === 0;

                      return (
                        <tr key={product.id}>
                          <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                            <ProductTableItemDetailsCell item={product} />
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm ">
                            <Tooltip>
                              <TooltipTrigger className={clsx(hasNoPiecePrice && hasNoCasePrice && "cursor-default text-gray-400", "cursor-pointer")}>
                                <span className="font-semibold">
                                  {hasNoPiecePrice && hasNoCasePrice ? (
                                    <span className="text-gray-400">n/a</span>
                                  ) : (
                                    <div>
                                      {/* If there is a case price, but no per-piece price, just show the case price display as the default */}
                                      {/* Otherwise, show the per-piece price as the default, with the case price underneath */}

                                      {hasNoPiecePrice && !hasNoCasePrice ? (
                                        renderPriceDisplay(productCasePriceRange, hasMultiplePrices, product)
                                      ) : (
                                        <>
                                          {renderPriceDisplay(productPriceRange, hasMultiplePrices, product)}

                                          {product.unit !== UnitSize.Case && renderPriceDisplay(productCasePriceRange, hasMultiplePrices, product, true)}
                                        </>
                                      )}
                                    </div>
                                  )}
                                </span>
                              </TooltipTrigger>
                              {!hasNoPiecePrice ? (
                                <TooltipContent className="rounded border bg-white p-2 shadow">
                                  {product.products?.map((p) => (
                                    <div className="block" key={p.id}>
                                      <span className="text-xs">
                                        {p.distributor?.name} - {toCurrency(p.price)}
                                        <span className="text-xs text-gray-500">
                                          /{product.unit === UnitSize.Case ? "cs" : product.unit === UnitSize.Pound ? "lb" : "ea"}
                                        </span>
                                      </span>
                                    </div>
                                  ))}
                                </TooltipContent>
                              ) : null}
                            </Tooltip>
                          </td>
                          <td className="py-5 pl-3 text-center">
                            {actionsColumn?.(product)}
                            {/*<AddToOrderGuideDialog selectedItem={product}>*/}
                            {/*  <Button*/}
                            {/*    variant="no-bg"*/}
                            {/*    size="icon"*/}
                            {/*    className="text-brand-600 hover:text-brand-800"*/}
                            {/*  >*/}
                            {/*    <PlusCircleIcon className="h-7 w-7" aria-hidden="true" />*/}
                            {/*    <span className="sr-only">Add to an order guide</span>*/}
                            {/*  </Button>*/}
                            {/*</AddToOrderGuideDialog>*/}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              {/*<DataTablePagination table={table} />*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
