import { msalPca } from "@/Security.ts";
import { AlertDialogProvider } from "@/components/dialogs/confirmation-dialog/ConfirmationDialog.tsx";
import { Toaster } from "@/components/ui/sonner.tsx";
import { MsalProvider } from "@azure/msal-react";
import { Provider } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import "./App.css";
import { DevDataPane } from "./components/dev/DevDataPane.tsx";
import { TooltipProvider } from "./components/ui/tooltip.tsx";
import { store } from "./store/Store.ts";

import { HydrationRoot } from "@/components/hydration/HydrationRoot.tsx";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";

posthog.init(
  // new
  import.meta.env.VITE_POSTHOG_KEY,
  { api_host: import.meta.env.VITE_POSTHOG_HOST },
);

function AppPage() {
  const location = useLocation();

  useEffect(() => {
    posthog.capture("$pageview", {
      $current_url: location.pathname,
      $title: document.title,
    });
  }, [location]);

  return (
    <PostHogProvider client={posthog}>
      <Provider store={store}>
        {/*<PersistGate loading={null} persistor={persistor}>*/}
        <TooltipProvider delayDuration={250} skipDelayDuration={0}>
          <AlertDialogProvider>
            {/*<FloatingActionsButtonWrapper />*/}
            <DevDataPane />
            <Toaster
              expand={true}
              position="bottom-right"
              // richColors
              closeButton
              theme="light"
              richColors
              toastOptions={{
                duration: 5000,
              }}
            />
            <MsalProvider instance={msalPca}>
              <HydrationRoot>
                <Outlet />
              </HydrationRoot>
            </MsalProvider>
          </AlertDialogProvider>
        </TooltipProvider>
        {/*</PersistGate>*/}
      </Provider>
    </PostHogProvider>
  );
}

export const App = AppPage;
