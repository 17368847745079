import { Logo } from "@/components/Logo.tsx";
import { PublicFooter } from "@/components/layout/PublicFooter.tsx";
import { Link } from "react-router-dom";

export function TermsOfUse() {
  return (
    <>
      <div className="container py-10">
        <div className="flex w-full justify-center">
          <Link to="/">
            <Logo className="h-24" />
          </Link>
        </div>

        <article className="prose max-w-none lg:prose-xl ">
          <h1 className="font-semibold">TERMS OF USE</h1>
          <p>
            Welcome to the Independent Restaurant Network Inc. website (“Website”), an online community designed and offered by Independent Restaurant Network
            Inc. (IRN) and its affiliates to the independent restaurant owners of USA and worldwide. Please review the following terms and conditions concerning
            your rights and obligations as a member and a user of this Website, as well as our rights and obligations as a service provider and website owner.
            Please take the time to read these terms carefully.
          </p>
          <p>
            If you use this website by accessing, using, downloading any information and/or materials, you are agreeing to be bound by these Terms of Use
            without any modification or qualification. If you do not consider and terms, conditions, rules, policies, guidelines or practices as they are
            expressly set out in these Terms of Use, we invite you to stop using our service. If for any reason you are unable to meet all the conditions set
            forth under this agreement, or if you breach any of the Terms of Use contained herein, you can be no longer qualified to be a member of the
            community.
          </p>
          <p>
            IRN reserves the right to modify or change these Terms of Use at any time without giving prior notice. Significant changes shall be notified to you
            by posting an announcement on the Site. Your use of the website is subject to the most recent Terms of Use posted on the Site at such time. The most
            recent version can be reviewed by clicking &quot;Terms of Use&quot; hyperlink at the bottom of our Site pages. We invite you to review those Terms
            of Use frequently in case of minor changes.{" "}
          </p>
          <h3>1. USE OF THIS WEBSITE’S CONTENT</h3>
          <p>
            The entire content included in this site as, but not limited to, text, graphics or code, is copyrighted as a collective work under the United States
            and other copyright laws, and is the property of Independent Restaurant Network Inc. The collective work includes works that are licensed to
            Independent Restaurant Network Inc., Copyright 2019, INDEPENDENT RESTAURANT NETWORK INC. ALL RIGHTS RESERVED. You agree not to reproduce, duplicate,
            copy, sell, resell or exploit for any commercial purposes, use of this Website, access to this Website or any of the content accessible through this
            Website. You agree not to copy or post on any networked computer, broadcast in any media, or use, modify, distribute, publish, transmit or create
            derivative works of any material found on this Website for any public or commercial purposes. You are not permitted to modify any materials from
            this Website in any way. You agree to expressly identify IRN as the copyright owner for any material or information that you use and/or copy from
            this Website. This permission terminates automatically without notice if you breach these Terms of Use in any manner or to any extent. Upon
            termination, you will immediately destroy any downloaded or printed materials. Except as expressly provided above, nothing contained in this Website
            will be construed as conferring by implication, estoppel, or otherwise any license or right under any copyright, patent, trademark or other
            intellectual property right of IRN or any third party. Except as expressly provided above, nothing contained herein shall be construed as conferring
            any license or right under any IRN copyright and all contents on the Website are protected by copyright. Any product, process, data, information or
            technology on this website may be the subject of other intellectual property rights, all of which are reserved by IRN and its affiliates and are not
            licensed hereunder.
          </p>
          <h3>2. DEFINITIONS</h3>
          <p>The following terms shall have the following meaning throughout the Terms of Use.</p>
          <p>a. &quot;Agreement&quot; or &quot;this agreement&quot; refers to these Terms of Use.</p>
          <p>b. &quot;Applicable law&quot; refers to the laws currently in force in the State of New Jersey, USA, which shall govern this agreement.</p>
          <p>
            c. &quot;Site&quot; means this website, all related webpages and all related websites operated by affiliates or divisions of IRN but does not
            include any third party websites which are linked to or may link from this website whether or not such third party websites are used in connection
            with the Services.
          </p>
          <p>d. &quot;Service&quot; and &quot;Services&quot; refer to the programs offered by IRN and its approved vendors.</p>
          <p>e. &quot;Member&quot; refers to any restaurant that has joined our network.</p>
          <p>f. &quot;IRN&quot;, &quot;we&quot;, &quot;us&quot; shall refer to Independent Restaurant Network Inc. and all its affiliates and subsidiaries.</p>
          <p>
            g. &quot;IRN Parties&quot; includes IRN, its affiliates, subsidiaries, partners, service providers, suppliers and contractors and each of their
            respective officers, directors, agents, and employees.
          </p>
          <p>h. &quot;IRN Community&quot; means the community of IRN members.</p>
          <p>
            i. &quot;You&quot; and &quot;user&quot; shall refer to any person who visits and/or uses the Site and includes a member and its employees and
            agents.
          </p>
          <h3>3. USER CONDUCT</h3>
          <p>You agree to use the website for the purposes that it is intended and only in accordance with the terms of this agreement.</p>
          <p>
            You agree to not use this Website to violate or solicit the violation of any applicable local, state, national or international law; infringe the
            rights of any third party, including, but not limited to, intellectual property rights and privacy or publicity rights; or interfere with or disrupt
            this Website, computer servers or boards accessible through this Website. If you do use this Website to commit any of the above, IRN may, at its
            sole discretion, terminate your ability to use this Website.
          </p>
          <p>
            You assume sole responsibility for and shall ensure that any information, data, documents or materials used, disclosed, entered into or created
            using the website or in connection with it is accurate, reliable and complete and does not violate our content guidelines. You represent and warrant
            that you have obtained all required consents and comply with all applicable legislation, including without limitation privacy legislation, in
            connection with any use and disclosure of information relating to the use of the website. IRN accepts no responsibility for the accuracy of any
            information, data, documents or materials entered into or created using the website except as otherwise set out herein. The input, posting or
            uploading of any information and the storage of any information, data, documents or materials on the website by us does not constitute our
            endorsement nor warranty as to the compliance of such information or materials with applicable legislation, nor to the accuracy, timeliness,
            materiality, completeness, or reliability of such information or materials.{" "}
          </p>
          <h3>4. MODIFICATION AND TERMINATION OF WEBSITE</h3>
          <p>
            IRN reserves the right, without notice or liability, at any time and occasionally, to modify or discontinue, temporarily or permanently, any aspect,
            mode or design of this Website (or any part of it) which include but not limited to the scope of service, time of service, or to the
            software/hardware required for access to the website. IRN may also limit the geographic locations or jurisdictions where certain Services may be
            available.
          </p>
          <p>
            Without prejudice to the foregoing, should there be a failure or error, omission, defect, deficiency, delay causing downtime, or inability of a
            member to access the website for any length of time, including as a result of the permanent termination of service, the member acknowledges and
            agrees that, except as otherwise set out herein, their only remedy for any error, omission, defect, deficiency, delay or other failure of the
            website is to discontinue using it.
          </p>
          <p>
            You agree that IRN shall not be liable to you or to any third party for any modification, suspension, or discontinuance of this Website. However,
            IRN disclaims any responsibility to update, improve, or change this Website.
          </p>
          <h3>5. OTHER WEBSITES AND RESOURCES</h3>
          <p>
            Third parties using this Website may provide links or access to other websites and resources. Because Independent Restaurant Network Inc. has no
            control over such websites and resources, you acknowledge and agree that Independent Restaurant Network Inc. is not responsible for the availability
            of such sites or resources and does not endorse and is not responsible or liable for any content, advertising, products or other materials on or
            available from such sites or resources. You further acknowledge and agree that Independent Restaurant Network Inc. shall not be responsible or
            liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such
            content, goods or websites available on or through any such site or resource.
          </p>
          <h3>6. SOFTWARE NOTICE</h3>
          <p>
            Should a member need to download or use software in connection with the website, he or she shall be unable to download and access such software
            unless he or she first agrees to the license agreement relating to such software. Use of any such software is governed by these Terms of Use and any
            such license agreement.
          </p>
          <h3>7. USE OF COOKIES</h3>
          <p>
            Although acceptance of cookies is not required to visit this Website, we do use cookies. Cookies are small text files stored on computer hard drives
            and are regularly used to analyze individual Website activity. By collecting and remembering information about your visit to a website, cookies
            allow web applications to recognize and respond to your individual preferences. We thus can estimate the usefulness of information provided to our
            members and users and to analyze the effectiveness of our navigational structure in providing you with that information. They help us provide you
            with a better website experience. They allow the website to respond to you as an individual and to tailor a website experience to your preferences.
            If you prefer not to receive cookies on this Website, you can set your browser to warn you before accepting cookies and you can refuse the cookie
            when your browser alerts you to its presence. You can also refuse all cookies by turning them off in your browser or delete cookies that have
            already been stored on your computer. If you have set your browser not to allow cookies, you will still be able to access this Website, provided
            that doing so may affect your perceived usability of this Website. Analytics Tools: This website uses Google Analytics, a web analytics service
            provided by Google, Inc. (“Google”). Google Analytics uses “cookies”, which are text files placed on your computer, to help the website analyze how
            users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and
            stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the website, compiling
            reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also
            transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf.
            Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate
            settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. By using this
            website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.
          </p>
          <h3>8. Copyrights, Trade-Marks and Intellectual Property</h3>
          <p>
            IRN and its licensors and suppliers own both the proprietary rights as well as the intellectual property rights to all URLs, materials, products,
            web content, webpage designs, webpage layouts, images, text, tools, utilities and software that make up the website, but excluding your data and
            information which you provide to us or input using the website. The usage of our website does not constitute a sale or transfer of any intellectual
            property rights to the users. Without any prejudice to the foregoing, any information or data entered using the website by a member or otherwise
            provided for accessing the website on the user&#39;s behalf shall at all material times remain the property of the member. The member hereby grants
            to IRN a worldwide, perpetual, royalty free, non exclusive right and license to use all content provided by the member in connection with the
            website related to the delivery of the Services.
          </p>
          <p>
            Materials on and relating to the website are protected by copyright, trade-mark and other intellectual property laws. Subject to your ownership of
            your member content and data, IRN reserves all rights in and to such materials. The member will not make store, download, transfer, sell, reproduce,
            redistribute, transfer to any other server, modify, reverse engineer or copy the Services or any of the materials or software or any part of the
            website or any content there from without IRN&#39;s express written consent. You will also take all reasonable steps to forestall any unauthorized
            use, copying or transfer of materials on or relating to the website.
          </p>
          <p>
            IRN has rights to several trade-marks which it uses in connection with the operation of its services and the use of its website. IRN does not grant
            the members and the users any right or license to use the IRN trade-marks or any logo, trade-name or other intellectual property other than as
            expressly set out herein and in other licenses between you and us.
          </p>
          <h3>9. Prohibited Use</h3>
          <p>
            The users shall access the information stored using the website for lawful purposes only and may not use such information for the purpose of
            committing or furthering fraudulent acts or for committing any acts that would give rise to both or either civil and criminal liability.The users
            agree not to publish, post, upload, distribute, provide or enter any material or information that is illegal, unlawful or can be regarded as
            fraudulent, libelous, malicious, threatening, offensive, profane, obscene, fanning ethnic or racial tensions, immoral or any such information which
            any reasonable person would consider objectionable on grounds of good conscience.
          </p>
          <p>No user shall use any means to restrict or prevent another user from accessing or enjoying the IRN website.</p>
          <p>
            No user shall be permitted to upload material onto the website that he or she ought to know infringes on the intellectual property of others, or
            upload material that places unnecessary load as to affect the performances of our websites, systems and equipment. You may not use the website in a
            manner which could block access to, impair, damage or otherwise disable it or any of our servers. You will not upload any files that contain
            viruses, Trojan horses, malware, spyware, worms, corrupted files or any other material or software that may damage the operation of another
            computer. Any and all materials uploaded are subject to applicable laws.
          </p>
          <p>
            No user shall lease, sell, pledge, sublicense, assign or otherwise deal with the software belonging to IRN in a manner that is inconsistent with our
            intellectual property rights over the software.
          </p>
          <p>
            No user shall promote any commercial interest, falsify or delete any information on the IRN website, collect personal information without express
            authority to do so, violate any applicable laws, create a false identity or utilize the website under false pretences.
          </p>
          <h3>10. DISCLAIMER OF WARRANTIES</h3>
          <p>
            Information accessed on this Website is not exhaustive. IRN makes reasonable efforts to include accurate and current information, whenever possible,
            but makes no warranties or representations as to its accuracy or completeness. It is provided “as is” without any kind of warranty, either expressed
            or implied, statutory or otherwise, including but not limited to the implied warranty of fitness for a particular purpose, non-infringement and
            merchantability.
          </p>
          <p>
            You are solely responsible for using this website which is provided on an ‘as is’ and ‘as available’ basis. IRN expressly disclaims all warranties
            of any kind, express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose and
            non-infringement. IRN makes no kind of warranty that our website will meet your needs, that it will be without any kind of interruption due to time,
            security and errors and that the information within it is true and reliable. You are solely responsible for any type of material obtained in any way
            through the use of our website and IRN holds no type of responsibility for any damage to your computer system or data loss resulting from that
            material. IRN has no kind of warranty for any advice or information obtained by you through or from this website.
          </p>
          <h3>11. LIMITATION OF LIABILITY</h3>
          <p>
            The members and any users by means of this accept to release, remise and forever discharge IRN and its parties from any and all manner of right,
            losses, costs, claims, complaints, demands, debts, damages, causes of action, proceedings, liabilities, obligations, legal fees, costs and
            disbursements of any nature whatsoever, including without limitation any lost profit, indirect, consequential, exemplary, incidental, special or
            punitive damages [aka a claim] whether known or unknown, which now or hereafter arise from, relate to, and/or are connected with this agreement, the
            use of the IRN website and services provided by IRN or by a third party, the use of any software within the website, viruses, spyware, service
            provider failures or internet access interruptions, loss of use, of data, error, inaccuracy of data, identity theft, fraud or unauthorized access or
            any information, document, materials, tools, utilities, data related to the use of the website, goodwill or other intangible losses; whether such
            claim is based on contract or tort or otherwise, even if we have been advised of the possibility of such claim or such claim was reasonably
            foreseeable and notwithstanding the sufficiency or insufficiency of any remedy provided for herein or in any license.
          </p>
          <p>
            Under no circumstances shall we and our collaborators be liable to you or any third party for any lost profit or any direct or indirect,
            consequential, exemplary, incidental, special or punitive damages arising from or relating to this agreement or your use of or inability to use this
            site and its services, even if we have been advised of the possibility of such damages.
          </p>
          <p>
            Access to and use of our website and its services are at your discretion and risk and you will be solely responsible for any damage, misuse, error
            or loss of data arising from such access and use.
          </p>
          <p>
            Notwithstanding anything to the contrary contained herein, our liability to you for any damages arising from and/or related to this agreement for
            any cause whatsoever and regardless of the form of the action shall at all times be limited to twenty (20) US dollars. The existence of more than
            one claim will not enlarge this limit. You consent that our collaborators will have no liability of any kind from or relating to this agreement.{" "}
          </p>
          <h3>12. EXCLUSIONS AND LIMITATIONS</h3>
          <p>
            There are jurisdictions which do not allow certain warranties or liability for incidental or consequential damages to be excluded. In consequence,
            some limitations might not be applicable to you.
          </p>
          <h3>13. Ownership and Disclosure of Information</h3>
          <p>
            You are the exclusive owner of all of your business&#39;s private data, content and all information which you enter and use in connection with the
            website. We do not claim any rights, proprietary or otherwise over any data or information which you may use or disclose in connection with the
            website and its services. However, there may be circumstances in which we may be required to disclose data, such as the following:
          </p>
          <p>a. For the purposes of fraud prevention and law enforcement;</p>
          <p>b. To comply with any legal, governmental or regulatory requirement;</p>
          <p>c. To our lawyers in connection with any legal proceedings; and</p>
          <p>d. To comply with a court order.</p>
          <p>
            If we are required to disclose your data or information under the above circumstances, we will use our best efforts to provide you with reasonable
            notice in the circumstances and if appropriate the right to challenge any such request. IRN’s Privacy Policy sets out in greater detail how we
            strive to protect your privacy and limit disclosure of your personal information.
          </p>
          <h3>14. Third Party Links, APIs and Content</h3>
          <p>
            In case you are directed to websites maintained by other third party service providers while using our website and Services, we hold no
            responsibility for your use, ability or inability to use them or any of the content of such sites. In addition, we may at any time in our discretion
            and without notice to you, discontinue providing our API to such third party services. You acknowledge that such sites and services are completely
            independent of IRN and we have no control over them. We accept no liability in respect of your use of the products and services offered by such
            third party services providers will be at your sole risk. You acknowledge that use of such third party service providers and their websites and
            services is, except where prohibited or modified by applicable law, subject to the terms, conditions and policies established by the third party
            service providers. You expressly hold us harmless and hereby release us from any liability whatsoever whether arising out of contract, tort or
            otherwise for and from any claims (defined below) arising out of your use of, or inability to use, the products and services of third party service
            providers whether or not such use is ancillary to your use of our website. The availability of such third party services in connection with the use
            of our website does not constitute endorsement, warranty, or representation as to the fitness, suitability, merchantability, title,
            non-infringement, quality, or accuracy of the third party provider or its products or services.
          </p>
          <p>
            If at any point such third party services cease to make themselves or their APIs available to us on reasonable terms, we may cease to provide those
            third party services without entitling you to a refund, credit or other compensation. Your only recourse shall be to stop using the website and
            services. In addition, if you authorize us to do so, we may grant third parties access to some or all (depending on the permission you give) of your
            private data, content and information through our own API for use in connection with their services.
          </p>
          <h3>15. Authorization to Export Data</h3>
          <p>
            Members and users acknowledge that we may retain a copy of his/her/its transactional information gathered through the use of the website. We may in
            certain circumstances export user information outside the State of New Jersey and outside of USA. We shall not share personal information with
            anyone except in the manner provided in our Privacy Policy.
          </p>
          <h3>16. Force Majeure</h3>
          <p>
            Neither party should be held liable for a delay or failure in performance of the agreement for services caused by reason of any occurrence of
            unforeseen event beyond its reasonable control, including but not limited to, natural disasters, power failures, server failures, third party
            service provider failures or service interruptions, embargo, labor disputes, lockouts and strikes, riots, war, floods, insurrections, legislative
            changes, and governmental actions. The party so affected by the unforeseen event shall be so excused on a day-to-day basis for the period of time
            equal to that of the underlying cause of delay.
          </p>
          <h3>17. Severance</h3>
          <p>
            All provisions of these Terms of Use are, notwithstanding the manner in which they have been grouped together or linked grammatically, severable
            from each other. If any of these Terms of Use should be determined to be unenforceable the remaining Terms of Use shall survive and remain in full
            force and effect and continue to be binding and enforceable.
          </p>
          <h3>18. Governing Law and Dispute Resolution</h3>
          <p>
            Except as otherwise set out herein, this Agreement shall in all respects be governed by and interpreted, construed and enforced in accordance with
            the laws of the State of New Jersey and the laws of the USA applicable therein as applied to agreements entered into and to be performed entirely
            within New Jersey, without regard to its choice of law or conflicts of law principles that would require application of law of a different
            jurisdiction. This agreement and any actions whatsoever taken by you in connection herewith and with any Service, software, tool, application or
            functionality, will be deemed to have been performed in New Jersey, USA. The parties hereto irrevocably submit and attorn to the exclusive
            jurisdiction and venue of the courts of the New Jersey, USA.
          </p>
          <p>
            You waive all rights to a trial by jury in connection with any legal proceeding or dispute against us. You further agree that any dispute or
            proceeding which you may bring against us shall be conducted on an individual basis and not a class-wide basis and that any such proceeding or
            dispute shall not be consolidated with any other dispute or proceeding which might arise between us and any other user.
          </p>
          <p>
            Any dispute shall be resolved by mediation either physical or online. The mediator shall be recommended by IRN and, should you comply with this
            recommendation, he/she will conduct the mediation process.
          </p>
          <h3>19. Legal Status of Independent Restaurant Network Inc.</h3>
          <p>
            The websites www.irnworks.com, www.irnnow.com and the names IRN and IRN community are property of Independent Restaurant Network Inc. a company
            incorporated under the New Jersey Business Corporation Act.
          </p>
          <p>
            <em>
              Our full name is Independent Restaurant Network and we’re a private company. We’re based in New Jersey and whatever is related to us (websites and
              names) we own.
            </em>
          </p>
          <h3>20. Language/LANGUE/IDIOMA</h3>
          <p>
            You and we expressly acknowledge and agree that this agreement and all related agreements, schedules, materials, licenses and policies be drafted in
            the English language only. Il est convenu que cette convention et tous les documents, annexes, licences et politiques s&#39;y rattachant soient
            rédigés en anglais. Estamos de acuerdo que este contrato y todos sus acuerdos, textos, materiales, licencias y normas esten escritos solo en ingles.
          </p>
          <p>
            <em>Our language of work is English.</em>
          </p>
          <h3>21. Consumer Issues</h3>
          <p>
            IRN is strictly addressed to businesses and its software and whole construction, conception and idea are business related. You agree to use the
            website for business purposes only and not for individual consumer, household or domestic purposes.
          </p>
          <h3>22. No Waiver</h3>
          <p>
            Should IRN take any form of delay or omit to exercise any right or remedy accruing upon any breach or default hereunder, nor such right or remedy
            shall be impaired nor be construed as a waiver of any such breach or default or of any similar breach or default thereafter occurring, nor shall any
            single or partial exercise thereof preclude any other or further exercise thereof or the exercise of any other right or remedy. No waiver (or
            consecutive waivers) of any single breach or default shall operate or be construed as a waiver of any subsequent breach or default.
          </p>
          <h3>23. INDEMNITY</h3>
          <p>
            You agree to indemnify and hold Independent Restaurant Network Inc., and its shareholders, subsidiaries, affiliates, officers, managers, agents,
            co-branders or other partners, and employees, harmless from and against any claim or demand, including reasonable attorneys’ fees, made by any third
            party because of or arising out of your use of this Website, your violation of these Terms of Use, or your violation of any rights of another person
            or entity.
          </p>
          <h3>24. NOTICES</h3>
          <p>
            Notices to you may be made via either email or regular mail. Independent Restaurant Network Inc. may also provide notices of changes to these Terms
            of Use or this Website by displaying notices or links to notices to you generally on this Website.
          </p>
          <h3>25. MODIFICATION OF AGREEMENT</h3>
          <p>
            It is to our discretion to amend these Terms of Use at any time without prior notification by posting the amended terms on this Website. You are
            invited to review regularly the Terms of Use. All amendments shall automatically be effective twenty (20) days after they are initially posted on
            this Website. Your use of this Website following the effective date of any amendment to the Terms of Use shall constitute your binding acceptance of
            that amendment.
          </p>
          <h3>26. GENERAL</h3>
          <p>
            The present Terms of Use constitute the entire agreement between you and IRN with respect to this Website and supersedes all prior agreements and
            understandings between you and IRN with respect to this Website. IRN’s failure to enforce any provision of these Terms of Use will not be construed
            as a waiver of any provision, right, or remedy. Should a part of these Terms of Use be held unenforceable, it will be construed in accordance with
            applicable law as nearly as possible to reflect the original intentions of the parties, and the remainder of the provisions will remain in full
            force and effect. Sections 1, 3, 8, 10, 11, 16, 8, 19, 22 and 23 of this Terms of Use shall survive any termination of this Website or this Terms of
            Use.
          </p>
        </article>
      </div>
      <PublicFooter />
    </>
  );
}
