import { TableCell, TableRow } from "@/components/ui/table.tsx";
import { OrderStatusBadge } from "@/pages/app/orders/utils.tsx";
import { BusinessDto, DistributorDto, OrderDetailsDto, OrderStatus } from "@/store/IRN.API.ts";
import { ReactNode } from "react";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../ui/card";
import { Table, TableBody, TableHead, TableHeader } from "../ui/table";

export function OrderDetailsSidebarSummary(props: {
  orderId: string;
  createdAt: string;
  status: OrderStatus;
  business: BusinessDto;
  order: OrderDetailsDto;
  orderItemsByDistributor: {
    [key: string]: {
      id: number;
      quantity: number;
      product: {
        name: string;
        price: number;
        distributor: DistributorDto;
      };
    }[];
  };
  children?: ReactNode;
}) {
  // Calculate the total owed. This should only include products from distributors who are owned by business ID = 1
  const totalDueToIrn = Object.keys(props.orderItemsByDistributor)
    .filter((distributorName) => props.orderItemsByDistributor[distributorName][0].product.distributor.ownedByBusinessId === 1)
    .reduce((acc, distributor) => acc + props.orderItemsByDistributor[distributor].reduce((acc, item) => acc + item.quantity * item.product.price, 0), 0);

  return (
    <>
      <div className="w-full max-w-2xl mx-auto p-4 md:p-6 lg:p-8">
        <Card className="bg-white">
          <CardHeader>
            <CardTitle>Order Summary</CardTitle>
            <CardDescription>
              Order #{props.order.orderId} -{" "}
              <time dateTime={new Date(props.createdAt).toLocaleDateString()}>{new Date(props.createdAt).toLocaleDateString()}</time>
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex items-center gap-4">
              <h3 className="font-semibold text-lg">Order Status:</h3>
              <OrderStatusBadge status={props.status} />
              {/*<Badge className="items-center" variant="solid">*/}
              {/*  <CheckIcon className="h-3.5 w-3.5 -translate-x-1" />*/}
              {/*  Completed*/}
              {/*</Badge>*/}
            </div>
            <div className="flex items-start gap-4 mt-4">
              <h3 className="font-semibold text-lg">Shipping Address:</h3>
              <address className="text-sm">
                {props.business?.address?.careOf ? (
                  <span className="block">C/O {props.business?.address?.careOf}</span>
                ) : (
                  <span className="block">{props.business?.operatingDbaName}</span>
                )}
                <span className="block">{props.business?.address?.address1}</span>
                {props.business?.address?.address2 && <span className="block">{props.business?.address?.address2}</span>}
                <span className="block">
                  {props.business?.address?.city}, {props.business?.address?.state} {props.business?.address?.zipCode}
                </span>
              </address>
            </div>
            <div className="mt-6">
              <h3 className="font-semibold text-lg mb-2">Distributor Breakdown:</h3>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Distributor</TableHead>
                    <TableHead>Quantity</TableHead>
                    <TableHead className="text-right">Total</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {/*<TableRow>*/}
                  {/*  <TableCell className="font-medium">Distributor A</TableCell>*/}
                  {/*  <TableCell>10</TableCell>*/}
                  {/*  <TableCell className="text-right">$200.00</TableCell>*/}
                  {/*</TableRow>*/}
                  {/*<TableRow>*/}
                  {/*  <TableCell className="font-medium">Distributor B</TableCell>*/}
                  {/*  <TableCell>5</TableCell>*/}
                  {/*  <TableCell className="text-right">$150.00</TableCell>*/}
                  {/*</TableRow>*/}
                  {/*<TableRow>*/}
                  {/*  <TableCell className="font-medium">Distributor C</TableCell>*/}
                  {/*  <TableCell>8</TableCell>*/}
                  {/*  <TableCell className="text-right">$160.00</TableCell>*/}
                  {/*</TableRow>*/}

                  {Object.keys(props.orderItemsByDistributor).map((distributorName: string) => (
                    <TableRow key={distributorName}>
                      <TableCell className="font-medium">{distributorName}</TableCell>
                      <TableCell>{props.orderItemsByDistributor[distributorName].reduce((acc, item) => acc + item.quantity, 0)}</TableCell>
                      <TableCell className="text-right">
                        {props.orderItemsByDistributor[distributorName]
                          .reduce((acc, item) => acc + item.quantity * item.product.price, 0)
                          .toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </CardContent>
          <CardFooter className="flex flex-col w-full">
            <div className="flex items-center justify-between w-full">
              <h4 className="font-semibold text-lg">Total:</h4>
              <h4 className="font-semibold text-lg">
                {Object.keys(props.orderItemsByDistributor)
                  .reduce(
                    (acc, distributor) => acc + props.orderItemsByDistributor[distributor].reduce((acc, item) => acc + item.quantity * item.product.price, 0),
                    0,
                  )
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
              </h4>
            </div>

            <div className="flex items-center justify-between w-full">
              <h3 className="font-semibold text-xl text-brand">Total due to IRN:</h3>
              <h3 className="font-semibold text-xl text-brand">
                {totalDueToIrn.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </h3>
            </div>

            <div className="mt-10 w-full">{props.children}</div>
          </CardFooter>
        </Card>
      </div>
      {/*<aside className="bg-white p-4 shadow rounded-lg flex flex-col gap-5 min-w-96 text-sm">*/}
      {/*  <div>*/}
      {/*    <p className="text-gray-500">Order number</p>*/}
      {/*    <p className="font-medium text-gray-900">{props.orderId}</p>*/}
      {/*  </div>*/}

      {/*  <div>*/}
      {/*    <p className="text-gray-500">Date</p>*/}
      {/*    <time dateTime={new Date(props.createdAt).toLocaleDateString()}>{new Date(props.createdAt).toLocaleDateString()}</time>*/}
      {/*  </div>*/}

      {/*  <div>*/}
      {/*    <span className="sr-only mx-2">Status</span>*/}
      {/*    <OrderStatusBadge status={props.status} />*/}
      {/*  </div>*/}

      {/*  {props.status !== OrderStatus.NotPlaced && (*/}
      {/*    <div className="mt-4 sm:mt-0">*/}
      {/*      <a href="#" className="font-medium text-brand-600 hover:text-brand-500">*/}
      {/*        View invoice*/}
      {/*        <span aria-hidden="true"> &rarr;</span>*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*  )}*/}

      {/*  <div>*/}
      {/*    <p className="font-medium text-gray-900">Shipping address</p>*/}
      {/*    <address className="text-gray-500">*/}
      {/*      {props.business?.address?.careOf && <span className="block">C/O {props.business?.address?.careOf}</span>}*/}
      {/*      <span className="block">{props.business?.address?.address1}</span>*/}
      {/*      {props.business?.address?.address2 && <span className="block">{props.business?.address?.address2}</span>}*/}
      {/*      <span className="block">*/}
      {/*        {props.business?.address?.city}, {props.business?.address?.state} {props.business?.address?.zipCode}*/}
      {/*      </span>*/}
      {/*    </address>*/}
      {/*  </div>*/}

      {/*  <div>*/}
      {/*    <p className="font-medium text-gray-900">Total</p>*/}
      {/*    <p className="text-gray-500">{Object.keys(totalsByDistributor!).length} distributors</p>*/}

      {/*    <p className="font-medium text-gray-900">*/}
      {/*      {Object.keys(totalsByDistributor!)*/}
      {/*        .reduce((acc, distributor) => acc + totalsByDistributor![distributor], 0)*/}
      {/*        .toLocaleString("en-US", {*/}
      {/*          style: "currency",*/}
      {/*          currency: "USD",*/}
      {/*        })}*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*</aside>*/}
    </>
  );
}
