import { ItemDetailsPanel } from "@/components/order-guide/ItemDetailsPanel.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Drawer, DrawerClose, DrawerContent, DrawerFooter, DrawerHeader, DrawerTitle, DrawerTrigger } from "@/components/ui/drawer.tsx";
import { PriceDisplay } from "@/components/ui/price-display.tsx";
import { useScreenBreakpoint } from "@/hooks";
import { OrderGuideItemDto } from "@/store/IRN.API.ts";
import { Float } from "@headlessui-float/react";
import { Popover } from "@headlessui/react";

export function OrderGuideItemListingItemInfoBlock({ item: { id, productLink } }: { item: OrderGuideItemDto; price: number }) {
  const currentBreakpoint = useScreenBreakpoint();
  return (
    <>
      {" "}
      {currentBreakpoint === "phone" && (
        <Drawer>
          <DrawerTrigger asChild>
            <div className="flex flex-col text-left focus-visible:outline-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-4">
              <div className="text-xs text-gray-500">
                {productLink?.brand}

                <div className="hidden text-xs uppercase md:ml-4 md:inline-block">
                  {productLink?.unitSize && <div className="text-gray-500 dark:text-gray-400">Size: {productLink?.unitSize}</div>}
                  {/*{item.productLink?.upc && <div className="hidden text-sm text-gray-500 dark:text-gray-400 md:block">UPC: {item.productLink?.upc}</div>}*/}
                  {/*{item.productLink?.mpn && <div className="hidden text-sm text-gray-500 dark:text-gray-400 md:block">MPN: {item.productLink?.mpn}</div>}*/}
                </div>
              </div>
              <div className="">{productLink?.name}</div>
              <div className="flex justify-between">
                <div>
                  <div className="text-base font-semibold">
                    <PriceDisplay price={productLink?.products?.[0]?.price ?? 0} />
                    {/*~{toCurrency(item.productLink?.products?.[0].price)}*/}
                  </div>
                  <div className="flex justify-between md:hidden">
                    {productLink?.unitSize && <div className="text-sm text-gray-500 dark:text-gray-400">Size: {productLink?.unitSize}</div>}
                    {/*{item.productLink?.upc && <div className="hidden text-sm text-gray-500 dark:text-gray-400 md:block">UPC: {item.productLink?.upc}</div>}*/}
                    {/*{item.productLink?.mpn && <div className="hidden text-sm text-gray-500 dark:text-gray-400 md:block">MPN: {item.productLink?.mpn}</div>}*/}
                  </div>
                </div>
              </div>

              {/*<div className="text-sm text-gray-500 dark:text-gray-400">SKU: {item.productLink?.upc ?? item.productLink?.mpn}</div>*/}
              {/*<div>Price: {toCurrency(item.productLink?.products?.[0].price)}</div>*/}
              {/*/!*<div>Category: Beverages</div>*!/*/}
              {/*/!*<div>Subcategory: Soft Drinks</div>*!/*/}
              {/*<div>Total Price: {toCurrency((item.productLink?.products?.[0].price || 0) * item.quantity)}</div>*/}

              {/*<div className="text-xs">*/}
              {/*  <div>select/input</div>*/}
              {/*  <div>delete</div>*/}
              {/*  <div>edit par</div>*/}
              {/*</div>*/}
            </div>
          </DrawerTrigger>

          <DrawerContent>
            <div className="mx-auto w-full max-w-sm">
              <DrawerHeader>
                <DrawerTitle>{productLink?.name}</DrawerTitle>
              </DrawerHeader>

              <ItemDetailsPanel productLink={productLink!} key={"details_panel" + productLink?.id} />
              <DrawerFooter>
                <DrawerClose asChild>
                  <Button>Close</Button>
                </DrawerClose>
              </DrawerFooter>
            </div>
          </DrawerContent>
        </Drawer>
      )}
      {currentBreakpoint !== "phone" && (
        <Popover className="w-full flex flex-1" key={"details_popover" + id}>
          <Float
            portal
            autoPlacement
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
            tailwindcssOriginClass
          >
            <Popover.Button
              key={"details_panel_btn" + id}
              className="flex flex-col text-left focus-visible:outline-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-4"
            >
              <>
                <div className="text-xs text-gray-500">
                  {productLink?.brand}

                  <div className="hidden text-xs uppercase md:ml-4 md:inline-block">
                    {productLink?.unitSize && <div className="text-gray-500 dark:text-gray-400">Size: {productLink?.unitSize}</div>}
                    {/*{item.productLink?.upc && <div className="hidden text-sm text-gray-500 dark:text-gray-400 md:block">UPC: {item.productLink?.upc}</div>}*/}
                    {/*{item.productLink?.mpn && <div className="hidden text-sm text-gray-500 dark:text-gray-400 md:block">MPN: {item.productLink?.mpn}</div>}*/}
                  </div>
                </div>
                <div className="">{productLink?.name}</div>
                <div className="flex justify-between">
                  <div>
                    <div className="text-base font-semibold">
                      <PriceDisplay price={productLink?.products?.[0]?.price ?? 0} />
                      {/*~{toCurrency(item.productLink?.products?.[0].price)}*/}
                    </div>
                    <div className="flex justify-between md:hidden">
                      {productLink?.unitSize && <div className="text-sm text-gray-500 dark:text-gray-400">Size: {productLink?.unitSize}</div>}
                      {/*{item.productLink?.upc && <div className="hidden text-sm text-gray-500 dark:text-gray-400 md:block">UPC: {item.productLink?.upc}</div>}*/}
                      {/*{item.productLink?.mpn && <div className="hidden text-sm text-gray-500 dark:text-gray-400 md:block">MPN: {item.productLink?.mpn}</div>}*/}
                    </div>
                  </div>
                </div>

                {/*<div className="text-sm text-gray-500 dark:text-gray-400">SKU: {item.productLink?.upc ?? item.productLink?.mpn}</div>*/}
                {/*<div>Price: {toCurrency(item.productLink?.products?.[0].price)}</div>*/}
                {/*/!*<div>Category: Beverages</div>*!/*/}
                {/*/!*<div>Subcategory: Soft Drinks</div>*!/*/}
                {/*<div>Total Price: {toCurrency((item.productLink?.products?.[0].price || 0) * item.quantity)}</div>*/}

                {/*<div className="text-xs">*/}
                {/*  <div>select/input</div>*/}
                {/*  <div>delete</div>*/}
                {/*  <div>edit par</div>*/}
                {/*</div>*/}
              </>
            </Popover.Button>
            <Popover.Panel className="z-10 mt-3 w-screen max-w-sm rounded-lg border border-brand-600 bg-white px-4 sm:px-0 lg:max-w-3xl">
              <ItemDetailsPanel productLink={productLink!} key={"details_panel" + productLink!.id} />
            </Popover.Panel>
          </Float>
        </Popover>
      )}
    </>
  );
}
