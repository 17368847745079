import { Log } from "@/Log.ts";
import { irnApi, OrderGuideUploadColumnType, UploadType } from "@/store/IRN.API.ts";
import { RootState } from "@/store/Store.ts";
import { showNotification } from "@/store/Toast.slice.ts";
import {
  FileUploadState,
  OrderGuideUploadWizardState,
  setFileState,
  setUploadedToServer,
  UploadRecord,
} from "@/store/order-guide-upload/wizard/OrderGuideUploadWizard.slice.ts";
import {
  getSerializableJsFile,
  isMutationDataResult,
  isMutationErrorResult,
  isMutationFetchBaseQueryErrorResult,
  isMutationSerializedErrorResult,
  isProblemDetails,
  SerializableJsFile,
} from "@/utils.ts";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as XLSX from "xlsx";
import { findDistributorPresetFromWorksheet } from "./wizard/OrderGuideUploadWizard.presets";

export enum FileUploadStatus {
  None,
  Loading,
  Parsing,
  ReadData,
  Error,
  Ready,
}

export enum OrderGuideUploadDisplayState {
  Idle,
  Parsing,
  ColumnSelection,
  Uploading,
  Success,
  Error,
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const defaultColumnTypes: Record<OrderGuideUploadColumnType, string | null> = Object.fromEntries(
  Object.keys(OrderGuideUploadColumnType).map((value) => [value, null]),
);

export const parseFileDataAsync = createAsyncThunk<any, SerializableJsFile | null>(
  "orderGuideUpload/parseFileDataAsync",
  async (file: SerializableJsFile | null, { dispatch }) => {
    console.log(file);
    // Log.debug(thunkAPI.getState());
    // const file = (getState() as RootState).orderGuideUpload.file;
    if (!file) {
      Log.error("parseFileDataAsync: No file");
      return null;
    }

    // Convert from the base64 string, to a buffer
    // const buffer = Buffer.from(file.base64StringFile, "base64");
    // const array = new Uint8Array(buffer);

    const workbook = XLSX.read(file.base64StringFile.split(";base64,")[1], {
      // raw: true,
      // dense: true,
      cellHTML: false,
    });

    // Get the column names
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];

    // Get the records
    const records: UploadRecord[] = [];
    const columnNames: string[] = [];

    // Log.debug(worksheet);

    const state: FileUploadState = {
      file,
      error: undefined,
    };

    const sheetRange = XLSX.utils.decode_range(worksheet["!ref"] ?? "A1:A1");
    const { distributorKey, preset } = findDistributorPresetFromWorksheet(worksheet) ?? {};

    if (preset) {
      state.skipFirstRow = preset.skipFirstRow;
      state.autoDetectedGuideDistributor = distributorKey;
    }
    // Log.debug(sheetRange);

    const numColumns = sheetRange.e.c + 1;
    const numRows = sheetRange.e.r + 1;

    // Iterate through all the rows in the sheet
    for (let rowIdx = state.skipFirstRow ? 1 : 0; rowIdx < numRows; rowIdx++) {
      if (columnNames.length === 0) {
        // Header row
        for (let colIdx = 0; colIdx < numColumns; colIdx++) {
          const cell = worksheet[XLSX.utils.encode_cell({ c: colIdx, r: rowIdx })];
          if (cell) {
            columnNames.push(cell.v);
          } else {
            columnNames.push(`Column ${colIdx}`);
          }
        }
      } else {
        // Data row
        const record: Record<string, any> = {};
        for (let colIdx = 0; colIdx < numColumns; colIdx++) {
          const cell = worksheet[XLSX.utils.encode_cell({ c: colIdx, r: rowIdx })];
          if (cell) {
            record[columnNames[colIdx]] = cell.v;
          } else {
            record[columnNames[colIdx]] = null;
          }
        }

        // Check if the record is "empty"
        let empty = true;
        for (const key in record) {
          if (record[key] !== null) {
            empty = false;
            break;
          }
        }

        if (empty) {
          continue;
        }

        const raw = JSON.stringify(record);
        // console.log(raw);
        records.push({ raw, record });
      }
    }

    // Log.debug("Columns", columnNames);
    // Log.debug("Records", records);
    // Log.debug(`Parsed ${records.length} records`);

    state.records = records;
    state.columns = columnNames;

    const toLower = (str: string) => (str.toLowerCase ? str.toLowerCase() : str.toString().toLowerCase());

    // Set the column types
    const columnTypes: Record<OrderGuideUploadColumnType, string | null> = { ...defaultColumnTypes };
    for (const columnName of columnNames) {
      // Check if the column name matches any of the column types
      const columnType = Object.keys(OrderGuideUploadColumnType).find((key) => key.toLowerCase() === toLower(columnName));
      if (columnType) {
        if (columnType === OrderGuideUploadColumnType.Size) {
          continue;
        }
        if (columnType === OrderGuideUploadColumnType.Unit) {
          continue;
        }

        columnTypes[columnType as OrderGuideUploadColumnType] = columnName;
      }
    }

    // If there is no name column, use the description column
    if (!columnTypes[OrderGuideUploadColumnType.Name]) {
      columnTypes[OrderGuideUploadColumnType.Name] = columnTypes[OrderGuideUploadColumnType.Description];
    }

    // If there is no distributor sku column, use the first column by default (usually correct-ish)
    if (!columnTypes[OrderGuideUploadColumnType.Sku]) {
      if (columnNames.length > 1 && columnNames[0] === "F" && columnNames[1] === "SUPC") {
        columnTypes[OrderGuideUploadColumnType.Sku] = columnNames[1];
      } else {
        columnTypes[OrderGuideUploadColumnType.Sku] = columnNames[0];
      }
    }

    // If unit size is not set, use the size column, or "pack" column
    if (!columnTypes[OrderGuideUploadColumnType.UnitSize]) {
      columnTypes[OrderGuideUploadColumnType.UnitSize] =
        columnNames.find((name) => toLower(name).includes("size")) || columnNames.find((name) => toLower(name).includes("pack")) || null;
    }

    state.columnTypes = columnTypes;
    //
    // if (isSysco) {
    //   console.log("Sysco file detected");
    //   modifyStateForSyscoOrderGuideUpload(state);
    // } else if (isKast) {
    //   console.log("Kast file detected");
    //   modifyStateForKastOrderGuideUpload(state);
    // } else {
    //   console.log("Not a Sysco file");
    // }

    dispatch(setFileState(state));
  },
);

export const loadRawFileDataAsync = createAsyncThunk("orderGuideUpload/loadRawFileDataAsync", async (file: File | null | undefined, { dispatch }) => {
  if (!file) {
    return;
  }

  const fileData = await getSerializableJsFile(file);

  console.log(fileData);
  dispatch(parseFileDataAsync(fileData));
});

export const uploadOrderGuideAsync = createAsyncThunk<number>(
  "orderGuideUpload/upload",
  async (_, { dispatch, getState }): Promise<number> => {
    const state: RootState = getState() as RootState;

    dispatch(setUploadedToServer(false));

    const currentBusiness = state.business.activeBusiness;
    if (!currentBusiness) {
      dispatch(showNotification({ message: "No active business. Please select a business at the top of the page.", severity: "error" }));
      throw new Error("No active business");
      // return;
    }

    const wizardState: FileUploadState = state.orderGuideUploadWizard.fileState!;

    const columns = wizardState.columns;
    const records = wizardState.records;
    const columnTypes = wizardState.columnTypes;
    const skipFirstRow = wizardState.skipFirstRow;
    const extractMpnUpc = wizardState.extractMpnUpcFromDescription;
    const file = wizardState.file;
    const uploadDistributorId = state.orderGuideUploadWizard.distributorId;
    const uploadType = state.orderGuideUploadWizard.uploadType;

    const result = await dispatch(
      irnApi.endpoints.uploadOrderGuide.initiate({
        businessId: currentBusiness.id,
        body: {
          columns: columns ?? [],
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columnTypes: columnTypes as { [key: string]: string },
          skipFirstRow: skipFirstRow ?? false,
          fileName: file?.name ?? "",
          fileType: file?.type ?? "",
          fileSize: file?.size ?? 0,
          records: (records ?? []).map((r: UploadRecord) => r.record) ?? [],
          extractMpnUpcFromDescription: extractMpnUpc ?? false,
          distributorId: parseInt(uploadDistributorId?.toString() ?? "0"),
          uploadType: uploadType as UploadType,
        },
      }),
    );

    if (isMutationDataResult<number>(result)) {
      console.log(result.data);
      dispatch(showNotification({ message: "Order guide uploaded successfully", severity: "success" }));
      return result.data!;
    } else if (isMutationErrorResult(result)) {
      let errorMessage: string | undefined | null;

      if (isMutationSerializedErrorResult(result)) {
        errorMessage = result.error.message;
      } else if (isMutationFetchBaseQueryErrorResult(result)) {
        if (isProblemDetails(result.error.data)) {
          errorMessage = result.error.data.detail;
        } else {
          errorMessage = result.error.status?.toString();
        }
      }

      errorMessage ??= "Order guide upload failed";

      dispatch(showNotification({ message: errorMessage, severity: "error" }));
      throw new Error(errorMessage);
    } else {
      dispatch(showNotification({ message: "Unknown error while uploaded order guide.", severity: "error" }));
      throw new Error("Unknown error");
    }
  },
  {
    condition: (_, { getState }) => {
      const state: Partial<RootState> = getState() as {
        orderGuideUploadWizard: OrderGuideUploadWizardState;
      };

      if (state.orderGuideUploadWizard?.uploadedToServer) {
        Log.warn("Already uploaded to server");
        return false;
      }

      return true;
    },
  },
);

// export const parseUploadedFileDataAsync = createAsyncThunk<XLSX.WorkSheet | null, SerializableJsFile>(
//   "orderGuideUpload/parseCsvFileDataAsync",
//   async (file, { dispatch, getState }) => {
//     if (!file) {
//       Log.error("parseCsvFileDataAsync: No file");
//       return null;
//     }
//
//     const parsed = parse(file.base64StringFile, {
//       columns: false,
//       skip_empty_lines: true,
//     });
//
//     const sheet = XLSX.utils.aoa_to_sheet(parsed);
//
//     return sheet;
//   }
// );
