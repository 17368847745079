import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import { ReactNode, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { UpdateUserRequestDto, useUpdateUserMutation } from "@/store/IRN.API.ts";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { selectUserInfo } from "@/store/Auth.Slice.ts";
import { IrnInput } from "@/components/forms/IrnInput.tsx";
import { Form } from "@/components/ui/form.tsx";
import { showNotification } from "@/store/Toast.slice.ts";

const validationSchema = yup.object<UpdateUserRequestDto>().shape({
  firstName: yup.string().required("Please enter your first name"),
  lastName: yup.string().required("Please enter your last name"),
  phone: yup.string().matches(/^\d{10}$/, {
    message: "Please enter a valid phone number",
    excludeEmptyString: true,
  }),
});

export function UpdateContactInfoDialog({ children }: { children: ReactNode }) {
  const [open, setOpen] = useState(false);
  const userInfo = useAppSelector(selectUserInfo);
  const [updateTrigger, updateResult] = useUpdateUserMutation();
  const dispatch = useAppDispatch();
  const form = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),

    defaultValues: {
      firstName: userInfo?.firstName ?? "",
      lastName: userInfo?.lastName ?? "",
      phone: userInfo?.phoneNumber ?? "",
    },
  });

  useEffect(() => {
    if (updateResult.isSuccess) {
      setOpen(false);
      dispatch(showNotification({ message: "Contact info updated", severity: "success" }));
    }
  }, [dispatch, updateResult.isSuccess]);

  const onSubmit = (data: UpdateUserRequestDto) => {
    console.log(data);
    updateTrigger({
      ...data,
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {children}
        {/*<Button variant="link">Update</Button>*/}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit contact info</DialogTitle>
          <DialogDescription>Make changes to your contact info here. Click save when you're done.</DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form className="space-y-6" onSubmit={form.handleSubmit(onSubmit)}>
            <IrnInput name="firstName" control={form.control} label="First name" auto-complete="given-name" />
            <IrnInput name="lastName" control={form.control} label="Last name" auto-complete="family-name" />
            <IrnInput name="phone" control={form.control} label="Phone number" auto-complete="tel" placeholder="1231231234" />
            <DialogFooter>
              <Button type="submit">Save</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
