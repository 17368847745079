import * as Yup from "yup";
import { ObjectSchema } from "yup";
import { AddressDto } from "@/store/IRN.API.ts";

export const addressFormValidationSchema: ObjectSchema<AddressDto> = Yup.object().shape({
  address1: Yup.string().required("Address is required"),
  address2: Yup.string(),
  city: Yup.string().required("City is required"),
  state: Yup.string()
    .required("State is required")
    .matches(/^[A-Za-z]{2}$/, "Please enter a valid state"),
  zipCode: Yup.string()
    .required("ZIP code is required")
    .matches(/^[0-9]{5}(-[0-9]{4})?$/, "Please enter a valid ZIP code"),
  careOf: Yup.string(),
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const optionalAddressFormValidationSchema: ObjectSchema<AddressDto> = Yup.object().shape({
  address1: Yup.string(),
  address2: Yup.string(),
  city: Yup.string(),
  state: Yup.string().matches(/^[A-Za-z]{2}$/, { message: "Please enter a valid state", excludeEmptyString: true }),
  zipCode: Yup.string().matches(/^[0-9]{5}(-[0-9]{4})?$/, { message: "Please enter a valid ZIP code", excludeEmptyString: true }),
  careOf: Yup.string(),
});
