import {
  BrandColumn,
  CategoryColumn,
  CategoryIdColumn,
  DescriptionColumn,
  DistributorSkuColumn,
  ImagesColumn,
  ManufacturerNumberColumn,
  NameColumn,
  PriceColumn,
  PricePerUnitColumn,
  SizeColumn,
  ThumbnailColumn,
  UnitColumn,
  UnitSizeColumn,
  UnitsPerCaseColumn,
  UpcColumn,
} from "@/components/order-guide-upload/columns/UnitSizeColumn.tsx";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion.tsx";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table.tsx";
import { useIsInRole } from "@/hooks";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { ApplicationRole } from "@/store/IRN.API.ts";
import {
  OrderGuideUploadWizardDistributorPresets,
  SupportedDistributorInfo,
  SupportedDistributors,
} from "@/store/order-guide-upload/wizard/OrderGuideUploadWizard.presets.tsx";
import {
  selectColumnNames,
  selectColumnSelectionErrors,
  selectColumnTypes,
  selectDistributorPresetTransformsApplied,
  selectDistributorPresetTransformsAppliedNotificationShown,
  selectRecords,
  selectSelectedDistributorPreset,
  selectShowAdvancedControls,
  setCanMoveToNextStep,
  setColumnNames,
  setColumnTypes,
  setDistributorPresetTransformsApplied,
  setDistributorPresetTransformsAppliedNotificationShown,
  setRecords,
  setSkipFirstRow,
} from "@/store/order-guide-upload/wizard/OrderGuideUploadWizard.slice.ts";
import { CheckIcon } from "@heroicons/react/20/solid";
import { EyeIcon } from "@heroicons/react/24/outline";
import { Columns3Icon } from "lucide-react";
import { Fragment, ReactNode, useEffect, useState } from "react";

export function SelectFileColumnsStep() {
  const appliedPresetTransformations = useAppSelector(selectDistributorPresetTransformsApplied);
  const appliedPresetTransformationsNotificationShown = useAppSelector(selectDistributorPresetTransformsAppliedNotificationShown);
  const selectedDistributor = useAppSelector(selectSelectedDistributorPreset);
  const fileColumns = useAppSelector(selectColumnNames);
  const fileRows = useAppSelector(selectRecords);
  const columnTypes = useAppSelector(selectColumnTypes);
  const columnErrors = useAppSelector(selectColumnSelectionErrors);
  const showAdvancedFeatures = useAppSelector(selectShowAdvancedControls);
  const [hiddenColumns, setHiddenColumns] = useState<boolean[]>([]);
  const [transformationsAppliedHtml, setTransformationsAppliedHtml] = useState<ReactNode | undefined>(undefined);
  const isAdmin = useIsInRole(ApplicationRole.Administrator, ApplicationRole.Developer, ApplicationRole.SuperAdministrator);
  const dispatch = useAppDispatch();
  // const notification = useAlert();

  const [supportedDistributorPresetInfo, setSupportedDistributorPresetInfo] = useState<SupportedDistributorInfo | undefined>(undefined);

  useEffect(() => {
    if (selectedDistributor && selectedDistributor !== "other") {
      setSupportedDistributorPresetInfo(SupportedDistributors.find((d) => d.value === selectedDistributor));
    }
  }, [selectedDistributor]);

  useEffect(() => {
    if (fileColumns) {
      setHiddenColumns(fileColumns!.map((_, index) => shouldHideColumn(index)));
    }
  }, [fileColumns, fileRows]);

  useEffect(() => {
    if (appliedPresetTransformations && !appliedPresetTransformationsNotificationShown) {
      showTransformationsAppliedNotification();
    }
  }, [appliedPresetTransformations]);

  // Essentially, make the step available immediately
  useEffect(() => {
    console.log(columnErrors);
    dispatch(setCanMoveToNextStep(!Object.values(columnErrors).some((v) => v !== undefined)));
  }, [columnErrors, dispatch]);

  const showTransformationsAppliedNotification = () => {
    const bodyContentCallback = OrderGuideUploadWizardDistributorPresets[selectedDistributor!].postTransformDetails;

    setTransformationsAppliedHtml(bodyContentCallback ? bodyContentCallback() : "The preset transformations have been applied to the file.");

    // notification({
    //   title: "Preset Transformations Applied",
    //   body: bodyContentCallback ? bodyContentCallback() : "The preset transformations have been applied to the file.",
    //   cancelButton: "Close",
    // });

    dispatch(setDistributorPresetTransformsAppliedNotificationShown(true));
  };

  const applyTransformations = () => {
    dispatch(setDistributorPresetTransformsApplied(true));
    const preset = OrderGuideUploadWizardDistributorPresets[selectedDistributor!];

    if (!preset) {
      return;
    }

    if (preset.skipFirstRow !== undefined) {
      dispatch(setSkipFirstRow(preset.skipFirstRow));
    }
    dispatch(setColumnTypes(preset.columns));

    if (preset.transformColumns && fileColumns) {
      dispatch(setColumnNames(preset.transformColumns(fileColumns)));
    }

    if (preset.transformRow && fileRows) {
      const newRows = fileRows.map((row) => {
        return preset.transformRow!(row);
      });
      dispatch(setRecords(newRows));
    }
  };

  const shouldHideColumn = (column: number) => {
    const columnName = fileColumns?.[column];

    if (!columnName) {
      return false;
    }

    const preset = OrderGuideUploadWizardDistributorPresets[selectedDistributor!];

    if (preset?.hideColumns?.includes(columnName)) {
      return true;
    }

    // Check if all the rows in this column are empty
    const allEmpty = fileRows?.every((row) => {
      return !row.record[columnName];
    });

    return !!allEmpty;
  };

  return (
    <>
      <div className="p-4">
        <div className="grid max-w-full gap-4">
          <div className="flex w-full justify-between">
            <div>
              <div className="flex items-center gap-2">
                <Columns3Icon className="h-5 w-5" />
                <h3 className="font-semibold">File Columns</h3>
              </div>
              <p className="text-sm leading-loose text-gray-500 dark:text-gray-400">Define important columns in your file so we can load it.</p>
            </div>
          </div>
          {supportedDistributorPresetInfo && !appliedPresetTransformations && (
            <Alert variant="success">
              <AlertTitle>Preset available</AlertTitle>
              <AlertDescription>
                <p className="text-sm">
                  We have a preset available for <span className="font-semibold text-brand-600">{supportedDistributorPresetInfo.name}</span>. You can change the
                  column mappings below if needed. In general, we&apos;ll try to auto-detect the columns for you, and add any columns to provide more complete
                  product data.
                </p>

                <Button onClick={applyTransformations} className="mt-4">
                  Apply Preset Transformations
                </Button>
              </AlertDescription>
            </Alert>
          )}

          {showAdvancedFeatures && (
            <Accordion collapsible type="single">
              <AccordionItem value="1">
                <AccordionTrigger>
                  <div className="text-left">
                    <h3>
                      File Preview <EyeIcon className="inline-block h-5 w-5" />
                    </h3>
                    <p className="text-sm text-gray-500">Click to expand</p>
                    <p className="text-sm text-gray-500">
                      The first 25 rows of your file are shown here. This is for preview purposes only. You can change the column mappings below if needed.
                    </p>
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <>
                    <p className="text-xs text-gray-500">Not all columns may be shown. Empty columns, and preset-hidden columns are not shown for brevity.</p>
                    <div className="block overflow-x-scroll rounded border shadow">
                      <Table className="min-w-full bg-white">
                        <TableHeader>
                          <TableRow>
                            <TableHead>Row</TableHead>
                            {fileColumns?.map((col, idx) => <Fragment key={col}>{!hiddenColumns[idx] && <TableHead>{col}</TableHead>}</Fragment>)}
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {fileRows?.slice(0, Math.min(100, fileRows?.length))?.map((record, index) => (
                            <TableRow key={index.toString()}>
                              <TableCell className="text-sm ">
                                {/*<input*/}
                                {/*  type="checkbox"*/}
                                {/*  className="form-checkbox h-4 w-4 text-brand-600 transition duration-150 ease-in-out"*/}
                                {/*  onChange={() => ignoreRow(index, !ignoredRows?.includes(index))}*/}
                                {/*  checked={ignoredRows?.includes(index) ?? false}*/}
                                {/*/>*/}
                                {index + 1}
                              </TableCell>

                              {Object.entries(record.record).map((col, index2) => (
                                <Fragment key={index2.toString()}>
                                  {!hiddenColumns[index2] && <TableCell className="max-w-[30ch] truncate whitespace-nowrap">{col[1]}</TableCell>}
                                </Fragment>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          )}

          <>
            {/*<h3>Column</h3>*/}
            {/*<p className="text-sm text-gray-500">Map the columns in your file to the columns we expect for this distributor.</p>*/}

            {supportedDistributorPresetInfo && appliedPresetTransformations && (
              <Alert variant="success">
                <AlertTitle>
                  <CheckIcon className="mr-2 inline-block h-6 w-6 text-emerald-600" />
                  Preset applied
                </AlertTitle>
                <AlertDescription>
                  <div className="mt-4 text-sm">
                    {transformationsAppliedHtml ?? (
                      <div>
                        <p>The preset transformations have been applied to the file. </p>
                      </div>
                    )}
                    <p>
                      You can change the column mappings below if needed. In general, we'll try to auto-detect the columns for you, and add any columns to
                      provide more complete product data.
                    </p>
                  </div>
                </AlertDescription>
              </Alert>
            )}

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-4">
              {/* Get the names of the enum values for OrderGuideUploadColumnType */}
              {/* For each enum value, render a label and a select box */}
              {columnTypes && (
                <>
                  <DistributorSkuColumn />
                  <NameColumn />
                  <BrandColumn />
                  <PriceColumn />
                  <PricePerUnitColumn />

                  <UpcColumn />
                  <ManufacturerNumberColumn />

                  <UnitsPerCaseColumn />
                  <UnitSizeColumn />

                  {showAdvancedFeatures && (
                    <Accordion type="single" collapsible className="col-span-full w-full">
                      <AccordionItem value="advanced">
                        <AccordionTrigger>
                          <h4>Advanced</h4>
                        </AccordionTrigger>
                        <AccordionContent className="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-4">
                          <DescriptionColumn />
                          <UnitColumn />
                          <SizeColumn />

                          {isAdmin && (
                            <>
                              <CategoryColumn />
                              <CategoryIdColumn />

                              <ThumbnailColumn />
                              <ImagesColumn />
                            </>
                          )}
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  )}
                </>
              )}
            </div>
          </>
        </div>
      </div>
    </>
  );
}
