import { useGetOrderGuideSummariesQuery } from "../../../store/IRN.API.ts";
import { Redirect } from "../../../components/authentication/AuthenticatedRoute.tsx";
import { OrderGuideSelectionWidget } from "../../../components/order-guide-selection-widget/OrderGuideSelectionWidget.tsx";
import { LoadingSpinner } from "@/components/LoadingSpinner.tsx";
import { useAppSelector } from "@/store/Hooks.ts";
import { selectBusinesses } from "@/store/Business.slice.ts";

export function GuideIndexPage() {
  const guides = useGetOrderGuideSummariesQuery();

  const businesses = useAppSelector(selectBusinesses);

  // If there are no businesses, redirect to /dashboard to let the user go through the onboarding process
  if (businesses && businesses.length === 0) {
    return <Redirect to="/app/dashboard" />;
  }

  if (guides.data) {
    // If there are no guides, display the dashboard widget
    // TODO: Maybe make this a dedicated page?
    if (guides.data.length === 0) {
      return <OrderGuideSelectionWidget />;
    }

    // Redirect to /app/order-guide/:id for the first guide
    return <Redirect to={`/app/order-guide/${guides.data[0].id}`} />;
  }

  return <LoadingSpinner color="brand" size="xl" />;
}
