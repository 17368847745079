import { Logo } from "@/components/Logo.tsx";
import { PublicFooter } from "@/components/layout/PublicFooter.tsx";
import { Link } from "react-router-dom";

export function ReturnPolicy() {
  return (
    <div className="flex min-h-screen flex-col">
      <div className="container py-10">
        <div className="flex w-full justify-center">
          <Link to="/">
            <Logo className="h-24" />
          </Link>
        </div>

        <article className="prose max-w-none lg:prose-xl ">
          <h1 className="font-semibold">Return Policy</h1>
          <p>
            For in-stock items, the purchase is backed by our 60-day Satisfaction Guarantee. If the merchandise you ordered does not meet your expectations, we
            will gladly exchange it or accept the return for a refund or credit within 60 days from the day you receive your order. You can send us an email at{" "}
            <a href="mailto:info@irnworks.com">info@irnworks.com</a> or call us at <a href="tel:+16099296053">+1 (609) 929 6053</a> between the hours of 8 a.m.
            and 5 p.m. EST.
          </p>
          <p>
            For custom orders, we are unable to accept returns or process exchanges. If you feel your order was produced incorrectly, please contact us within
            30 days of the invoice date. If it is determined there is a material or manufacturing defect with your order, we will replace your order or issue a
            refund.
          </p>
          <p>
            To return an in-stock item, contact Customer Service at to obtain a Return Form. Fill out this form and carefully repack the merchandise with the
            form in the original carton within 60 days of purchase.
          </p>
        </article>
      </div>
      <div className="flex-1"></div>
      <PublicFooter />
    </div>
  );
}
