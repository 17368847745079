import { OrderStatus } from "@/store/IRN.API.ts";

export const orderStatusToString = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.Disputed:
      return "Disputed";
    case OrderStatus.NotPlaced:
      return "Not Placed";
    case OrderStatus.Refunded:
      return "Refunded";
    case OrderStatus.Returned:
      return "Returned";
    case OrderStatus.Shipped:
      return "Shipped";
    case OrderStatus.Delivered:
      return "Delivered";
    case OrderStatus.Pending:
      return "Pending";
    case OrderStatus.Cancelled:
      return "Cancelled";

    default:
      return "Unknown";
  }
};
