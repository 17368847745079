import { OrderGuideItemDto } from "@/store/IRN.API.ts";
import { Label } from "@/components/ui/label.tsx";
import { NumericInput } from "@/components/ui/numeric-input.tsx";

export function OrderGuideItemListingQuantityControl(props: { item: OrderGuideItemDto; onChange: React.ChangeEventHandler<HTMLInputElement> }) {
  return (
    <div className="flex flex-col gap-2 text-center">
      <Label>Quantity</Label>
      <NumericInput className="w-[3.5rem]" min={0} max={1000000} step={1} defaultValue={props.item.quantity} onChange={props.onChange} />
    </div>
  );
}
