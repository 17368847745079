import { AuthWrapper } from "@/components/authentication/AuthWrapper.tsx";
import { Redirect } from "@/components/authentication/AuthenticatedRoute.tsx";
import { Logo } from "@/components/Logo.tsx";
import { LoadingSpinner } from "@/components/LoadingSpinner.tsx";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";

export function Login() {
  return (
    <AuthWrapper>
      <Redirect to="/app/dashboard" />
    </AuthWrapper>
  );
}

export function Logout() {
  const msal = useMsal();

  useEffect(() => {
    msal.instance.logoutRedirect({
      postLogoutRedirectUri: window.location.origin,
    });
  }, [msal.instance]);

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-10">
      <Logo className="h-20 w-auto" />
      <h1 className="text-center text-3xl font-bold">Please wait while we log you out</h1>
      <LoadingSpinner size="6xl" />
    </div>
  );
}
