import { useAppSelector } from "@/store/Hooks.ts";
import { selectAuthenticated } from "@/store/Auth.Slice.ts";
import { Route, useNavigate } from "react-router-dom";
import { useEffect } from "react";

/**
 * This route will redirect to the login page if the user is not authenticated.
 * @param children
 * @param path
 * @param roles
 * @param anyRoles
 * @constructor
 */
export function AuthenticatedRoute({ children, path }: { children?: React.ReactNode; path: string }) {
  const isAuthenticated = useAppSelector(selectAuthenticated);
  const renderChildren = () => {
    if (!isAuthenticated) {
      return <Redirect to="/login" />;
    }

    return children;
  };
  return <Route path={path} element={renderChildren()} />;
}

export function Redirect({ to }: { to: string }) {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  }, [navigate, to]);
  return null;
}
