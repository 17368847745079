import { CheckIcon } from "@heroicons/react/20/solid";
import { PublicHeader } from "@/components/layout/PublicHeader.tsx";
import { PublicFooter } from "@/components/layout/PublicFooter.tsx";

const includedFeatures = [
  "Email support",
  "Official member resources",
  "Custom order guides",
  // "Price match engine",
  "Access to add-on services such as CPAs, Training, and others",
  // "99.9% uptime SLA",
  // "Unlimited order creations",
  // "Unlimited order matching",
];

const priceMatchingFeatures = [
  "Save an average of 15-30% on product purchases*",
  "Guaranteed lowest price orders creation**",
  "Create & place unlimited orders",
  "Bring your own distributors",
  "No limits on order creations",
  "No limits on number of distributors",
  "Access to IRN preferred distributors",
  "Lower prices on products purchased through our distribution partners",
];

const priceMatchingFeaturesNotes = [
  "* Savings depends on the distributor and product. Savings are not guaranteed.",
  "** Guaranteed lowest price orders do not include taxes, shipping, or other fees. Orders are subject to distributor minimum requirements.",
];

// const includedProductsFeatures = ["Access to multiple distributors", "Over 10,000 products", "Access to new products", "Access to exclusive products & discounts"];

const availableServices = [
  "CPA services",
  "Training",
  "Consulting",
  // "Phone support",
  "Custom onboarding",
  // "Custom order guide creation",
  // "Custom product load",
  // "Custom product matching"
];

const faqs = [
  {
    question: "Is there any setup fee?",
    answer:
      "There are no setup fees! You can get started with a 7-day free trial. If you would like us to help you set up your account, we offer a one-time setup fee of $250 for your first order guide & product load.",
  },
  {
    question: "Can I cancel my subscription?",
    answer:
      "Yes! You can cancel your subscription at any time. If you cancel your subscription, you will still have access to your account until the end of your billing cycle.",
  },
  {
    question: "Do you offer discounts?",
    answer: "Not at this time. We feel that $100 per month is a fair price for the value we provide.",
  },
  {
    question: "Do you offer a free trial?",
    answer:
      "Yes! You can get started with a 7-day free trial. You will also be given access to up to 10 order creations during your trial period, to test our matching services, and make sure it’s a good fit for your business.",
  },
  {
    question: "Are add-on services included?",
    answer:
      "Add-on services such as training, consulting, and CPA services are not included in the monthly membership fee. These services are available for an additional fee. Please contact us for more information.",
  },
  // {
  //   question: "What is the 3% fee for product purchases?",
  //   answer:
  //     "The 3% fee is charged on the total amount of the order, excluding shipping and taxes. We charge this fee to cover the costs of our distribution partners, and to provide you with the best possible pricing on your product purchases. This fee is subject to change at any time.",
  // },
  // {
  //   question: "Does the 3% fee apply to distributors not in your network?",
  //   answer:
  //     "No. The 3% fee only applies to products purchased through our distribution partners. If you purchase products from a distributor not in our network, you will not be charged the 3% fee for those products.",
  // },
  // More questions...
];

export function PricingPage() {
  return (
    <>
      <PublicHeader wrapperClassName="" />
      <main>
        {/* Pricing section */}
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl sm:text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Simple no-tricks pricing</h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                A small monthly fee gets you full access to our order guides, and matching service. No extra fees, no hidden charges. Cancel anytime.
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
              <div className="p-8 sm:p-10 lg:flex-auto">
                <h3 className="text-2xl font-bold tracking-tight text-gray-900">Monthly membership</h3>
                {/*<p className="mt-6 text-base leading-7 text-gray-600">*/}
                {/*  */}
                {/*</p>*/}
                <div className="mt-10 flex items-center gap-x-4">
                  <h4 className="flex-none text-sm font-semibold leading-6 text-brand-600">What’s included</h4>
                  <div className="h-px flex-auto bg-gray-100" />
                </div>
                <ul role="list" className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                  {includedFeatures.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon className="h-6 w-5 flex-none text-brand-600" aria-hidden="true" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                <div className="h-full rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                  <div className="mx-auto max-w-xs px-8">
                    <p className="text-base font-semibold text-gray-600">Per month</p>
                    <p className="mt-6 flex items-baseline justify-center gap-x-2">
                      <span className="text-5xl font-bold tracking-tight text-gray-900">$0</span>
                      <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span>
                    </p>
                    <a
                      href="#"
                      className="mt-10 block w-full rounded-md bg-brand-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                    >
                      Get started
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
              <div className="p-8 sm:p-10 lg:flex-auto">
                <h3 className="text-2xl font-bold tracking-tight text-gray-900">Price matching</h3>
                {/*<p className="mt-6 text-base leading-7 text-gray-600">*/}
                {/*  */}
                {/*</p>*/}
                <div className="mt-10 flex items-center gap-x-4">
                  <h4 className="flex-none text-sm font-semibold leading-6 text-brand-600">What’s included</h4>
                  <div className="h-px flex-auto bg-gray-100" />
                </div>
                <ul role="list" className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                  {priceMatchingFeatures.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon className="h-6 w-5 flex-none text-brand-600" aria-hidden="true" />
                      {feature}
                    </li>
                  ))}
                </ul>

                <ul role="list" className="mt-8 text-xs text-gray-500">
                  {priceMatchingFeaturesNotes.map((feature) => (
                    <li key={feature}>{feature}</li>
                  ))}
                </ul>
              </div>
              <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                <div className="h-full rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                  <div className="mx-auto max-w-xs px-8">
                    <p className="text-base font-semibold text-gray-600">Per month</p>
                    <p className="mt-6 flex items-baseline justify-center gap-x-2">
                      <span className="text-5xl font-bold tracking-tight text-gray-900">$100</span>
                      <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span>
                    </p>
                    <a
                      href="#"
                      className="mt-10 block w-full rounded-md bg-brand-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                    >
                      Get started
                    </a>
                    <p className="mt-6 text-xs leading-5 text-gray-600">7 days and 10 order creations free*</p>
                    <p className="mt-6 text-xs leading-5 text-gray-600">* Products purchased through our distribution partners excluded</p>
                  </div>
                </div>
              </div>
            </div>

            {/*<div className="mx-auto mt-10 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-10 lg:mx-0 lg:flex lg:max-w-none">
              <div className="p-8 sm:p-10 lg:flex-auto">
                <h3 className="text-2xl font-bold tracking-tight text-gray-900">Product purchases</h3>
                <p className="mt-6 text-base leading-7 text-gray-600">
                  Purchase products through our distribution partners and pay a small fee per order. On average, our customers save 10-17% (sometimes much more)
                  on their product purchases through our distribution partners.
                </p>
                <div className="mt-10 flex items-center gap-x-4">
                  <h4 className="flex-none text-sm font-semibold leading-6 text-brand-600">What’s included</h4>
                  <div className="h-px flex-auto bg-gray-100" />
                </div>
                <ul role="list" className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                  {includedProductsFeatures.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon className="h-6 w-5 flex-none text-brand-600" aria-hidden="true" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                <div className="h-full rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                  <div className="mx-auto max-w-xs px-8">
                    <p className="text-base font-semibold text-gray-600">Per order</p>
                    <p className="mt-6 flex items-baseline justify-center gap-x-2">
                      <span className="text-5xl font-bold tracking-tight text-gray-900">3%</span>
                      <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span>
                    </p>
                    <a
                      href="#"
                      className="mt-10 block w-full rounded-md bg-brand-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                    >
                      Get started
                    </a>
                    <p className="mt-6 text-xs leading-5 text-gray-600">
                      Products purchased through our distribution partners are subject to standard pricing. Distribution terms and conditions apply.
                    </p>
                  </div>
                </div>
              </div>
            </div>*/}

            <div className="mx-auto mt-10 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-10 lg:mx-0 lg:flex lg:max-w-none">
              <div className="p-8 sm:p-10 lg:flex-auto">
                <h3 className="text-2xl font-bold tracking-tight text-gray-900">Add-on services</h3>
                <p className="mt-6 text-base leading-7 text-gray-600">
                  Below is a non-comprehensive list of available services that IRN provides. These services are charged separately from other purchases &
                  memberships.
                </p>
                <div className="mt-10 flex items-center gap-x-4">
                  <h4 className="flex-none text-sm font-semibold leading-6 text-brand-600">Available services</h4>
                  <div className="h-px flex-auto bg-gray-100" />
                </div>
                <ul role="list" className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                  {availableServices.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon className="h-6 w-5 flex-none text-brand-600" aria-hidden="true" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                <div className="h-full rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                  <div className="mx-auto max-w-xs px-8">
                    <p className="mt-6 flex items-baseline justify-center gap-x-2">
                      <span className="text-5xl font-bold tracking-tight text-gray-900">Contact us</span>
                    </p>

                    <p className="mt-6 text-xs leading-5 text-gray-600">
                      Not all services may be available in your geographic location. Please contact us for more information.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FAQ section */}
        <div className="mx-auto mb-24 mt-24 max-w-7xl divide-y divide-gray-900/10 px-6 sm:mt-56 lg:px-8">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
          <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
            {faqs.map((faq, index) => (
              <div key={index.toString()} className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
                <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">{faq.question}</dt>
                <dd className="mt-4 lg:col-span-7 lg:mt-0">
                  <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </main>

      <PublicFooter />
    </>
  );
}
