import { setDebugObjectData } from "@/store/Dev.slice.ts";
import { showNotification } from "@/store/Toast.slice.ts";
import { isProblemDetails } from "@/utils.ts";
import { isRejectedWithValue, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import * as HttpStatus from "http-status-codes";
import { toast } from "sonner";

export const apiQueryErrorLoggerMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    console.log(JSON.stringify(action));
    // applyApmLabelsForObject(action, "action.");
    // console.error("RTK Query Error", action);

    if (isProblemDetails(action.payload.data)) {
      // console.log("Problem Details", action.payload.data);
      const problemDetails = action.payload.data;
      const errors = problemDetails.errors;

      // Problem details
      toast.error(
        <div>
          <p className="font-bold">Error</p>
          {problemDetails.detail && <p className="font-semibold">{problemDetails.detail}</p>}
          {/*<p>{Object.keys(errors).length > 0 ? problemDetails.title : problemDetails.detail}</p>*/}
          {errors &&
            Object.keys(errors).map((key) => (
              <p className="text-sm" key={key}>
                {key}: {errors[key].join(", ")}
              </p>
            ))}
          <p className="text-xs opacity-50">
            HTTP {problemDetails.status} {HttpStatus.getReasonPhrase(problemDetails.status ?? 501)} - {problemDetails.title}
          </p>
        </div>,
      );
    } else if (action.payload.status === "PARSING_ERROR") {
      toast.error(
        <div>
          <p className="font-bold">Request Error {action.payload.status}</p>
          <p>{action.payload.data.toString().substring(0, 300)}</p>
        </div>,
      );
    } else if (action.type === "api/executeMutation/rejected" || action.type === "api/executeQuery/rejected") {
      const statusName = HttpStatus.getReasonPhrase(action.payload.status);
      toast.error(
        <div>
          <p className="font-bold">Request Error</p>
          <p>
            HTTP {action.payload.status} {statusName} - @{action.meta.arg.endpointName} - {action.error.message}
          </p>
        </div>,
      );
    } else {
      api.dispatch(
        showNotification({
          message: "data" in action.error ? (action.error.data as { message: string }).message : action.error.message,
          severity: "error",
          isApiError: true,
        }),
      );
    }

    const debugInfo = {
      ...action,
    };

    if (debugInfo.meta?.baseQueryMeta?.request) {
      delete debugInfo.meta.baseQueryMeta.request;
      delete debugInfo.meta.baseQueryMeta.response;
    }
    api.dispatch(setDebugObjectData({ rtkQueryError: debugInfo }));

    // apm.captureError(new Error("RTK Query Error"));
  }
  return next(action);
};
