import { Link } from "react-router-dom";

export function NotFoundPage() {
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <h1 className="text-3xl font-bold text-gray-900">Page Not Found</h1>
      <p className="mt-2 text-sm text-gray-500">The page you are looking for does not exist or has been moved.</p>
      <Link to="/" className="mt-4 text-sm font-medium text-brand">
        Go back home<span aria-hidden="true"> &rarr;</span>
      </Link>
    </div>
  );
}
