import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog.tsx";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import * as React from "react";
import { ReactNode } from "react";

type buttonVariants = "default" | "destructive" | "outline" | "secondary" | "ghost" | "link" | "no-bg" | "success";

export const AlertDialogContext = React.createContext<
  <T extends AlertAction>(params: T) => Promise<T["type"] extends "alert" | "confirm" ? boolean : null | string>
>(() => null!);

export type AlertAction =
  | {
      type: "alert";
      title: string;
      body?: ReactNode;
      cancelButton?: string;
      cancelVariant?: buttonVariants;
    }
  | {
      type: "confirm";
      title: string;
      body?: ReactNode;
      cancelButton?: string;
      cancelVariant?: buttonVariants;
      actionButton?: string;
      actionVariant?: buttonVariants;
    }
  | {
      type: "prompt";
      title: string;
      body?: ReactNode;
      cancelButton?: string;
      cancelVariant?: buttonVariants;
      actionButton?: string;
      actionVariant?: buttonVariants;
      inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    }
  | { type: "close" };

interface AlertDialogState {
  open: boolean;
  title: string;
  body: ReactNode;
  type: "alert" | "confirm" | "prompt";
  cancelButton: string;
  cancelVariant: buttonVariants;
  actionButton: string;
  actionVariant: buttonVariants;
  defaultValue?: string;
  inputProps?: React.PropsWithoutRef<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>;
}

export function alertDialogReducer(state: AlertDialogState, action: AlertAction): AlertDialogState {
  switch (action.type) {
    case "close":
      return { ...state, open: false };
    case "alert":
      return {
        ...state,
        open: true,
        ...action,
        cancelButton: action.cancelButton || "Okay",
        cancelVariant: action.cancelVariant || "default",
      };
    case "confirm":
      return {
        ...state,
        open: true,
        ...action,
        cancelButton: action.cancelButton || "Cancel",
        actionButton: action.actionButton || "Okay",
        cancelVariant: action.cancelVariant || "link",
        actionVariant: action.actionVariant || "default",
      };
    case "prompt":
      return {
        ...state,
        open: true,
        ...action,
        cancelButton: action.cancelButton || "Cancel",
        actionButton: action.actionButton || "Okay",
        cancelVariant: action.cancelVariant || "link",
        actionVariant: action.actionVariant || "default",
        inputProps: action.inputProps,
      };
    default:
      return state;
  }
}

export function AlertDialogProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(alertDialogReducer, {
    open: false,
    title: "",
    body: "",
    type: "alert",
    cancelButton: "Cancel",
    actionButton: "Okay",
    cancelVariant: "link",
    actionVariant: "default",
  });

  const resolveRef = React.useRef<(tf: any) => void>();

  function close() {
    dispatch({ type: "close" });
    resolveRef.current?.(false);
  }

  function confirm(value?: string) {
    dispatch({ type: "close" });
    resolveRef.current?.(value ?? true);
  }

  const dialog = React.useCallback(async <T extends AlertAction>(params: T) => {
    dispatch(params);

    return new Promise<T["type"] extends "alert" | "confirm" ? boolean : null | string>((resolve) => {
      resolveRef.current = resolve;
    });
  }, []);

  return (
    <AlertDialogContext.Provider value={dialog}>
      {children}
      <AlertDialog
        open={state.open}
        onOpenChange={(open) => {
          if (!open) close();
          return;
        }}
      >
        <AlertDialogContent asChild>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              confirm(event.currentTarget.prompt?.value);
            }}
          >
            <AlertDialogHeader>
              <AlertDialogTitle>{state.title}</AlertDialogTitle>
              {state.body ? <AlertDialogDescription asChild>{state.body}</AlertDialogDescription> : null}
            </AlertDialogHeader>
            {state.type === "prompt" && <Input name="prompt" defaultValue={state.defaultValue} {...state.inputProps} />}
            <AlertDialogFooter>
              <Button type="button" onClick={close} variant={state.cancelVariant}>
                {state.cancelButton}
              </Button>
              {state.type === "alert" ? null : (
                <Button type="submit" variant={state.actionVariant}>
                  {state.actionButton}
                </Button>
              )}
            </AlertDialogFooter>
          </form>
        </AlertDialogContent>
      </AlertDialog>
    </AlertDialogContext.Provider>
  );
}

type Params<T extends "alert" | "confirm" | "prompt"> = Omit<Extract<AlertAction, { type: T }>, "type"> | string;

export function useConfirm() {
  const dialog = React.useContext(AlertDialogContext);

  return React.useCallback(
    (params: Params<"confirm">) => {
      return dialog({
        ...(typeof params === "string" ? { title: params } : params),
        type: "confirm",
      });
    },
    [dialog],
  );
}

export function usePrompt() {
  const dialog = React.useContext(AlertDialogContext);

  return (params: Params<"prompt">) =>
    dialog({
      ...(typeof params === "string" ? { title: params } : params),
      type: "prompt",
    });
}

export function useAlert() {
  const dialog = React.useContext(AlertDialogContext);
  return (params: Params<"alert">) =>
    dialog({
      ...(typeof params === "string" ? { title: params } : params),
      type: "alert",
    });
}
