import { ProductsTable } from "@/components/dashboard/products/ProductsTable.tsx";
import { useScreenBreakpoint } from "@/hooks";
import { LargeFormatProductFilter } from "@/pages/app/products/LargeFormatProductFilter.tsx";
import { MiniFormatProductFilter } from "@/pages/app/products/MiniFormatProductFilter.tsx";
import { SearchFilter } from "@/pages/app/products/SearchFilter.ts";
import { selectActiveBusiness } from "@/store/Business.slice.ts";
import { useAppSelector } from "@/store/Hooks.ts";
import { SearchFacet, SearchFacetDto, useGetSearchProductFacetsQuery } from "@/store/IRN.API.ts";
import { useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";

export function ProductsPage() {
  const activeBusiness = useAppSelector(selectActiveBusiness);
  const searchFacets = useGetSearchProductFacetsQuery(activeBusiness?.id ?? 1);
  const breakpoint = useScreenBreakpoint();

  const [activeSearchFacets, setActiveSearchFacets] = useState<SearchFacetDto[]>([]);
  const [searchFilters, setSearchFilters] = useState<SearchFilter[]>([]);
  const debouncedSearchFacets = useDebounce(activeSearchFacets, 500);

  const businessId = useAppSelector(selectActiveBusiness)?.id ?? 0;

  useEffect(() => {
    // Build the filters from the facets
    const filters: { id: SearchFacet; name: string; options: { value: string; label: string; checked: boolean }[] }[] = [];
    searchFacets.data?.forEach((facet) => {
      const filter = filters.find((f) => f.id === facet.facet);
      if (filter) {
        filter.options.push({ value: facet.value, label: facet.value, checked: false });
      } else {
        filters.push({
          id: facet.facet,
          name: facet.facet.toString(),
          options: [{ value: facet.value, label: facet.value, checked: false }],
        });
      }
    });

    setSearchFilters(filters);
  }, [searchFacets]);

  // Basic 2 column layout for the product details/search table
  // 1/3 of the page is product categories
  // 2/3 of the page is product details/search table
  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-5 gap-5">
        {breakpoint !== "phone" && (
          <LargeFormatProductFilter searchFilters={searchFilters} activeSearchFacets={activeSearchFacets} setActiveSearchFacets={setActiveSearchFacets} />
        )}

        <div className="lg:col-span-4">
          <div className="rounded bg-white sm:p-5 shadow">
            <ProductsTable
              searchFacets={debouncedSearchFacets}
              businessId={businessId}
              filtersSlot={
                breakpoint === "phone" && (
                  <MiniFormatProductFilter
                    searchFilters={searchFilters}
                    activeSearchFacets={activeSearchFacets}
                    setActiveSearchFacets={setActiveSearchFacets}
                  />
                )
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
