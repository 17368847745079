import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface NotificationsState {
  dismissedAlertIds: string[];
  dismissedNotificationIds: string[];
}

export const initialState: NotificationsState = {
  dismissedAlertIds: [],
  dismissedNotificationIds: [],
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    dismissAlert: (state, action: PayloadAction<string>) => {
      state.dismissedAlertIds = [...state.dismissedAlertIds, action.payload];
    },
    dismissNotification: (state, action: PayloadAction<string>) => {
      state.dismissedNotificationIds = [...state.dismissedNotificationIds, action.payload];
    },
  },
});

export const { dismissAlert, dismissNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;

export const selectDismissedAlertIds = (state: { notifications: NotificationsState }) => state.notifications.dismissedAlertIds;
export const selectDismissedNotificationIds = (state: { notifications: NotificationsState }) => state.notifications.dismissedNotificationIds;
